/* eslint-disable no-unreachable */
/* eslint-disable consistent-return */
import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import checkImage from './checkImage.json'
import checkCloseImage from './checkCloseImage.json'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'fa-solid-900.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderHourMeter = (dataHourStart, dataHourEnd) => {
  if (dataHourStart?.length === 0 && dataHourEnd?.length === 0) {
    const content = [
      [
        {
          text: 'Hour Meter เริ่มใช้งาน : ',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter หลังใช้งาน : ',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter เริ่มใช้งาน : ',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter หลังใช้งาน : ',
          style: 'headerTable',
          alignment: 'left',
        },
      ],
    ]
    return content
  } else if (dataHourEnd.length === 0) {
    const content = [
      [
        {
          text: 'Hour Meter เริ่มใช้งาน :  ' + dataHourStart?.[0]?.DECANTERNo1,
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter หลังใช้งาน : ',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter เริ่มใช้งาน : ' + dataHourStart?.[0]?.DECANTERNo2,
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter หลังใช้งาน : ',
          style: 'headerTable',
          alignment: 'left',
        },
      ],
    ]
    return content
  } else if (dataHourStart?.length === 0) {
    const content = [
      [
        {
          text: 'Hour Meter เริ่มใช้งาน :  ',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter หลังใช้งาน : ' + dataHourEnd?.[0]?.DECANTERNo1,
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter เริ่มใช้งาน : ',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter หลังใช้งาน : ' + dataHourEnd?.[0]?.DECANTERNo2,
          style: 'headerTable',
          alignment: 'left',
        },
      ],
    ]
    return content
  } else {
    const content = [
      [
        {
          text: 'Hour Meter เริ่มใช้งาน :  ' + dataHourStart?.[0]?.DECANTERNo1,
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter หลังใช้งาน : ' + dataHourEnd?.[0]?.DECANTERNo1,
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter เริ่มใช้งาน : ' + dataHourStart?.[0]?.DECANTERNo2,
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text: 'Hour Meter หลังใช้งาน : ' + dataHourEnd?.[0]?.DECANTERNo2,
          style: 'headerTable',
          alignment: 'left',
        },
      ],
    ]
    return content
  }
  return null
}

const renderMapTime = (data, timeMap) => {
  const content = []
  _.map(timeMap, (time, index) => {
    let indexTime = false
    if (data?.length === 0) {
      return content.push([
        { text: time, style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    } else {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (time === eachData?.TimeStart) {
          indexTime = true
          content.push([
            { text: eachData?.TimeStart || '', style: 'tableContent' },
            {
              image:
                eachData?.DECANTNO1.เครื่อง1เปิด === true
                  ? checkImage?.image
                  : checkCloseImage?.image,
              fit: [9, 9],
              alignment: 'center',
            },
            {
              image:
                eachData?.DECANTNO1.เครื่อง1ปิด === true
                  ? checkImage?.image
                  : checkCloseImage?.image,
              fit: [9, 9],
              alignment: 'center',
            },
            { text: eachData?.DECANTERNo1.กระแส || '', style: 'tableContent' },
            {
              text: eachData?.DECANTERNo1['ตัน/ชม'] || '',
              style: 'tableContent',
            },
            {
              image:
                eachData?.DECANTNO2.เครื่อง2เปิด === true
                  ? checkImage?.image
                  : checkCloseImage?.image,
              fit: [9, 9],
              alignment: 'center',
            },
            {
              image:
                eachData?.DECANTNO2.เครื่อง2ปิด === true
                  ? checkImage?.image
                  : checkCloseImage?.image,
              fit: [9, 9],
              alignment: 'center',
            },
            { text: eachData?.DECANTERNo2.กระแส || '', style: 'tableContent' },
            {
              text: eachData?.DECANTERNo2['ตัน/ชม'] || '',
              style: 'tableContent',
            },
            { text: eachData?.CSTank.Temp || '', style: 'tableContent' },
            {
              image:
                eachData?.CSTank['1 ท่อ'] === true
                  ? checkImage?.image
                  : checkCloseImage?.image,
              fit: [9, 9],
              alignment: 'center',
            },
            {
              image:
                eachData?.CSTank['2 ท่อ'] === true
                  ? checkImage?.image
                  : checkCloseImage?.image,
              fit: [9, 9],
              alignment: 'center',
            },
            { text: eachData?.comment || '', style: 'tableContent' },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: time, style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    }
  })
  return content
}

const FM_PD_003 = async (form, formInput, subForm, system, date) => {
  //formInputไม่หมือนกับ07
  const colors = '#dddddd'

  const timeMap = [
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
  ]
  //find data for map in table
  const dataDecanter = _.filter(formInput?.arr, {
    subForm_name: 'DECANTER No.1-2 & CS Tank',
  })
  const dataHourStart = _.filter(formInput?.arr, {
    HourMeter: 'เริ่มใช้งาน',
  })
  const dataHourEnd = _.filter(formInput?.arr, {
    HourMeter: 'หลังใช้งาน',
  })

  const contentHourMeter = renderHourMeter(dataHourStart, dataHourEnd)
  const TableBody = renderMapTime(dataDecanter, timeMap) //ของ07ไม่มี
  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [10, 57, 10, 0],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      {
        margin: [10, 8, 10, 0],
        table: {
          widths: ['22%', '57%', '13.5%', '7.5%'],
          body: [
            [
              {
                image: 'logo_imge',
                fit: [20, 20],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: `${form?.name || ''}`,
                alignment: 'center',
                bold: true,
                fontSize: 20,
                margin: [0, 0, 0, -3],
                border: [true, true, true, false],
              },
              {
                text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
                style: 'header',
                colSpan: 2,
                margin: [0, 0, 0, -3],
                border: [true, true, true, true],
              },
              { text: '', border: [false, true, true, true] },
            ],
            [
              {
                text: 'THAI TALLOW AND OIL CO.,LTD.',
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: 12,
                border: [true, false, true, true],
              },
              {
                text: `วันที่ ${dayjs(date).format(
                  'DD เดือน MMMM พ.ศ. BBBB '
                )}`,
                alignment: 'center',
                style: 'header',
                border: [true, false, true, true],
              },
              {
                text: `วันที่บังคับใช้ ${
                  dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                }`,
                style: 'header',
                margin: [-2, -2, 0, -3],
                border: [true, true, true, true],
              },
              {
                text: `แก้ไขครั้งที่: 0${form?.editVersion || '-'}`,
                style: 'header',
                margin: [-2, -2, 0, -3],
                border: [true, true, true, true],
              },
            ],
          ],
        },
      },
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        table: {
          widths: ['25%', '25%', '25%', '25%'],
          body: [
            [
              {
                text: 'DECANTER No.1',
                colSpan: 2,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {
                text: 'DECANTER No.2',
                colSpan: 2,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
            ],
            ...contentHourMeter,
          ],
        },
      },
      {
        margin: [0, 0, 0, 0],
        table: {
          widths: [
            '11%',
            '5.6%',
            '5.6%',
            '5.8%',
            '10%',
            '5.6%',
            '5.6%',
            '5.8%',
            '10%',
            '6%',
            '6%',
            '6%',
            '17%',
          ],
          body: [
            [
              //Row 1
              {
                stack: [
                  {
                    text: 'เวลาทำงาน',
                    bold: true,
                    style: 'headerTable',
                    alignment: 'left',
                  },
                  {
                    canvas: [
                      {
                        type: 'line',
                        x1: 81, //เพิ่มเพื่อให้ด้านขวาออกไป
                        y1: -14, //เพิ่มเพื่อให้ด้านขวาลง
                        x2: -4,
                        y2: 38, //เพิ่มเพื่อให้ด้านซ้ายลง
                        lineWidth: 1,
                        lineColor: '#000000',
                      },
                    ],
                  },
                  {
                    text: 'ค่าควบคุม',
                    alignment: 'right',
                    style: 'headerTable',
                    margin: [0, -18, 0, 0],
                    bold: true,
                  },
                ],
                rowSpan: 3,
              },
              {
                text: 'DECANTER No.1',
                colSpan: 4,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
              {},
              {
                text: 'DECANTER No.2',
                colSpan: 4,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
              {},
              {
                text: 'CS Tank',
                colSpan: 3,
                style: 'headerTable',
                fillColor: colors,
              },
              {},
              {},
              { text: '', border: [true, true, true, false] },
            ],
            [
              //Row 2
              {
                text: '',
                style: 'headerTable',
                border: [true, false, false, false],
              },
              {
                text: 'เปิด',
                rowSpan: 2,
                margin: [0, 4],
                style: 'headerTable',
              },
              { text: 'ปิด', rowSpan: 2, style: 'headerTable', margin: [0, 4] },
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'ตัน/ชม',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'เปิด',
                rowSpan: 2,
                style: 'headerTable',
                margin: [0, 4],
              },
              { text: 'ปิด', rowSpan: 2, style: 'headerTable', margin: [0, 4] },
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'ตัน/ชม',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'Temp',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: '1 ท่อ',
                rowSpan: 2,
                style: 'headerTable',
                margin: [0, 4],
              },
              {
                text: '2 ท่อ',
                rowSpan: 2,
                style: 'headerTable',
                margin: [0, 4],
              },
              {
                text: 'หมายเหตุ',
                style: 'headerTable',
                border: [true, false, true, false],
              },
            ],
            [
              //Row 3
              {
                text: 'ค่าควบคุม',
                style: 'headerTable',
                alignment: 'right',
                border: [true, false, true, true],
              },
              {},
              {},
              {
                text: '(40-60 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(15-30 ตัน/ชม)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {},
              {},
              {
                text: '(40-60 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(15-30 ตัน/ชม)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '85-95 C',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {},
              {},
              { text: '', border: [true, false, true, true] },
            ],
            ...TableBody,
          ],
        },
      },
      {
        alignment: 'between',
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: 'ผู้รายงานกะ A 1.ลงชื่อ................................................. 2.ลงชื่อ................................................',
                alignment: 'left',
                style: 'tableContent',
                margin: [0, -2, 0, -2],
                border: [true, true, false, false],
              },
              {
                text: ' ผู้รายงานกะ B 1.ลงชื่อ...................................................... 2.ลงชื่อ......................................................',
                style: 'tableContent',
                margin: [0, -2, 0, -2],
                alignment: 'left',
                border: [false, true, true, false],
              },
            ],
            [
              {
                text: '    (.......................................)     (.......................................)  ',
                alignment: 'center',
                style: 'tableContent',
                border: [true, false, false, false],
              },
              {
                text: '    (.......................................)    (.......................................)  ',
                alignment: 'center',
                style: 'tableContent',
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'หัวหน้างานกะ A ลงชื่อ................................................................',
                alignment: 'left',
                style: 'tableContent',
                border: [true, false, false, false],
              },
              {
                text: 'หัวหน้างานกะ B ลงชื่อ................................................................',
                style: 'tableContent',
                alignment: 'left',
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: '(.........................................................)   ',
                alignment: 'center',
                style: 'tableContent',
                margin: [-100, -4, 0, 0],
                border: [true, false, false, true],
              },
              {
                text: '(.........................................................)    ',
                alignment: 'center',
                style: 'tableContent',
                margin: [-100, -4, 0, 0],
                border: [false, false, true, true],
              },
            ],
          ],
        },
        styles: {
          header: {
            fontSize: 8,
            bold: true,
            alignment: 'right',
          },
          header1: {
            fontSize: 12,
            bold: true,
          },
        },
      },
    ],

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: {
        font: 'FontAwesome',
        bold: true,
        alignment: 'center',
        fontSize: 10,
      },
      header: {
        bold: true,
        fontSize: 15,
        margin: [0, -2, 0, -3],
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        margin: [0, -1, 0, -2],
        fontSize: 15,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 14,
        margin: [0, -4, 0, -2],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_PD_003
