import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import RenderQC_007 from './RenderQC_007'
import less_than_or_equal_to from './less_than_or_equal_to.json'

// const checkSTD =(data)=>{
//   if(data===)
// }
pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
  MySymbol: {
    normal: 'Symbol_Regular.ttf',
    bold: 'Symbol_Bold.ttf',
  },
}

const imageMap = (item) => {
  switch (item) {
    case '<=4.5':
      return less_than_or_equal_to.image
    case '<=17':
      return less_than_or_equal_to.image // รูปภาพที่แทนค่า <=17
    default:
      return less_than_or_equal_to.image // รูปภาพที่แทนค่าที่ไม่ระบุ
  }
}
const renderMapTime = (data, SampleMap, STDMap) => {
  const content = []
  _.map(SampleMap, (Sample, index) => {
    let indexTime = false
    if (data?.length === 0) {
      console.log('sss', less_than_or_equal_to)
      return content.push([
        { text: Sample, style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        {
          stack: [
            {
              columns: [
                {
                  image: imageMap(STDMap[index]),
                  fit: [25, 20],
                  margin: [-3, 0, 0, 0],
                  alignment: 'right',
                },
                {
                  text: STDMap[index],
                  margin: [0, 2, 0, 0],
                  alignment: 'left',
                },
              ],

              margin: [0, -4, 0, -6],
            },
          ],
        },
      ])
    } else {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (Sample === eachData?.SAMPLE) {
          indexTime = true
          content.push([
            { text: eachData?.SAMPLE || '', style: 'tableContent' },
            {
              text: eachData?.Beaker,
              style: 'tableContent',
            },
            {
              text: eachData?.BS,
              style: 'tableContent',
            },
            { text: eachData?.After || '', style: 'tableContent' },
            {
              text: eachData?.Flask || '',
              style: 'tableContent',
            },
            {
              text: eachData?.FO,
              style: 'tableContent',
            },
            {
              text: eachData?.Moisture,
              style: 'tableContent',
            },
            { text: eachData?.OilNos || '', style: 'tableContent' },
            {
              text: eachData?.DryBasis || '',
              style: 'tableContent',
            },
            { text: eachData?.OilDry || '', style: 'tableContent' },
            {
              text: eachData?.OilBasis,
              style: 'tableContent',
            },
            {
              stack: [
                {
                  columns: [
                    {
                      image: imageMap(STDMap[index]),
                      fit: [25, 20],
                      margin: [-3, 0, 0, 0],
                      alignment: 'right',
                    },
                    {
                      text: STDMap[index],
                      margin: [0, 2, 0, 0],
                      alignment: 'left',
                    },
                  ],

                  margin: [0, -4, 0, -6],
                },
              ],
            },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: Sample, style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            {
              stack: [
                {
                  columns: [
                    {
                      image: imageMap(STDMap[index]),
                      fit: [25, 20],
                      margin: [-3, 0, 0, 0],
                      alignment: 'right',
                    },
                    {
                      text: STDMap[index],
                      margin: [0, 2, 0, 0],
                      alignment: 'left',
                    },
                  ],

                  margin: [0, -4, 0, -6],
                },
              ],
            },
          ])
          return content
        }
      })
    }
  })
  return content
}

const renderHeaderBody = (data, date) => {
  if (data?.length === 0) {
    return {
      text: [
        {
          text: 'วันที่.......................................  ',
          style: 'header2',
        },
        {
          text: 'Shift\t',
          style: 'header2',
        },
        { text: '', style: 'icon' },
        { text: '   A\t', style: 'header2' },
        { text: '', style: 'icon' },
        { text: '   B       ', style: 'header2' },
        {
          text: 'เวลา.......................................',
          style: 'header2',
        },
        {
          text: 'เวลาเก็บตัวอย่าง..........................................................................................',
          style: 'header2',
        },
      ],
      alignment: 'center',
      margin: [10, 10, 10, 0],
    }
  } else {
    return {
      text: [
        {
          text: `วันที่ ${dayjs(date).format('D เดือน MMMM พ.ศ. BBBB \t')}`,
          style: 'header2',
        },
        {
          text: 'Shift\t',
          style: 'header2',
        },
        { text: data?.[0].Shift.A ? '' : '', style: 'icon' },
        { text: '   A\t', style: 'header2' },
        { text: data?.[0].Shift.B ? '' : '', style: 'icon' },
        { text: '   B       ', style: 'header2' },
        {
          text: 'เวลา ' + data?.[0].Time + ' น.\t',
          style: 'header2',
        },
        {
          text: 'เวลาเก็บตัวอย่าง ' + data?.[0].TimeEx + ' น.',
          style: 'header2',
        },
      ],
      alignment: 'center',
      margin: [10, 10, 10, 0],
    }
  }
}
const FM_QC_001 = async (form, formInput, subForm, system, date) => {
  console.log('lesss', less_than_or_equal_to)
  const sampleMap = [
    'Fiber No.1',
    'Fiber No.2',
    'Fiber No.3',
    'Step A',
    'Kernel Screw No.1',
    'Kernel Screw No.2',
    'Kernel Screw No.3',
    'Before Decanter No.1',
    'Before Decanter No.2',
    'Sludge Decanter No.1',
    'Sludge Decanter No.2',
    'SCD No.1',
    'SCD No.2',
    'Feed',
    'Separator No.1',
    'Separator No.2',
    'Separator No.3',
    'Separator No.4',
    'Cooling',
  ]
  const STDMap = [
    '4.5',
    '4.5',
    '4.5',
    '17',
    '8',
    '8',
    '8',
    '10',
    '3',
    '2',
    '2',
    '3.5',
    '3.5',
    '2',
    '1',
    '1',
    '1',
    '1',
    '1',
  ]

  const TableBody = renderMapTime(formInput.arr, sampleMap, STDMap)
  const headerBody = renderHeaderBody(formInput.arr, date)
  // console.log(RenderQC_007())

  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 20, 20, 10],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [],

    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        table: {
          widths: ['23%', '54%', '23%'],
          body: [
            [
              {
                image: 'logo_imge',

                rowSpan: 2,
                fit: [35, 35],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: `TTO3: แบบฟอร์มการวิเคราะห์หารสูญเสียนํ้ามัน\nในกระบวนการผลิต (%Oil losses) `,
                alignment: 'center',
                bold: true,
                rowSpan: 3,
                fontSize: 20,
                margin: [0, 10],
              },
              {
                text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
                style: 'header',
                border: [true, true, true, false],
              },
            ],
            [
              '',
              { text: ``, alignment: 'center' },
              {
                text: `วันที่บังคับใช้ ${
                  dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                }`,
                style: 'header',
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: `THAI TALLOW AND OIL CO.,LTD.`,
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: 12,
                border: [true, false, true, true],
              },
              '',
              {
                text: `แก้ไขครั้งที่: 0${form?.editVersion || '-'}`,
                style: 'header',
                border: [true, false, true, true],
              },
            ],
          ],
        },
      },

      headerBody,
      {
        margin: [0, 10],
        table: {
          headerRows: 1,
          heights: [1],
          widths: [
            '12%',
            '8%',
            '8%',
            '8%',
            '8%',
            '8%',
            '8%',
            '8%',
            '8%',
            '8%',
            '8%',
            '8%',
          ],
          body: [
            [
              { text: 'SAMPLE', style: 'headerTable' },
              { text: 'BEAKER(B)', style: 'headerTable' },
              { text: 'B+S', style: 'headerTable' },
              { text: 'AFTER', style: 'headerTable' },
              { text: 'FLASK', style: 'headerTable' },
              { text: 'F+O', style: 'headerTable' },
              { text: '%Moisture', style: 'headerTable' },
              { text: '%Oil/NOS', style: 'headerTable' },
              { text: '%Dry/Basis', style: 'headerTable' },
              { text: '%Oil/Dry', style: 'headerTable' },
              { text: '%Oil/Basis', style: 'headerTable' },
              { text: 'STD', style: 'headerTable' },
            ],
            ...TableBody,
          ],
        },
      },
      {
        text: 'ลงชื่อ....................................................................................พนักงานควบคุมคุณภาพ',
        margin: [30, 20],
        alignment: 'left',
        style: 'headerTable',
      },
      {
        text: '',
        pageBreak: 'before',
        pageOrientation: 'portrait',
      },
      RenderQC_007(formInput, form, date),
    ],
    // footer: function (currentPage, pageCount) {
    //   return []
    // },

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: {
        font: 'FontAwesome',
        bold: true,
        alignment: 'center',
        fontSize: 10,
      },
      header: {
        bold: true,
        fontSize: 15,
        margin: [0, 0, 0, 0],
      },
      header2: {
        fontSize: 16,
        bold: true,
      },
      headerTable: {
        margin: [0, -2, 0, 0],
        fontSize: 15,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 14,
        margin: [0, -2, 0, 0],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_QC_001
