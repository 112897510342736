import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import {
  Button,
  FormControl,
  FormLabel,
  Switch,
  Select,
} from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import GaugeTypeData from '../../../../config/GaugeType.json'
import NumberSensorType from '../../../../config/NumberSensorType.json'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { InlineMath, BlockMath } from 'react-katex'
import 'katex/dist/katex.min.css'
import Latex from 'react-latex'

import { useParams, useHistory } from 'react-router-dom'
import * as actions from '../../../../redux/actions'

export default function AddPrediction() {
  const { t, i18n } = useTranslation()

  const { watch, register, handleSubmit, reset } = useForm()
  const [sensorType, setsensorType] = useState('BOOLEAN')
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const station = useSelector((state) => state.station)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(actions.stationGet(params?.stationId))
    setIsLoading(true)
    return () => {}
  }, [params])

  console.log('AddPrediction - ', station)

  // useEffect(() => {
  //   setIsLoading(true)
  //   return () => {}
  // }, [])

  // console.log('Log in AddPrediction - ', station)

  const [isShowEquation, setIsShowEquation] = useState('')
  const [isEquation, setIsEquation] = useState('')
  const [isSymbol, setIsSymbol] = useState([])

  const genSensorName = (source) => {
    if (source === 'SENSOR') {
      return _.map(station.sensors, (sensor, key) => {
        return <option value={sensor.refString}>{sensor.name}</option>
      })
    }
    if (source === 'VARIABLE') {
      return _.map(station.variables, (variable) => {
        return <option>{variable.name}</option>
      })
    }
  }

  const onSubmit = async (data, e) => {
    let confirm = window.confirm('ยืนยันการเพิ่มการทำนาย')
    let payload

    _.map(station.sensors, (sensor) => {
      if (sensor.refString === data.sensorRef) {
        payload = {
          ...data,
          stationId: station._id,
          stationName: station.name,
          sensorId: sensor._id,
          sensorName: sensor.name,
        }
      }
    })

    if (confirm) {
      e.preventDefault()
      let templateValue = station

      if (templateValue && templateValue.prediction) {
        templateValue.prediction.push(payload)
      }

      dispatch(actions.stationPut(params.stationId, templateValue)).then(() => {
        dispatch(actions.stationAll()).then(() => {
          history.goBack()
        })
      })
    }
  }

  console.log('PREDICTION DATA = ', watch('typeSource'))

  if (station && isLoading) {
    return (
      <div>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-gray-600 text-base font-bold font-sans">
                      {'เพิ่มตัวแปรที่ต้องการทำนาย'}
                    </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                      {'การทำนาย'}
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {'เลือกที่มาของข้อมูล'}
                          </label>
                          <Select
                            // defaultValue={equationData.gaugeType}
                            ref={register}
                            size="sm"
                            name="typeSource"
                            placeholder="เลือกที่มาข้อมูล"
                          >
                            <option value="SENSOR">เซนเซอร์</option>
                            <option value="VARIABLE">ตัวแปร</option>
                            <option value="HUMANINPUT">การกรอกข้อมูล</option>
                          </Select>
                        </div>
                      </div>

                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {'เลือกข้อมูล'}
                          </label>
                          <Select
                            // defaultValue={equationData.gaugeType}
                            ref={register}
                            size="sm"
                            name="sensorRef"
                            placeholder="เลือกข้อมูล"
                          >
                            {genSensorName(watch('typeSource'))}
                          </Select>
                        </div>
                      </div>

                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {'เลือกเวลาในการทำนาย'}
                          </label>
                          <Select
                            // defaultValue={equationData.gaugeType}
                            ref={register}
                            size="sm"
                            name="frequency"
                            placeholder="เลือกเวลาในการทำนาย"
                          >
                            <option value="15min">15 นาทีข้างหน้า</option>
                            <option value="1hr">1 ชั่วโมงข้างหน้า</option>
                            <option value="12hr">12 ชั่วโมงข้างหน้า</option>
                            <option value="1days">1 วันข้างหน้า</option>
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="text-center px-4 gap-0 py-3 ">
                      <Button
                        isFullWidth
                        colorScheme="blue"
                        variant="solid"
                        type="submit"
                      >
                        {t('utility.save')}{' '}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <SpinnerLoading />
  }
}
