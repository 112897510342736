import api from '../../../util/api'
import {
  FORM_INPUT_ALL,
  FORM_INPUT_GET,
  FORM_INPUT_PUT,
  FORM_INPUT_DEL,
  FORM_INPUT_LOADING,
  FORM_INPUT_ERROR,
  FORM_INPUT_POST,
} from '../types'

export const formInputAll = (id, payload) => async (dispatch) => {
  try {
    const {
      name = '',
      date = '',
      recorder = '',
      checkedMonth = false,
    } = payload
    console.log('date', recorder)
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/form-input/${id}?name=${name}&date=${date}&recorder=${recorder}&checkedMonth=${checkedMonth}`
    )
    if (status === 200) {
      dispatch({ type: FORM_INPUT_ALL, payload: data })
    }
  } catch (error) {
    console.error(error)
    dispatch({ type: FORM_INPUT_ERROR })
    throw new Error(error)
  }
}

export const formInputPost = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: FORM_INPUT_LOADING })
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/form-input/${id}`,
      payload
    )
    dispatch({ type: FORM_INPUT_POST, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: FORM_INPUT_ERROR })
    throw new Error(error)
  }
}

export const formInputGet = (formId, id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/form-input/${formId}/${id}`
    )
    if (status === 200) {
      dispatch({ type: FORM_INPUT_GET, payload: data })
    }
  } catch (error) {
    console.error(error)
    dispatch({ type: FORM_INPUT_ERROR })
    throw new Error(error)
  }
}

export const formInputPut = (formId, id, payload) => async (dispatch) => {
  try {
    dispatch({ type: FORM_INPUT_LOADING })
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/form-input/${formId}/${id}`,
      payload
    )
    dispatch({ type: FORM_INPUT_PUT, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: FORM_INPUT_ERROR })
    throw new Error(error)
  }
}

export const formInputDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: FORM_INPUT_LOADING })
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/form-input/${id}`
    )
    dispatch({ type: FORM_INPUT_DEL, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: FORM_INPUT_ERROR })
    throw new Error(error)
  }
}

export const formInputLoading = () => (dispatch) => {
  dispatch({ type: FORM_INPUT_LOADING })
}
