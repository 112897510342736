import React from 'react'
import {
  FM_BM_001,
  FM_BM_002,
  FM_BM_004,
  FM_PD_001,
  FM_PD_002,
  FM_PD_003,
  FM_PD_004,
  FM_PD_005,
  FM_PD_006,
  FM_PD_007,
  FM_PD_008,
  FM_PD_010,
  FM_BM_003,
  FM_QC_001,
  FM_QC_005,
  FM_QC_007,
  FM_BG_001,
  FM_BG_002,
  FM_BG_003,
  FM_BG_004,
  FM_BG_005,
  FM_BG_006,
  ReportForm,
  LabYieldReport,
  ReportSubForm,
} from '../../components/ReportPDF/index'

function FunctionPrintReport(form, formInput, subForm, system, date) {
  //console.log('func print', formInput)
  if (form?.pdfSetting?.formType === 'fix') {
    switch (form?.pdfSetting?.fixFormName) {
      case 'FM_PD_001':
        FM_PD_001(form, formInput, subForm, system, date)
        break
      case 'FM_PD_002':
        FM_PD_002(form, formInput, subForm, system, date)
        break
      case 'FM_PD_003':
        FM_PD_003(form, formInput, subForm, system, date)
        break
      case 'FM_PD_004':
        FM_PD_004(form, formInput, subForm, system, date)
        break
      case 'FM_PD_005':
        FM_PD_005(form, formInput, subForm, system, date)
        break
      case 'FM_PD_006':
        FM_PD_006(form, formInput, subForm, system, date)
        break
      case 'FM_PD_007':
        FM_PD_007(form, formInput, subForm, system, date)
        break
      case 'FM_PD_008':
        FM_PD_008(form, formInput, subForm, system, date)
        break
      case 'FM_PD_010':
        FM_PD_010(form, formInput, subForm, system, date)
        break
      case 'FM_BM_001':
        FM_BM_001(form, formInput, subForm, system, date)
        break
      case 'FM_BM_002':
        FM_BM_002(form, formInput, subForm, system, date)
        break
      case 'FM_BM_003':
        FM_BM_003(form, formInput, subForm, system, date)
        break
      case 'FM_BM_004':
        FM_BM_004(form, formInput, subForm, system, date)
        break
      case 'FM_QC_001':
        FM_QC_001(form, formInput, subForm, system, date)
        break
      case 'FM_QC_005':
        FM_QC_005(form, formInput, subForm, system, date)
        break
      case 'FM_QC_007':
        FM_QC_007(form, formInput, subForm, system, date)
        break
      case 'FM_BG_001':
        FM_BG_001(form, formInput, subForm, system, date)
        break
      case 'FM_BG_002':
        FM_BG_002(form, formInput, subForm, system, date)
        break
      case 'FM_BG_003':
        FM_BG_003(form, formInput, subForm, system, date)
        break
      case 'FM_BG_004':
        FM_BG_004(form, formInput, subForm, system, date)
        break
      case 'FM_BG_005':
        FM_BG_005(form, formInput, subForm, system, date)
        break
      case 'FM_BG_006':
        FM_BG_006(form, formInput, subForm, system, date)
        break
      case 'LabYieldReport':
        LabYieldReport(form, formInput, subForm, system, date)
        break
      default:
        FM_PD_001(form, formInput, subForm, system, date)
        break
    }
  } else if (form?.pdfSetting?.formType === 'generate') {
    ReportForm(form, formInput, system, date)
  }
}

export default FunctionPrintReport
