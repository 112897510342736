import React from 'react'
import PropTypes from 'prop-types'
import { sub } from 'date-fns'
import _ from 'lodash'

const ColWidthTable = (form, countAttribute, countComponentAttribute) => {
  const widths = []
  _.map(form?.components, (row) =>
    row?.attribute?.length === 0 || row?.formType === 'SELECTOR'
      ? widths.push(
          `${(
            100 /
              (form?.components?.length +
                countAttribute -
                countComponentAttribute) || 1
          ).toFixed(2)}%`
          // 'auto'
        )
      : _.map(row?.attribute, (each) =>
          widths.push(
            `${(
              100 /
                (form?.components?.length +
                  countAttribute -
                  countComponentAttribute) || 1
            ).toFixed(2)}%`
            // 'auto'
          )
        )
  )
  return widths
}
const RenderSubFormTablrFunction = (form, formInput, subForm) => {
  const content = []
  const mergeForm = _.merge(subForm)

  _.map(mergeForm?.arr, (row, index) => {
    const subHeader = []
    const tableDataBody = []
    const widthFeild = []
    const countAttribute = _.sumBy(row?.components, (rows) =>
      rows?.formType === 'SELECTOR' ? 0 : rows?.attribute?.length
    )
    const countComponentAttribute = _.countBy(row?.components, (rows) =>
      rows?.formType === 'SELECTOR' ? 0 : rows?.attribute?.length !== 0
    )
    const col = ColWidthTable(
      row,
      countAttribute,
      countComponentAttribute?.true || 0
    )

    widthFeild.push(
      _.map(
        row?.components,
        (topic) => `${(100 / row?.components?.length).toFixed(2)}%`
      )
    )

    const checkType = (data) => {
      if (typeof data === 'number') {
        return data.toFixed(2)
      }

      return data
    }

    _.map(formInput?.arr, (data, index) => {
      if (row?._id === data?.subForm_id) {
        const setData = []

        _.map(row?.components, (each) => {
          each?.attribute?.length === 0 || each?.formType === 'SELECTOR'
            ? setData.push({
                text: ` ${checkType(data[each?.name]) || '-'}`,
                alignment: 'center',
              })
            : _.map(each?.attribute, (eachData) =>
                setData.push({
                  text: ` ${
                    checkType(data?.[each?.name]?.[eachData?.subTopic]) || '-'
                  }`,
                  alignment: 'center',
                })
              )
        })
        tableDataBody.push(setData)
      }
    })
    const headTable = []
    _.map(row?.components, (topic, index) => {
      topic?.attribute?.length === 0 || topic?.formType === 'SELECTOR'
        ? headTable.push({
            text: `${topic?.topic}`,
            alignment: 'center',
            bold: true,
            colSpan: 1,
            rowSpan: 2,
          })
        : _.map(topic?.attribute, (each) =>
            headTable.push({
              text: `${topic?.topic}`,
              alignment: 'center',
              bold: true,
              colSpan:
                topic?.attribute?.length !== 0 || topic?.formType !== 'SELECTOR'
                  ? topic?.attribute?.length
                  : 1,
            })
          )
    })

    _.map(row?.components, (data, index) =>
      data?.attribute?.length === 0 || data?.formType === 'SELECTOR'
        ? subHeader.push({ text: '', border: [false, false, false, false] })
        : _.map(data?.attribute, (each) =>
            subHeader.push({
              text: `${each?.subTopic}`,
              alignment: 'center',
              bold: true,
              colSpan: 1,
            })
          )
    )

    const table = {
      margin: [0, 3],
      table: {
        widths: col,
        body: [headTable, subHeader, ...tableDataBody],
      },
    }

    console.log('table', table)
    content.push({ ...table })
  })
  return content
}

RenderSubFormTablrFunction.propTypes = {
  form: PropTypes.object,
  formInput: PropTypes.object,
  subForm: PropTypes.object,
}

export default RenderSubFormTablrFunction
