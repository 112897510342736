import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import checkImage from './checkImage.json'
import checkCloseImage from './checkCloseImage.json'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderHourMeter = (data) => {
  if (data.length === 0) {
    const content = [
      [
        {
          text: 'ชั่วโมงเริ่มใช้งาน',
          style: 'headerTable',
        },
        {
          text: '',
        },
        {
          text: '',
        },
        {
          text: '',
          border: [true, false, true, false],
          fillColor: '#dddddd',
        },
      ],
      [
        {
          text: 'ชั่วโมงหลังใช้งาน',
          style: 'headerTable',
        },
        {
          text: '',
        },
        {
          text: '',
        },
        {
          text: '',
          border: [true, false, true, true],
          fillColor: '#dddddd',
        },
      ],
    ]
    return content
  } else if (data?.length === 2) {
    const content = _.map(data, (row) => [
      {
        text:
          row?.HourMeter === 'ชั่วโมงเริ่มใช้งาน'
            ? 'ชั่วโมงเริ่มใช้งาน'
            : 'ชั่วโมงหลังใช้งาน',
        style: 'headerTable',
        alignment: 'center',
      },
      {
        text:
          row?.subForm_name === 'ชั่วโมงใช้งานเครื่อง1-2'
            ? row?.SEPERATERNo1
            : row?.SEPERATERNo3 || '',
        style: 'headerTable',
        alignment: 'center',
      },
      {
        text:
          row?.subForm_name === 'ชั่วโมงใช้งานเครื่อง1-2'
            ? row?.SEPERATERNo2
            : row?.SEPERATERNo4 || '',
        style: 'headerTable',
        alignment: 'center',
      },
      {
        text: '',
        border:
          row?.HourMeter === 'ชั่วโมงเริ่มใช้งาน'
            ? [true, false, true, false]
            : [true, false, true, true],
        fillColor: '#dddddd',
      },
    ])
    return content
  } else if (data?.[0]?.HourMeter === 'ชั่วโมงเริ่มใช้งาน') {
    const content = _.map(data, (row) => [
      {
        text: 'ชั่วโมงเริ่มใช้งาน',
        style: 'headerTable',
        alignment: 'center',
      },
      {
        text:
          row?.subForm_name === 'ชั่วโมงใช้งานเครื่อง1-2'
            ? row?.SEPERATERNo1
            : row?.SEPERATERNo3 || '',
        style: 'headerTable',
        alignment: 'center',
      },
      {
        text:
          row?.subForm_name === 'ชั่วโมงใช้งานเครื่อง1-2'
            ? row?.SEPERATERNo2
            : row?.SEPERATERNo4 || '',
        style: 'headerTable',
        alignment: 'center',
      },
      {
        text: '',
        border:
          row?.HourMeter === 'ชั่วโมงเริ่มใช้งาน'
            ? [true, false, true, false]
            : [true, false, true, true],
        fillColor: '#dddddd',
      },
    ])
    content.push([
      {
        text: 'ชั่วโมงหลังใช้งาน',
        style: 'headerTable',
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: '',
        border: [true, false, true, true],
        fillColor: '#dddddd',
      },
    ])
    return content
  } else if (data?.[0]?.HourMeter === 'ชั่วโมงหลังใช้งาน') {
    const content = [
      [
        {
          text: 'ชั่วโมงเริ่มใช้งาน',
          style: 'headerTable',
        },
        {
          text: '',
        },
        {
          text: '',
        },
        {
          text: '',
          border: [true, false, true, false],
          fillColor: '#dddddd',
        },
      ],
    ]

    _.map(data, (row) =>
      content.push([
        {
          text: 'ชั่วโมงหลังใช้งาน',
          style: 'headerTable',
          alignment: 'center',
        },
        {
          text:
            row?.subForm_name === 'ชั่วโมงใช้งานเครื่อง1-2'
              ? row?.SEPERATERNo1
              : row?.SEPERATERNo3 || '',
          style: 'headerTable',
          alignment: 'center',
        },
        {
          text:
            row?.subForm_name === 'ชั่วโมงใช้งานเครื่อง1-2'
              ? row?.SEPERATERNo2
              : row?.SEPERATERNo4 || '',
          style: 'headerTable',
          alignment: 'center',
        },
        {
          text: '',
          border:
            row?.HourMeter === 'ชั่วโมงเริ่มใช้งาน'
              ? [true, false, true, false]
              : [true, false, true, true],
          fillColor: '#dddddd',
        },
      ])
    )

    return content
  }

  return null
}

const renderMapTime = (data, timeMap) => {
  const content = []
  _.map(timeMap, (time, index) => {
    let indexTime = false
    if (data?.length === 0) {
      return content.push([
        { text: time, style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    } else {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (time === eachData?.TimeStart) {
          indexTime = true
          content.push(
            eachData?.subForm_name === 'SEPERATER No.1-2'
              ? [
                  //SEPERATER No.1-2
                  { text: eachData?.TimeStart || '', style: 'tableContent' },
                  {
                    image:
                      eachData?.SEPERATERNo1.เดินเครื่อง === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.SEPERATERNo1.หยุดเครื่อง === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.SEPERATERNo1.กระแส || '',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.SEPERATERNo1['Slude Temp'] || '',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.SEPERATERNo1.Flow || '',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.SEPERATERNo1.ปริมาณน้ำมัน || '',
                    style: 'tableContent',
                  },
                  {
                    image:
                      eachData?.SEPERATERNo1['เริ่ม CIP'] === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.SEPERATERNo1['หยุด CIP'] === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.SEPERATERNo2.เดินเครื่อง === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.SEPERATERNo2.หยุดเครื่อง === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.SEPERATERNo2.กระแส || '',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.SEPERATERNo2['Slude Temp'] || '',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.SEPERATERNo2.Flow || '',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.SEPERATERNo2.ปริมาณน้ำมัน || '',
                    style: 'tableContent',
                  },
                  {
                    image:
                      eachData?.SEPERATERNo2['เริ่ม CIP'] === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.SEPERATERNo2['หยุด CIP'] === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.comment || '',
                    style: 'tableContent',
                  },
                ]
              : [
                  //SEPERATER No.3-4
                  { text: eachData?.TimeStart || '', style: 'tableContent' },
                  {
                    image:
                      eachData?.SEPERATERNo3.เดินเครื่อง === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.SEPERATERNo3.หยุดเครื่อง === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.SEPERATERNo3.กระแส || '',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.SEPERATERNo3['Slude Temp'] || '',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.SEPERATERNo3.Flow || '',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.SEPERATERNo3.ปริมาณน้ำมัน || '',
                    style: 'tableContent',
                  },
                  {
                    image:
                      eachData?.SEPERATERNo3['เริ่ม CIP'] === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.SEPERATERNo3['หยุด CIP'] === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.SEPERATERNo4.เดินเครื่อง === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.SEPERATERNo4.หยุดเครื่อง === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.SEPERATERNo4.กระแส || '',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.SEPERATERNo4['Slude Temp'] || '',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.SEPERATERNo4.Flow || '',
                    style: 'tableContent',
                  },
                  {
                    text: eachData?.SEPERATERNo4.ปริมาณน้ำมัน || '',
                    style: 'tableContent',
                  },
                  {
                    image:
                      eachData?.SEPERATERNo4['เริ่ม CIP'] === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.SEPERATERNo4['หยุด CIP'] === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.comment || '',
                    style: 'tableContent',
                  },
                ]
          )
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: time, style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    }
  })
  return content
}

const renderOil = (data) => {
  if (data?.length === 0) {
    return {
      text: 'รวม.......................ตัน',
      alignment: 'center',
      style: 'tableContent',
      margin: [0, 1, 0, -2],
      rowSpan: 2,
      border: [true, false, true, true],
    }
  } else {
    return {
      text: 'รวม...' + ' ' + data?.[0].TotalOil + ' ' + '...ตัน',
      alignment: 'center',
      style: 'tableContent',
      margin: [0, 1, 0, -2],
      rowSpan: 2,
      border: [true, false, true, true],
    }
  }
}
const FM_PD_004 = async (form, formInput, subForm, system, date) => {
  const colors = '#dddddd'
  const timeMap = [
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
  ]
  //find data for map in table
  const dataHourMeter = _.filter(formInput?.arr, {
    subForm_name: 'ชั่วโมงใช้งานเครื่อง1-2',
  })
  const dataHourMeter3 = _.filter(formInput?.arr, {
    subForm_name: 'ชั่วโมงใช้งานเครื่อง3-4',
  })
  const data1_2 = _.filter(formInput?.arr, {
    subForm_name: 'SEPERATER No.1-2',
  })
  const data3_4 = _.filter(formInput?.arr, {
    subForm_name: 'SEPERATER No.3-4',
  })
  const dataTotalOil = _.filter(formInput?.arr, {
    subForm_name: 'ปริมาณน้ำมันที่เก็บได้',
  })
  const contentOil = renderOil(dataTotalOil)
  const contentHourMeter = renderHourMeter(dataHourMeter)
  const contentHourMeter3 = renderHourMeter(dataHourMeter3)
  const TableBody1_2 = renderMapTime(data1_2, timeMap)
  const TableBody3_4 = renderMapTime(data3_4, timeMap)
  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [8, 59, 8, 0],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      {
        margin: [8, 5, 8, 0],
        style: 'tableExample',
        table: {
          widths: ['20%', '64%', '16%'],
          body: [
            [
              {
                image: 'logo_imge',
                rowSpan: 2,
                fit: [20, 20],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: `${form?.name || ''}`,
                alignment: 'center',
                bold: true,
                rowSpan: 2,
                font: 'AngsanaNew',
                fontSize: '16',
                margin: [0, 0],
                border: [true, true, true, false],
              },
              {
                text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
                fontSize: '10',
              },
            ],
            [
              { text: '', alignment: 'center' },
              { text: ``, alignment: 'center' },
              {
                text: `วันที่บังคับใช้: ${
                  dayjs(form?.enforceDate).format('DD MMM YYYY') || '-'
                }`,
                fontSize: '10',
              },
            ],
            [
              {
                text: 'THAITALLOW AND OIL CO.,LTD.',
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: '9',
                border: [true, false, true, true],
              },
              {
                text: `วันที่ ${dayjs(date).format(
                  'DD เดือน MMMM พ.ศ. BBBB '
                )}`,
                alignment: 'center',
                bold: true,
                fontSize: '12',
                border: [true, false, true, true],
              },
              {
                text: `แก้ไขครั้งที่: 0${form?.editVersion || '-'}`,
                fontSize: '10',
              },
            ],
          ],
        },
      },
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        table: {
          widths: ['9.6%', '42.9%', '42.9%', '4.6%'],
          body: [
            [
              {
                text: 'เครื่องจักร',
                style: 'headerTable',
                fillColor: colors,
              },
              {
                text: 'SEPERATER No.1',
                style: 'headerTable',
                fillColor: colors,
              },

              {
                text: 'SEPERATER No.2',
                style: 'headerTable',
                fillColor: colors,
              },
              {
                text: '',
                style: 'headerTable',
                fillColor: colors,
                border: [true, true, true, false],
              },
            ],
            ...contentHourMeter,
          ],
        },
      },
      {
        margin: [0, 0, 0, 0],
        table: {
          widths: [
            '12.5%',
            '5.25%',
            '5.5%',
            '5%',
            '5%',
            '5%',
            '5%',
            '5%',
            '5%',
            '5.25%',
            '5.5%',
            '5%',
            '5%',
            '5%',
            '5%',
            '5%',
            '5%',
            '6%',
          ],
          body: [
            [
              //Row 1
              {
                stack: [
                  {
                    text: 'เวลาทำงาน',
                    bold: true,
                    style: 'headerTable',
                    alignment: 'left',
                  },
                  {
                    canvas: [
                      {
                        type: 'line',
                        x1: 57, //เพิ่มเพื่อให้ด้านขวาออกไป
                        y1: -10, //เพิ่มเพื่อให้ด้านขวาลง
                        x2: -4,
                        y2: 25, //เพิ่มเพื่อให้ด้านซ้ายลง
                        lineWidth: 1,
                        lineColor: '#000000',
                      },
                    ],
                  },
                  {
                    text: 'ค่าควบคุม',
                    alignment: 'right',
                    style: 'headerTable',
                    margin: [0, -15, 0, 0],
                    bold: true,
                  },
                ],
                rowSpan: 3,
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: 'กระแส',
                rowSpan: 2,
                margin: [-3, 2, -3, -2],
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'Slude',
                style: 'headerTable',
                alignment: 'center',
                border: [true, true, true, false],
              },
              {
                text: 'Flow',
                style: 'headerTable',
                rowSpan: 2,
                margin: [-3, 2, -3, -2],
                border: [true, true, true, false],
              },
              {
                text: 'ปริมาณ',
                style: 'headerTable',
                rowSpan: 2,
                margin: [-3, 2, -3, -2],
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: 'กระแส',
                rowSpan: 2,
                style: 'headerTable',
                margin: [-3, 2, -3, -2],
                border: [true, true, true, false],
              },
              {
                text: 'Slude',
                style: 'headerTable',
                alignment: 'center',
                border: [true, true, true, false],
              },
              {
                text: 'Flow',
                style: 'headerTable',
                rowSpan: 2,
                margin: [-3, 2, -3, -2],
                border: [true, true, true, false],
              },
              {
                text: 'ปริมาณ',
                style: 'headerTable',
                rowSpan: 2,
                margin: [-3, 2, -3, -2],
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
            ],
            [
              //Row 2
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'เดินเครื่อง',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'หยุดเครื่อง',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {},
              {
                text: 'Temp',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'เริ่ม CIP',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'หยุด CIP',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'เดินเครื่อง',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'หยุดเครื่อง',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {},
              {
                text: 'Temp',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'เริ่ม CIP',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'หยุด CIP',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'หมายเหตุ',
                style: 'headerTable',
                border: [true, false, true, false],
              },
            ],
            [
              //Row 3
              {
                text: '',
                style: 'headerTable',
                alignment: 'right',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(40-60 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(85-98 ํC)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(ตัน/ชม.)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: 'นํ้ามัน',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(40-60 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(85-98 ํC)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(ตัน/ชม.)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: 'นํ้ามัน',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
            ],
            ...TableBody1_2,
            //...TableBody4_6,
          ],
        },
      },
      {
        table: {
          widths: ['9.6%', '42.9%', '42.9%', '4.6%'],
          body: [
            [
              {
                text: 'เครื่องจักร',
                style: 'headerTable',
                fillColor: colors,
              },
              {
                text: 'SEPERATER No.3',
                style: 'headerTable',
                fillColor: colors,
              },

              {
                text: 'SEPERATER No.4',
                style: 'headerTable',
                fillColor: colors,
              },
              {
                text: '',
                style: 'headerTable',
                fillColor: colors,
                border: [true, true, true, false],
              },
            ],
            ...contentHourMeter3,
            //  ...contentHourMeterAfter,
          ],
        },
      },
      {
        margin: [0, 0, 0, 0],
        table: {
          widths: [
            '12.5%',
            '5.25%',
            '5.5%',
            '5%',
            '5%',
            '5%',
            '5%',
            '5%',
            '5%',
            '5.25%',
            '5.5%',
            '5%',
            '5%',
            '5%',
            '5%',
            '5%',
            '5%',
            '6%',
          ],
          body: [
            [
              //Row 1
              {
                stack: [
                  {
                    text: 'เวลาทำงาน',
                    bold: true,
                    style: 'headerTable',
                    alignment: 'left',
                  },
                  {
                    canvas: [
                      {
                        type: 'line',
                        x1: 57, //เพิ่มเพื่อให้ด้านขวาออกไป
                        y1: -10, //เพิ่มเพื่อให้ด้านขวาลง
                        x2: -4,
                        y2: 25, //เพิ่มเพื่อให้ด้านซ้ายลง
                        lineWidth: 1,
                        lineColor: '#000000',
                      },
                    ],
                  },
                  {
                    text: 'ค่าควบคุม',
                    alignment: 'right',
                    style: 'headerTable',
                    margin: [0, -15, 0, 0],
                    bold: true,
                  },
                ],
                rowSpan: 3,
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: 'กระแส',
                rowSpan: 2,
                style: 'headerTable',
                margin: [-3, 2, -3, -2],
                border: [true, true, true, false],
              },
              {
                text: 'Slude',
                style: 'headerTable',
                alignment: 'center',
                border: [true, true, true, false],
              },
              {
                text: 'Flow',
                style: 'headerTable',
                rowSpan: 2,
                margin: [-3, 2, -3, -2],
                border: [true, true, true, false],
              },
              {
                text: 'ปริมาณ',
                style: 'headerTable',
                rowSpan: 2,
                margin: [-3, 2, -3, -2],
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: 'กระแส',
                rowSpan: 2,
                style: 'headerTable',
                margin: [-3, 2, -3, -2],
                border: [true, true, true, false],
              },
              {
                text: 'Slude',
                style: 'headerTable',
                alignment: 'center',
                border: [true, true, true, false],
              },
              {
                text: 'Flow',
                style: 'headerTable',
                rowSpan: 2,
                margin: [-3, 2, -3, -2],
                border: [true, true, true, false],
              },
              {
                text: 'ปริมาณ',
                style: 'headerTable',
                rowSpan: 2,
                margin: [-3, 2, -3, -2],
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                alignment: 'left',
                border: [true, true, true, false],
              },
            ],
            [
              //Row 2
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'เดินเครื่อง',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'หยุดเครื่อง',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {},
              {
                text: 'Temp',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'เริ่ม CIP',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'หยุด CIP',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'เดินเครื่อง',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'หยุดเครื่อง',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {},
              {
                text: 'Temp',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'เริ่ม CIP',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'หยุด CIP',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'หมายเหตุ',
                style: 'headerTable',
                border: [true, false, true, false],
              },
            ],
            [
              //Row 3
              {
                text: '',
                style: 'headerTable',
                alignment: 'right',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(40-60 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(85-98 ํC)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(ตัน/ชม.)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: 'นํ้ามัน',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(40-60 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(85-98 ํC)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(ตัน/ชม.)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: 'นํ้ามัน',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
            ],
            ...TableBody3_4,
          ],
        },
      },
      {
        alignment: 'between',
        table: {
          widths: [`15.3%`, `37.25%`, `37.35%`, `10.1%`],
          body: [
            [
              {
                text: 'ปริมาณนํ้ามันที่ได้',
                alignment: 'center',
                style: 'tableContent',
                margin: [0, 2, 0, -2],
                border: [true, true, true, false],
              },
              {
                text: 'ผู้รายงานกะ A ลงชื่อ   1. .................................................',
                alignment: 'left',
                style: 'tableContent',
                margin: [0, 2, 0, -2],
                border: [false, true, true, false],
              },
              {
                text: `ผู้รายงานกะ B ลงชื่อ   1. .................................................`,
                style: 'tableContent',
                alignment: 'left',
                margin: [0, 2, 0, -2],
                border: [false, true, true, false],
              },
              { text: '', border: [true, true, true, true], rowSpan: 3 },
            ],
            [
              contentOil,
              {
                text: '2. ................................................',
                alignment: 'center',

                style: 'tableContent',
                margin: [0, 1, 0, -2],
                border: [false, false, true, false],
              },
              {
                text: `2. .................................................`,
                style: 'tableContent',
                alignment: 'center',
                margin: [0, 1, 0, -2],
                border: [false, false, true, false],
              },
              {},
            ],
            [
              {},
              {
                text: 'หัวหน้าผลิตกะ A ลงชื่อ .............................................',
                alignment: 'left',
                style: 'tableContent',
                margin: [0, 0, 0, 0],
                border: [true, true, true, true],
              },
              {
                text: 'หัวหน้างานกะ B ลงชื่อ ................................................',
                style: 'tableContent',
                alignment: 'left',
                margin: [0, 0, 0, 0],
                border: [true, true, true, true],
              },
              {},
            ],
          ],
        },
        styles: {
          header: {
            fontSize: 8,
            bold: true,
            alignment: 'right',
          },
          header1: {
            fontSize: 12,
            bold: true,
          },
        },
      },
    ],

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: {
        font: 'FontAwesome',
        bold: true,
        alignment: 'center',
        fontSize: 7,
      },
      header: {
        bold: true,
        fontSize: 14,
        margin: [0, 0, 0, -3],
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        margin: [-3, -3, -3, -2],
        fontSize: 10,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 10,
        margin: [0, -3, 0, -2],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_PD_004
