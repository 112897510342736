import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import currencyFormatter from 'currency-formatter'
import _ from 'lodash'
import moment from 'moment'
import RenderSubFormTablrFunction from './function/RenderSubFormTablrFunction'
import FooterReportType2 from './FooterFile/FooterReportType2'
import HeaderReportType1 from './HeaderFile/HeaderReportType1'
import HeaderReportType2 from './HeaderFile/HeaderReportType2'
import FooterReportSigleForm from './FooterFile/FooterReportSigleForm'
import FooterReportSigleComment from './FooterFile/FooterReportSigleComment'
import FooterReportWastWater from './FooterFile/FooterReportWastWater'
import FooterReportQC from './FooterFile/FooterReportQC'
import FooterReportBlank from './FooterFile/FooterReportBlank'
import FooterReportBiomass from './FooterFile/FooterReportBiomass'
import dayjs from 'dayjs'

pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf',
  },
}

// const CheckHeadTable = (data) => {
//   _.map(form?.components, (row) => ({ text: row?.topic }))
// }

const ReportSubForm = async (form, formInput, subForm, system, date) => {
  const mergeForm = _.merge(subForm)

  const tableBody = RenderSubFormTablrFunction(form, formInput, subForm)

  const widthFeild = [
    _.map(mergeForm?.arr, (row) =>
      _.map(row?.components, (topic) => `${100 / 8}%`)
    ),
  ]

  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: form?.pdfSetting?.orientations || 'portrait',
    pageMargins: [10, 120, 10, 160],
    defaultStyle: {
      font: 'THSarabunNew',
      fontSize: '12',
    },
    header: [
      form?.pdfSetting?.header === 'type_1'
        ? HeaderReportType1(form, date)
        : '',
      form?.pdfSetting?.header === 'type_2'
        ? HeaderReportType2(form, date)
        : '',
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
    },
    content: [...tableBody],
    footer(currentPage, pageCount) {
      return [
        form?.pdfSetting?.footer === 'type_1' ? FooterReportSigleForm() : '',
        form?.pdfSetting?.footer === 'type_2' ? FooterReportSigleComment() : '',
        form?.pdfSetting?.footer === 'type_3' ? FooterReportType2() : '',
        form?.pdfSetting?.footer === 'type_4' ? FooterReportWastWater() : '',
        form?.pdfSetting?.footer === 'type_5' ? FooterReportQC() : '',
        form?.pdfSetting?.footer === 'type_6' ? FooterReportBlank() : '',
        form?.pdfSetting?.footer === 'type_7' ? FooterReportBiomass() : '',
        {
          table: {
            widths: ['100%'],

            body: [
              [
                {
                  text: ' หน้า ' + currentPage.toString() + ' จาก ' + pageCount,
                  alignment: 'right',
                  border: [false, false, false, false],
                  margin: [15, 0, 15, 0],
                },
              ],
            ],
          },
        },
      ]
    },
    images: {
      logo_imge: system?.logo,
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default ReportSubForm
