import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { red } from '@mui/material/colors'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderCheckContRows = (data, timePM) => {
  const content = []
  console.log('length', data.length)
  _.map(timePM, (time, index) => {
    let indexTime = false
    if (data?.length === 0) {
      return content.push([
        {
          text: Intl.NumberFormat('en-US').format(time),
          style: 'tableContent',
        },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    } else {
      //console.log('Time', time, index, data.length)
      _.map(data, (row, indexData) => {
        if (time === row?.HourPM) {
          indexTime = true
          content.push([
            {
              text: Intl.NumberFormat('en-US').format(row?.HourPM),
              style: 'tableContent',
            },
            {
              text: dayjs(row?.Date).format('D/MM/BBBB') || '',
              style: 'tableContent',
            },
            {
              text: row?.HourPMTrue,
              style: 'tableContent',
            },
            {
              text: row?.Security,
              style: 'tableContent',
            },
            {
              text: row?.comment,
              style: 'tableContent',
            },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            {
              text: Intl.NumberFormat('en-US').format(time),
              style: 'tableContent',
            },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    }
  })
  return content
}

// const renderTableBody = (data) => {
//   const DataSet = renderCheckContRows(data)
//   console.log(DataSet)

//   if (DataSet?.length !== 32) {
//     for (let i = DataSet?.length; i < 32; i++) {
//       DataSet.push([
//         { text: ' ', style: 'tableContent' },
//         { text: ' ', style: 'tableContent' },
//         { text: ' ', style: 'tableContent' },
//         { text: ' ', style: 'tableContent' },
//         { text: ' ', style: 'tableContent' },

//       ])
//     }
//   }
//   return DataSet
// }

const FM_BG_006 = async (form, formInput, subForm, system, date) => {
  console.log(formInput)
  const timePM = [
    '2000',
    '4000',
    '6000',
    '8000',
    '10000',
    '12000',
    '14000',
    '16000',
    '18000',
    '20000',
    '22000',
    '24000',
    '26000',
    '28000',
    '30000',
    '32000',
    '34000',
    '36000',
    '38000',
    '40000',
    '42000',
    '44000',
    '46000',
    '48000',
    '50000',
    '52000',
    '54000',
    '56000',
    '58000',
  ]
  const TableBody = renderCheckContRows(formInput.arr, timePM)

  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [10, 69, 10, 10],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      {
        margin: [10, 8, 10, 0],
        style: 'tableExample',
        table: {
          widths: ['40%', '30%', '15%', '15%'],
          body: [
            [
              {
                image: 'logo_imge',
                rowSpan: 2,
                fit: [27, 27],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: `${form?.name || ''}`,
                alignment: 'center',
                bold: true,
                rowSpan: 3,
                font: 'AngsanaNew',
                fontSize: '18',
                margin: [4, 2],
              },
              {
                text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
                style: 'headerTable',
                colSpan: 2,
                margin: [0, 0, 0, 0],
              },
              {},
            ],
            [
              { text: '', alignment: 'center' },
              {},
              {
                text: 'แก้ไขครั้งที่: 01',
                style: 'headerTable',
                margin: [0, 2, 0, -2],
              },
              {
                text: 'หน้าที่ : 1/1',
                style: 'headerTable',
                margin: [0, 2, 0, -2],
              },
            ],
            [
              {
                text: 'THAITALLOW AND OIL CO.,LTD.',
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: '12',
                border: [true, false, true, true],
              },
              {},

              {
                text: `วันที่บังคับใช้: ${
                  dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                }`,
                style: 'headerTable',
                colSpan: 2,
                margin: [0, 2, 0, -2],
              },
              {},
            ],
          ],
        },
      },
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        table: {
          widths: ['10%', '10%', '10%', '10%', '60%'],
          body: [
            [
              // Row 1
              {
                text: 'ชม. PM',
                alignment: 'center',
                bold: true,
              },
              {
                text: 'ว/ด/ป',
                alignment: 'center',
                bold: true,
              },
              {
                text: 'ชม. PM จริง',
                alignment: 'center',
                bold: true,
              },
              {
                text: 'ผู้ตรวจสอบ',
                alignment: 'center',
                bold: true,
              },
              {
                text: 'หมายเหตุ',
                alignment: 'center',
                bold: true,
              },
            ],

            ...TableBody,
          ],
        },
      },
      {
        alignment: 'between',
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: [
                  { text: '* ', color: 'red' },
                  { text: 'ทำ PM ทุกๆ 2,000 ซม.' },
                ],
                fontSize: 14,
                alignment: 'left',

                border: [false, false, false, false],
              },
              {
                text: '\nหัวหน้าแผนก....................................................',

                alignment: 'center',
                fontSize: 14,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: ' ',
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: ' ',

                alignment: 'center',
                fontSize: 14,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                alignment: 'left',

                border: [false, false, false, false],
              },
              {
                text: ' ',

                alignment: 'center',
                fontSize: 14,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: ' ',
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
          ],
        },
        styles: {
          header: {
            fontSize: 8,
            bold: true,
            alignment: 'right',
          },
          header1: {
            fontSize: 12,
            bold: true,
          },
        },
      },
    ],

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: { font: 'FontAwesome' },
      header: {
        fontSize: 14,
        bold: true,
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        fontSize: 12,
        margin: [0, -2],
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 14,
        margin: [0, -2],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_BG_006
