import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

export default function ModalInputEdit({
  isOpen,
  onOpen,
  onClose,
  dataSelect,
}) {
  return (
    <div>
      {console.log(dataSelect)}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>แก้ไขข้อมูล {dataSelect?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="font-bold text-red-500 text-center ">
              กำลังพัฒนา...
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}
