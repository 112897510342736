import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { useParams } from 'react-router'
import { Button, useDisclosure } from '@chakra-ui/react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import RenderDataHistoryForm from './RenderDataHistoryForm'
import BackButton from 'components/Button/BackButton'
import ModalInputEdit from 'components/Modal/ModalInputEdit'
import { useFieldArray, useForm } from 'react-hook-form'
import RenderDataHistorySubForm from './RenderDataHistorySubForm'
import Spinners from '../../components/Loading/SpinnerLoading'

export default function InputHistoryDetail() {
  const params = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const form = useSelector((state) => state.form)
  const formInput = useSelector((state) => state.formInput)
  const subForm = useSelector((state) => state.subForm)
  const system = useSelector((state) => state.system)
  const me = useSelector((state) => state.me)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [date, setDate] = useState(new Date())
  const [dataSelect, setDataSelect] = useState({})

  useEffect(() => {
    dispatch(actions.formGet(params.id))

    return () => {}
  }, [])

  useEffect(() => {
    if (form?.subForm_status) {
      dispatch(actions.subFormAll({ form: params.id }))
    }
    if (form?.name) {
      dispatch(
        actions.formInputAll(params?.id, {
          ...form,
          date,
          checkedMonth: true,
          recorder: me?._id,
        })
      )
    }
    return () => {}
  }, [form, date])
  // console.log('Form:', form)
  // console.log('Date:', date)

  const prop = {
    form,
    formInput,
    isOpen,
    onOpen,
    formId: params?.id,
    onClose,
    modalOpen: (data) => {
      setDataSelect(data)
      onOpen()
    },
  }

  const renderDataNormalForm = () => <RenderDataHistoryForm {...prop} />

  const renderDataWithSubForm = () => (
    <RenderDataHistorySubForm subForm={subForm} {...prop} />
  )
  const renderModal = () => (
    <ModalInputEdit
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      dataSelect={dataSelect}
    />
  )
  if (formInput.isLoading || !formInput.isCompleted || !form.isCompleted) {
    return <Spinners />
  }
  if (!formInput.isLoading || formInput.isCompleted) {
    return (
      <div>
        {renderModal()}
        <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
          <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1">
                <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                  รายงานแบบฟอร์ม
                </h6>
                <h2 className="text-gray-800 text-2xl font-bold font-sans ">
                  {form?.name}
                </h2>
                <BackButton />
              </div>
            </div>
            <div className="py-2">
              {console.log('ffff', form.subForm_status)}
              {form?.subForm_status
                ? renderDataWithSubForm()
                : renderDataNormalForm()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
