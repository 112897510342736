import React, { useState, useEffect } from 'react'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import * as actions from '../../redux/actions/'
import { useForm } from 'react-hook-form'
// import EstimateBillLists from "./components/EstimateBillLists";
import _ from 'lodash'
import dayjs from 'dayjs'
import {
  Button,
  Select,
  Text,
  Lorem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Checkbox,
  Input,
  FormControl,
} from '@chakra-ui/react'
import EstimateBillDetailLists from './components/EstimateBillDeatailLists'
import { printPDF } from './components/EstimateBillReport'
import { LocalLaundryService, Sd } from '@mui/icons-material'

export default function ElectricBillEstimatorDetail() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const electricConsumption = useSelector((state) => state.electricConsumption)
  const systemInfo = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    dispatch(actions.electricConsumptionGet(id)).then(() => {
      dispatch(actions.systemInfoGet()).then(() => {
        setIsLoading(true)
      })
    })
    return () => {}
  }, [])

  const { register, handleSubmit, watch } = useForm()

  const selectSubType = () => {
    let type = watch('electricType')
    if (type === 'type-4') {
      return [
        <option value="TOD">อัตรา TOD</option>,
        <option value="TOU">อัตรา TOU</option>,
      ]
    } else if (type === 'type-5') {
      return [
        <option value="TOU">อัตรา TOU</option>,
        <option value="noTOU">ไม่มีมิเตอร์ TOU</option>,
      ]
    } else {
      return [
        <option value="nomal">อัตราปกติ</option>,
        <option value="TOU">อัตรา TOU</option>,
      ]
    }
  }

  const selectVoltDemand = () => {
    let subType = watch('subElectricType')
    let type = watch('electricType')
    if (type === 'type-1' && subType === 'nomal') {
      return [
        <option value="less150">
          ใช้พลังงานไฟฟ้าไม่เกิน 150 หน่วยต่อเดือน
        </option>,
        <option value="more150">ใช้พลังงานไฟฟ้าเกิน 150 หน่วยต่อเดือน</option>,
      ]
    } else if (type === 'type-1' && subType === 'TOU') {
      return [
        <option value="volt-2"> แรงดันตั้งแต่ 22 - 33 KV</option>,
        <option value="volt-3"> แรงดันน้อยกว่า 22 KV</option>,
      ]
    } else if (type === 'type-2') {
      return [
        <option value="volt-2"> แรงดันตั้งแต่ 22 - 33 KV</option>,
        <option value="volt-3"> แรงดันน้อยกว่า 22 KV</option>,
      ]
    } else if (type === 'type-7' && subType === 'nomal') {
      return []
    } else if (type === 'type-7') {
      return [
        <option value="volt-2"> แรงดันตั้งแต่ 22 - 33 KV</option>,
        <option value="volt-3"> แรงดันน้อยกว่า 22 KV</option>,
      ]
    } else if (type && subType) {
      return [
        <option value="volt-1"> แรงดันมากกว่า 69 KV</option>,
        <option value="volt-2"> แรงดันตั้งแต่ 22 - 33 KV</option>,
        <option value="volt-3"> แรงดันน้อยกว่า 22 KV</option>,
      ]
    } else {
      return []
    }
  }

  const editElectricParameter = (data) => {
    const payload = {
      ...data,
      ftValue: parseFloat(data.ftValue),
      service: parseFloat(data.service),
    }
    console.log('Send API EDIT ELECTRIC PARAMETER ..........', payload)
    dispatch(actions.electricConsumptionPut(id, payload))
    alert('บันทึกสำเร็จ')
    onClose()
  }
  console.log('LOG REGISTER - ', watch('electricType'))

  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded ">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              ประมาณการบิลค่าไฟฟ้า
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              ประมาณการบิลค่าไฟฟ้าประจำเดือน{' '}
              {dayjs(electricConsumption.date).format('MMMM')}/
              {dayjs(electricConsumption.date).format('BBBB')}
            </h2>
          </div>
        </div>

        <div className="flex justify-between my-2">
          <Link to={'/electric-bill-estimator'}>
            <Button variant="outline" size="sm">
              กลับ
            </Button>
          </Link>
          <div className="flex justify-between m-2">
            <Button
              leftIcon={<i className="fas fa-edit"></i>}
              colorScheme="red"
              size="sm"
              onClick={onOpen}
            >
              แก้ไข
            </Button>

            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              size="xl"
              onClose={onClose}
            >
              <form onSubmit={handleSubmit(editElectricParameter)}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader className="font-sans">
                    แก้ไขค่าทางไฟฟ้า
                  </ModalHeader>
                  <ModalBody>
                    <div className="flex flex-col">
                      <div className="flex flex-row items-center box-content w-49 h-20">
                        <div className="w-2/5 m-2 p-0 text-left font-sans text-xl">
                          ประเภทค่าไฟ :
                        </div>
                        <div className="flex flex-row w-3/5">
                          <div className="m-2 font-sans text-sm text-center">
                            <Select
                              defaultValue={electricConsumption.electricType}
                              placeholder="เลือกประเภท"
                              type="text"
                              name="electricType"
                              ref={register}
                            >
                              <option value="type-1">ประเภทที่ 1</option>
                              <option value="type-2">ประเภทที่ 2</option>
                              <option value="type-3">ประเภทที่ 3</option>
                              <option value="type-4">ประเภทที่ 4</option>
                              <option value="type-5">ประเภทที่ 5</option>
                              <option value="type-6">ประเภทที่ 6</option>
                              <option value="type-7">ประเภทที่ 7</option>
                            </Select>
                          </div>
                          <div className="m-2 font-sans text-sm text-center">
                            <Select
                              defaultValue={electricConsumption.subElectricType}
                              placeholder="ประเภทย่อย"
                              type="text"
                              name="subElectricType"
                              ref={register}
                            >
                              {selectSubType()}
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center box-content w-49 h-20">
                        <div className="w-2/5 m-2 p-0 text-left font-sans text-xl">
                          ขนาดแรงดันไฟฟ้า :
                        </div>
                        <div className="m-2 px-10 w-3/5 font-sans text-sm text-left">
                          <Select
                            defaultValue={electricConsumption.voltDemand}
                            placeholder="แรงดันไฟฟ้า"
                            type="text"
                            name="voltDemand"
                            ref={register}
                          >
                            {selectVoltDemand()}
                          </Select>
                        </div>
                      </div>
                      <div className="flex flex-row items-center box-content w-96 h-20">
                        <div className="w-2/5 m-2 p-0 text-left font-sans text-xl">
                          ค่า Ft :
                        </div>
                        <div className="m-2 mx-5 w-3/5 font-sans text-sm text-center">
                          <Input
                            defaultValue={electricConsumption.ftValue}
                            size="md"
                            width="auto"
                            placeholder="ใส่ค่า Ft"
                            name="ftValue"
                            ref={register}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row items-center box-content w-96 h-20">
                        <div className="w-2/5 m-2 p-0 text-left font-sans text-xl">
                          ค่าบริการ :
                        </div>
                        <div className="m-2 mx-5 w-3/5 font-sans text-sm text-center">
                          <Input
                            defaultValue={electricConsumption.service}
                            size="md"
                            width="auto"
                            placeholder="ใส่ค่าบริการ"
                            type="number"
                            name="service"
                            ref={register}
                          />
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="font-sans">
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      บันทึก
                    </Button>
                    <Button onClick={onClose}>ยกเลิก</Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
            <Button
              leftIcon={<i className="fas fa-print"></i>}
              colorScheme="yellow"
              size="sm"
              onClick={() => {
                printPDF(electricConsumption, systemInfo)
              }}
            >
              พิมพ์
            </Button>
          </div>
        </div>
        <div className="flex flex-wrap p-4 w-full bg-gray-200 rounded-sm">
          <div className="w-full md:w-1/2 ">
            <div className="my-2 ">
              <span className="font-bold text-xl">{'ค่าไฟฟ้าทั้งหมด : '}</span>
              <span className="font-bold text-xl">
                {'' +
                  Intl.NumberFormat('en-US').format(
                    Number(electricConsumption.summary).toFixed(2)
                  ) +
                  ' บาท'}
              </span>
            </div>
            <div className="my-2 mx-2">
              <span>{'ค่าพลังงานไฟฟ้าทั้งหมด : '}</span>
              <span>
                {'' +
                  Intl.NumberFormat('en-US').format(
                    Number(electricConsumption.power).toFixed(2)
                  ) +
                  ' บาท'}
              </span>
            </div>
            <div className="my-2 mx-2">
              <span className="">{'ค่าความต้องการไฟฟ้า PEAK : '}</span>
              <span>
                {'' +
                  Intl.NumberFormat('en-US').format(
                    Number(electricConsumption.needPower).toFixed(2)
                  ) +
                  ' บาท'}
              </span>
            </div>
            <div className="my-2 mx-2">
              <span className="">{'ค่าบริการ : '}</span>
              <span>
                {'' +
                  Intl.NumberFormat('en-US').format(
                    Number(electricConsumption.service).toFixed(2)
                  ) +
                  ' บาท'}
              </span>
            </div>
            <div className="my-2 mx-2">
              <span className="font-bold text-lg">{'รวมค่าไฟฟ้าฐาน : '}</span>
              <span className="font-bold text-lg">
                {'' +
                  Intl.NumberFormat('en-US').format(
                    Number(electricConsumption.base).toFixed(2)
                  ) +
                  ' บาท'}
              </span>
            </div>
            <hr />
            <div className="my-2 mx-2">
              <span className="">{'ค่า ft : '}</span>
              <span>
                {'' +
                  Intl.NumberFormat('en-US').format(
                    Number(electricConsumption.ft).toFixed(2)
                  ) +
                  ' บาท'}
              </span>
            </div>
            <div className="my-2 mx-2">
              <span className="">{'ค่าเพาเวอร์แฟคเตอร์ : '}</span>
              <span>
                {'' +
                  Intl.NumberFormat('en-US').format(
                    Number(electricConsumption.powerFactor).toFixed(2)
                  ) +
                  ' บาท'}
              </span>
            </div>
            <div className="my-2 mx-2">
              <span className="font-bold text-lg">
                {'รวมค่าไฟฟ้าก่อนรวมภาษี : '}
              </span>
              <span className="font-bold text-lg">
                {'' +
                  Intl.NumberFormat('en-US').format(
                    Number(
                      parseFloat(electricConsumption.summary) -
                        parseFloat(electricConsumption.vat)
                    ).toFixed(2)
                  ) +
                  ' บาท'}
              </span>
            </div>
            <hr />
            <div className="my-2 mx-2">
              <span className="">{'ค่าภาษี : '}</span>
              <span>
                {'' +
                  Intl.NumberFormat('en-US').format(
                    Number(electricConsumption.vat).toFixed(2)
                  ) +
                  ' บาท'}
              </span>
            </div>
            <div className="my-2 mx-2 ">
              <span className="font-bold text-lg">
                {'ค่าไฟฟ้าทั้งหมด (รวมภาษี) : '}
              </span>
              <span className="font-bold text-xl">
                {'' +
                  Intl.NumberFormat('en-US').format(
                    Number(electricConsumption.summary).toFixed(2)
                  ) +
                  ' บาท'}
              </span>
            </div>
            <hr />
          </div>
          <div className="w-full md:w-1/2 overflow-x-auto ">
            <div className="mt-8 mb-2 text-sm font-semibold">
              ข้อมูลเพิ่มเติม
            </div>
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {'ช่วงที่ความต้องการพลังไฟฟ้าช่วง On Peak สูงสุด: '}
              </span>
              <span className="text-sm">
                {dayjs(electricConsumption.date_max_on_peak).format(
                  'วันddd ที่ D MMMM BBBB เวลา HH:mm น.'
                )}
              </span>
            </div>
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {'ความต้องการพลังไฟฟ้าสูงสุดช่วง On Peak : '}
              </span>
              <span className="text-sm">
                {'' + electricConsumption.max_on_peak.toFixed(2) + ' กิโลวัตต์'}
              </span>
            </div>
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {'ความต้องการพลังไฟฟ้าสูงสุดช่วง Off Peak : '}
              </span>
              <span className="text-sm">
                {'' +
                  electricConsumption.max_off_peak.toFixed(2) +
                  ' กิโลวัตต์'}
              </span>
            </div>
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {'ความต้องการพลังไฟฟ้าสูงสุดช่วง Holiday : '}
              </span>
              <span className="text-sm">
                {'' + electricConsumption.max_holiday.toFixed(2) + ' กิโลวัตต์'}
              </span>
            </div>
            <hr />
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {'ปริมาณการใช้พลังงานไฟฟ้าทั้งหมด : '}
              </span>
              <span className="text-sm">
                {'' +
                  Intl.NumberFormat('en-US').format(
                    Number(electricConsumption.all).toFixed(2)
                  ) +
                  ' หน่วย'}
              </span>
            </div>
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {'ปริมาณการใช้พลังงานไฟฟ้าช่วง On Peak : '}
              </span>
              <span className="text-sm">
                {'' +
                  Intl.NumberFormat('en-US').format(
                    Number(electricConsumption.onPeak).toFixed(2)
                  ) +
                  ' หน่วย'}
              </span>
            </div>
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {'ปริมาณการใช้พลังงานไฟฟ้าช่วง Off Peak : '}
              </span>
              <span className="text-sm">
                {'' +
                  Intl.NumberFormat('en-US').format(
                    Number(electricConsumption.offPeak).toFixed(2)
                  ) +
                  ' หน่วย'}
              </span>
            </div>
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {'ปริมาณการใช้พลังงานไฟฟ้าช่วง Holiday : '}
              </span>
              <span className="text-sm">
                {'' +
                  Intl.NumberFormat('en-US').format(
                    Number(electricConsumption.holiday).toFixed(2)
                  ) +
                  ' หน่วย'}
              </span>
            </div>
          </div>
        </div>
        <div className="my-2 mx-2 text-lg font-bold">
          ประมาณการค่าไฟฟ้ารายวัน
        </div>
        <div className="p-4  w-full bg-gray-200 rounded-sm border-black border-2">
          {_.size(electricConsumption.history) ? (
            <div className="overflow-x-auto">
              <EstimateBillDetailLists history={electricConsumption.history} />
            </div>
          ) : (
            <div className="flex justify-center p-4">
              <div>ไม่มีข้อมูล</div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}
