import React from 'react'
import { Box } from '@chakra-ui/react'
export default function StaticNumberGauge({
  staticData,
  unit = '',
  graphLabel = '',
}) {
  //console.log('nann', staticData)
  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      className=" ml-4 my-2 p-2 w-full  md:w-full  h-25"
    >
      <h5 className="font-bold text-2xl"> {graphLabel} </h5>
      <div>
        <h1
          className={`font-bold text-4xl ${
            staticData === null ? 'text-red-500' : 'text-blue-500'
          } mb-0`}
        >
          {staticData === null ? 0 : parseFloat(staticData).toFixed(2)}
        </h1>
        <h3 className="font-semibold text-lg"> {unit}</h3>
      </div>
    </Box>
  )
}
