import React, { useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Code,
  FormControl,
  Input,
  Select,
  Checkbox,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { InlineMath, BlockMath } from 'react-katex'
import 'katex/dist/katex.min.css'
import Latex from 'react-latex'

import * as actions from '../../redux/actions'
import config from '../../config'

export default function EquationComponent({
  equationData,
  stationData,
  placeToEdit = 'station',
}) {
  const { t } = useTranslation()
  const [isEditorMode, setIsEditorMode] = useState(false)
  const { register, handleSubmit, watch } = useForm()
  const dispatch = useDispatch()
  // Merged the Sensor Data and Make new Station Data to Database
  const prepareUpdateDatabase = (data) => {
    const selectedSensor = _.find(
      stationData.equations,
      (sensor) => sensor._id === equationData._id
    )
    const selectedSensorIndex = _.findIndex(
      stationData.equations,
      (sensor) => sensor._id === equationData._id
    )
    const mergedequationData = _.merge(selectedSensor, data)
    let editedStation = stationData
    editedStation.equations[selectedSensorIndex] = mergedequationData

    // is on Template or on Station FIXME: Magic Code

    if (placeToEdit === 'template') {
      console.log('This is Template not station')
      dispatch(actions.stationTemplatePut(stationData._id, editedStation))
    } else {
      console.log('This is Station')
      dispatch(actions.stationPut(stationData._id, editedStation)).then(() => {
        dispatch(actions.stationAll())
      })
    }

    setIsEditorMode(false)
    window.location.reload()
  }

  const prepateDeleteSensor = (sensorId) => {
    let confirm = window.confirm('ยืนยันการลบเซ็นเซอร์')
    if (confirm) {
      const selectedSensorIndex = _.findIndex(
        stationData.equations,
        (sensor) => sensor._id === sensorId
      )

      let editedStation = stationData
      if (editedStation.equations) {
        console.log('Original Sensor Stack', editedStation.equations)
        editedStation.equations.splice(selectedSensorIndex, 1)
        console.log('SPLICED')
        console.log('New Sensor Stack', editedStation.equations)
      }

      // is on Template or on Station FIXME: Magic Code
      if (placeToEdit === 'template') {
        console.log('This is Template not station')
        dispatch(actions.stationTemplatePut(stationData._id, editedStation))
      } else {
        console.log('This is Station')
        dispatch(actions.stationPut(stationData._id, editedStation)).then(
          () => {
            dispatch(actions.stationAll())
          }
        )
      }
      setIsEditorMode(false)
    }
  }

  const editorModeComponent = () => (
    <div>
      <FormControl>
        <label className="text-sm font-bold"> {t('sensor.name')} </label>
        <Input
          type="text"
          name="name"
          size="sm"
          defaultValue={equationData.name}
          ref={register}
        />
      </FormControl>

      <FormControl>
        <label className="text-sm font-bold"> {t('sensor.sensorDesc')} </label>
        <Input
          type="text"
          name="description"
          size="sm"
          defaultValue={equationData.description}
          ref={register}
        />
      </FormControl>

      <FormControl>
        <label className="text-sm font-bold">Iot Reference String</label>
        <Input
          type="text"
          name="refString"
          size="sm"
          defaultValue={equationData.refString}
          ref={register}
        />{' '}
      </FormControl>

      <FormControl>
        <label className="text-sm font-bold">
          {' '}
          {t('sensor.sensorFeature')}{' '}
        </label>
        {equationData.sensorType === 'BOOLEAN' && (
          <Select
            defaultValue={equationData.gaugeType}
            ref={register}
            size="sm"
            name="gaugeType"
          >
            <option value="">{t('utility.noFeature')}</option>
            {_.map(
              config.ComponentType.SensorGaugeType,
              (_gaugeType, index) => (
                <option value={_gaugeType.value} key={index}>
                  {_gaugeType.describe}
                </option>
              )
            )}
          </Select>
        )}

        {equationData.sensorType === 'NUMBER' && (
          <Select
            defaultValue={equationData.gaugeType}
            ref={register}
            size="sm"
            name="gaugeType"
          >
            {_.map(
              config.ComponentType.NumberSensorType,
              (_gaugeType, index) => (
                <option value={_gaugeType.value} key={index}>
                  {_gaugeType.describe}
                </option>
              )
            )}
          </Select>
        )}
      </FormControl>

      {equationData.sensorType === 'BOOLEAN' && (
        <div>
          <FormControl>
            <label className="text-sm font-bold">
              Iot Control Reference String
            </label>
            <Input
              type="text"
              name="controlRefString"
              size="sm"
              defaultValue={equationData.controlRefString}
              ref={register}
            />{' '}
          </FormControl>
          <FormControl>
            <label className="text-sm font-bold">
              {' '}
              {t('control.pulseControl')}{' '}
            </label>
            <Select
              defaultValue={equationData.controlWithPulse}
              ref={register}
              size="sm"
              name="controlWithPulse"
            >
              <option value={true}>{t('control.yes')}</option>
              <option value={false}>{t('control.no')}</option>
            </Select>
          </FormControl>
          <FormControl>
            <label className="text-sm font-bold">
              {t('sensor.defaultValue')}
            </label>
            <Select
              defaultValue={equationData.booleanDefaultValue}
              ref={register}
              size="sm"
              name="booleanDefaultValue"
            >
              <option value={Boolean(true)}>True</option>
              <option value={Boolean(false)}>False</option>
            </Select>
          </FormControl>
        </div>
      )}

      {equationData.sensorType === 'NUMBER' && (
        <>
          <FormControl>
            <label className="text-sm font-bold">
              {t('sensor.unitOfValue')}{' '}
            </label>
            <Input
              type="text"
              name="valueUnit"
              size="sm"
              defaultValue={equationData.valueUnit}
              ref={register}
            />{' '}
          </FormControl>
          <FormControl>
            <label className="text-sm font-bold">
              {t('sensor.defaultValue')}{' '}
            </label>
            <Input
              type="number"
              name="numericDefaultValue"
              size="sm"
              defaultValue={equationData.numericDefaultValue}
              ref={register}
            />{' '}
          </FormControl>
        </>
      )}
    </div>
  )

  const displayModeComponent = () => (
    <div className="flex flex-col justify-start">
      <p className="text-sm "> {equationData.description} </p>
      <Latex displayMode={true}>
        {'$$ f(x) = ' + equationData.latex + '$$'}
      </Latex>
    </div>
  )

  return (
    <Box borderRadius="lg" padding="4" className="bg-white my-2">
      <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
        <div className="flex gap-3">
          <div className="w-5/6">
            <h5 className="text-lg font-bold font-sans">
              {' '}
              {equationData.name}{' '}
            </h5>
          </div>

          <div className="flex gap-1 ">
            {isEditorMode === true ? (
              <>
                <Button
                  size="sm"
                  type="button"
                  colorScheme="gray"
                  onClick={() => setIsEditorMode(false)}
                >
                  {t('utility.cancel')}
                </Button>{' '}
                <Button size="sm" colorScheme="green" type="submit">
                  {t('utility.save')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="sm"
                  colorScheme="yellow"
                  onClick={() => setIsEditorMode(true)}
                  type="button"
                >
                  {t('utility.edit')}
                </Button>
                <Button
                  size="sm"
                  colorScheme="red"
                  type="button"
                  onClick={() => prepateDeleteSensor(equationData._id)}
                >
                  {t('utility.delete')}
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="flex gap-3">
          <div>
            {isEditorMode === true
              ? editorModeComponent()
              : displayModeComponent()}
          </div>
        </div>
      </form>
    </Box>
  )
}
