import React from 'react'
import _ from 'lodash'
import GeneralGaugeSelector from '../../../components/Gagues/Functions/GeneralGaugeSelector'
import SensorDataRendering from '../../../components/Sensors/Functions/SensorDataRendering'
import VariableDataRendering from '../../../components/Variables/Function/VariableMainRendering'
import Config from '../../../config'

export default function DashboardMonitorComponent2({
  currentStation,
  station,
}) {
  //console.log('currentStation in component', currentStation)
  // let orderedGauge = _.orderBy(currentStation.gauges, 'orderLevel', 'asc')
  return (
    <div>
      <div className="grid grid-cols-1 gap-x-4 gap-y-1  lg:grid-cols-4 md:gid-cols-3 mr-6 mb-4">
        {_.map(currentStation, (gauge, index) => {
          // return <div>{gauge?.gaugeLabel}</div>

          if (gauge.gaugeSource === Config.TypeOfSource.GaugeSource.sensor) {
            return (
              <GeneralGaugeSelector
                gaugeData={gauge}
                key={index}
                stationData={station}
                staticValue={SensorDataRendering(station, gauge)}
              />
            )
          } else {
            return (
              <GeneralGaugeSelector
                gaugeData={gauge}
                key={index}
                stationData={station}
                staticValue={VariableDataRendering(station, gauge.variableId)}
              />
            )
          }
        })}
      </div>
    </div>
  )
}
