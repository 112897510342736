import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderTableBody = (data) => {
  const content = []
  if (data?.length === 0) {
    for (let i = 0; i < 5; i++) {
      content.push([
        { text: `Week ${i + 1}` || '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    }
    content.push(
      [
        { text: `Total` || '', style: 'tableContent', bold: true },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ],
      [
        {
          text: 'Estimated Biogas Output',
          style: 'headerTable',
          alignment: 'left',
          colSpan: 3,
          border: [true, false, false, false],
        },
        {},
        {},
        {
          text: '',
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, false],
        },
        {
          text: 'Nm³/Month',
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, false],
        },
        {
          text: 'Estimated Electric from Biogas Output',
          style: 'headerTable',
          alignment: 'left',
          colSpan: 3,
          margin: [0, 2, 0, 2],
          border: [false, false, false, false],
        },
        {},
        {},
        {
          text: '',
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, false],
        },
        {
          text: 'Kw./Month',
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, false],
        },
        {
          text: '',
          style: 'headerTable',
          margin: [0, 6, 0, -6],
          border: [false, false, true, false],
        },
      ],
      [
        {
          text: 'Estimated used Biomass Output',
          style: 'headerTable',
          alignment: 'left',
          colSpan: 3,
          border: [true, false, false, true],
        },
        {},
        {},
        {
          text: '',
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {
          text: 'Mt/Month',
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {
          text: 'Estimated Electric from Biomass Output',
          style: 'headerTable',
          alignment: 'left',
          colSpan: 3,
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {},
        {},
        {
          text: '',
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {
          text: 'Kw./Month',
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {
          text: '',
          style: 'headerTable',
          margin: [0, 6, 0, -6],
          border: [false, false, true, true],
        },
      ],
      [
        {
          text: '',
          style: 'headerTable',
          alignment: 'left',
          colSpan: 5,
          border: [true, false, false, true],
        },
        {},
        {},
        {},
        {},
        {
          text: 'Total Estimated Electric Output',
          style: 'headerTable',
          alignment: 'left',
          colSpan: 3,
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {},
        {},
        {
          text: '',
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {
          text: 'Kwh./Month',
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {
          text: '',
          style: 'headerTable',
          margin: [0, 6, 0, -6],
          border: [false, false, true, true],
        },
      ]
    )

    return content
  } else {
    content.push(
      [
        { text: 'Week 1', style: 'tableContent' },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].TotalWeek1),
          style: 'tableContent',
        },
        {
          text: Intl.NumberFormat('en-US').format(
            data?.[0].VolumeKernelProduct1
          ),
          style: 'tableContent',
        },
        { text: '0', style: 'tableContent' },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].VolumeWasteWater1),
          style: 'tableContent',
        },
        { text: '45', style: 'tableContent' },
        {
          text: Number(data?.[0].OperationHour1).toFixed(),
          style: 'tableContent',
        },
        { text: '24', style: 'tableContent' },
        { text: '24', style: 'tableContent' },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].CPOOutput1),
          style: 'tableContent',
        },
        {
          text: Number(data?.[0].CPKOOutput1).toFixed(),
          style: 'tableContent',
        },
      ],
      [
        { text: 'Week 2', style: 'tableContent' },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].TotalWeek2),
          style: 'tableContent',
        },
        {
          text: Intl.NumberFormat('en-US').format(
            data?.[0].VolumeKernelProduct2
          ),
          style: 'tableContent',
        },
        { text: '0', style: 'tableContent' },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].VolumeWasteWater2),
          style: 'tableContent',
        },
        { text: '45', style: 'tableContent' },
        {
          text: Number(data?.[0].OperationHour2).toFixed(),
          style: 'tableContent',
        },
        { text: '24', style: 'tableContent' },
        { text: '24', style: 'tableContent' },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].CPOOutput2),
          style: 'tableContent',
        },
        {
          text: Number(data?.[0].CPKOOutput2).toFixed(),
          style: 'tableContent',
        },
      ],
      [
        { text: 'Week 3', style: 'tableContent' },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].TotalWeek3),
          style: 'tableContent',
        },
        {
          text: Intl.NumberFormat('en-US').format(
            data?.[0].VolumeKernelProduct3
          ),
          style: 'tableContent',
        },
        { text: '0', style: 'tableContent' },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].VolumeWasteWater3),
          style: 'tableContent',
        },
        { text: '45', style: 'tableContent' },
        {
          text: Number(data?.[0].OperationHour3).toFixed(),
          style: 'tableContent',
        },
        { text: '24', style: 'tableContent' },
        { text: '24', style: 'tableContent' },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].CPOOutput3),
          style: 'tableContent',
        },
        {
          text: Number(data?.[0].CPKOOutput3).toFixed(),
          style: 'tableContent',
        },
      ],
      [
        { text: 'Week 4', style: 'tableContent' },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].TotalWeek4),
          style: 'tableContent',
        },
        {
          text: Intl.NumberFormat('en-US').format(
            data?.[0].VolumeKernelProduct4
          ),
          style: 'tableContent',
        },
        { text: '0', style: 'tableContent' },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].VolumeWasteWater4),
          style: 'tableContent',
        },
        { text: '45', style: 'tableContent' },
        {
          text: Number(data?.[0].OperationHour4).toFixed(),
          style: 'tableContent',
        },
        { text: '24', style: 'tableContent' },
        { text: '24', style: 'tableContent' },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].CPOOutput4),
          style: 'tableContent',
        },
        {
          text: Number(data?.[0].CPKOOutput4).toFixed(),
          style: 'tableContent',
        },
      ],
      [
        { text: 'Week 5', style: 'tableContent' },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].TotalWeek5),
          style: 'tableContent',
        },
        {
          text: Intl.NumberFormat('en-US').format(
            data?.[0].VolumeKernelProduct5
          ),
          style: 'tableContent',
        },
        { text: '0', style: 'tableContent' },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].VolumeWasteWater5),
          style: 'tableContent',
        },
        { text: '45', style: 'tableContent' },
        {
          text: Number(data?.[0].OperationHour5).toFixed(),
          style: 'tableContent',
        },
        { text: '24', style: 'tableContent' },
        { text: '24', style: 'tableContent' },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].CPOOutput5),
          style: 'tableContent',
        },
        {
          text: Number(data?.[0].CPKOOutput5).toFixed(),
          style: 'tableContent',
        },
      ],
      [
        { text: 'Total', style: 'tableContent', bold: true },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].TotalOfWeek),
          style: 'tableContent',
          bold: true,
        },
        {
          text: Intl.NumberFormat('en-US').format(
            data?.[0].VolumeKernelProductTotal
          ),
          style: 'tableContent',
          bold: true,
        },
        { text: '0', style: 'tableContent', bold: true },
        {
          text: Intl.NumberFormat('en-US').format(
            data?.[0].VolumeWasteWaterTotal
          ),
          style: 'tableContent',
          bold: true,
        },
        { text: '45', style: 'tableContent', bold: true },
        {
          text: Number(data?.[0].OperationHourTotal).toFixed(),
          style: 'tableContent',
          bold: true,
        },
        { text: '24', style: 'tableContent', bold: true },
        { text: '24', style: 'tableContent', bold: true },
        {
          text: Intl.NumberFormat('en-US').format(data?.[0].CPOOutputTotal),
          style: 'tableContent',
          bold: true,
        },
        {
          text: Number(data?.[0].CPKOOutputTotal).toFixed(),
          style: 'tableContent',
          bold: true,
        },
      ],
      [
        {
          text: 'Estimated Biogas Output',
          style: 'headerTable',
          alignment: 'left',
          colSpan: 3,
          border: [true, false, false, false],
        },
        {},
        {},
        {
          text: Intl.NumberFormat('en-US').format(
            Number(data?.[0].BiogasOutput).toFixed()
          ),

          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, false],
        },
        {
          text: 'Nm³/Month',
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, false],
        },
        {
          text: 'Estimated Electric from Biogas Output',
          style: 'headerTable',
          alignment: 'left',
          colSpan: 3,
          margin: [0, 2, 0, 2],
          border: [false, false, false, false],
        },
        {},
        {},
        {
          text: Intl.NumberFormat('en-US').format(
            Number(data?.[0].ElectricBiogasOutput).toFixed()
          ),
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, false],
        },
        {
          text: 'Kw./Month',
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, false],
        },
        {
          text: '',
          style: 'headerTable',
          margin: [0, 6, 0, -6],
          border: [false, false, true, false],
        },
      ],
      [
        {
          text: 'Estimated used Biomass Output',
          style: 'headerTable',
          alignment: 'left',
          colSpan: 3,
          border: [true, false, false, true],
        },
        {},
        {},
        {
          text: Intl.NumberFormat('en-US').format(
            Number(data?.[0].BiomasUsedOutput).toFixed()
          ),
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {
          text: 'Mt/Month',
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {
          text: 'Estimated Electric from Biomass Output',
          style: 'headerTable',
          alignment: 'left',
          colSpan: 3,
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {},
        {},
        {
          text: Intl.NumberFormat('en-US').format(
            Number(data?.[0].ElectricBiomassOutput).toFixed()
          ),
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {
          text: 'Kw./Month',
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {
          text: '',
          style: 'headerTable',
          margin: [0, 6, 0, -6],
          border: [false, false, true, true],
        },
      ],
      [
        {
          text: '',
          style: 'headerTable',
          alignment: 'left',
          colSpan: 5,
          border: [true, false, false, true],
        },
        {},
        {},
        {},
        {},
        {
          text: 'Total Estimated Electric Output',
          style: 'headerTable',
          alignment: 'left',
          colSpan: 3,
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {},
        {},
        {
          text: Intl.NumberFormat('en-US').format(
            Number(data?.[0].TotalElectricOutput).toFixed()
          ),
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {
          text: 'Kwh./Month',
          style: 'headerTable',
          margin: [0, 2, 0, 2],
          border: [false, false, false, true],
        },
        {
          text: '',
          style: 'headerTable',
          margin: [0, 6, 0, -6],
          border: [false, false, true, true],
        },
      ]
    )
  }
  return content
}

const FM_PD_010 = async (form, formInput, subForm, system, date) => {
  const TableBody = renderTableBody(formInput?.arr)
  //console.log('Form input', formInput.arr.name)
  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 120, 20, 10],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      {
        margin: [20, 30, 20, 10],
        table: {
          widths: ['23%', '54%', '12%', '11%'],
          body: [
            [
              {
                image: 'logo_imge',
                rowSpan: 2,
                fit: [35, 35],
                alignment: 'center',
                margin: [0, 5, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: 'แผนกระบวนการผลิต',
                alignment: 'center',
                bold: true,
                fontSize: 20,
                border: [true, true, true, false],
              },
              {
                text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
                style: 'header',
                colSpan: 2,
                margin: [0, 0, 0, -3],
                border: [true, true, true, true],
              },
              {},
            ],
            [
              {},
              {
                text: '(Production Plan)',
                alignment: 'center',
                bold: true,
                fontSize: 20,
                border: [true, false, true, false],
              },
              {
                text: `แก้ไขครั้งที่: 0${form?.editVersion || '-'}`,
                alignment: 'center',
                style: 'header',

                border: [true, true, true, true],
              },
              {
                text: `หน้าที่ 1/1`,

                style: 'header',
                border: [true, true, true, true],
              },
            ],
            [
              {
                text: `THAI TALLOW AND OIL CO.,LTD.`,
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: 12,
                border: [true, false, true, true],
              },
              {
                text: `ประจำเดือน ${dayjs(date).format('MMMM')}`,
                alignment: 'center',
                bold: true,
                fontSize: 20,
                border: [true, false, true, true],
              },
              {
                text: `วันที่บังคับใช้ ${
                  dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                }`,
                style: 'header',
                alignment: 'center',

                colSpan: 2,
                border: [true, true, true, true],
              },
              {},
            ],
          ],
        },
      },
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        margin: [0, 10],
        table: {
          widths: [
            '6%',
            '9%',
            '11%',
            '11%',
            '11%',
            '9%',
            '10%',
            '8%',
            '8%',
            '8%',
            '9%',
          ],
          body: [
            [
              {
                text: '',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'Volume Of raw',
                style: 'headerTable',
                margin: [0, 6, 0, -6],
                border: [true, true, true, false],
              },
              {
                text: 'Volume Of Kernel',
                style: 'headerTable',
                margin: [0, 2, 0, 2],
                border: [true, true, true, false],
              },
              {
                text: 'Volume Of Kernel',
                style: 'headerTable',
                margin: [0, 2, 0, 2],
                border: [true, true, true, false],
              },
              {
                text: 'Volume Of',
                style: 'headerTable',
                margin: [0, 2, 0, 2],
                border: [true, true, true, false],
              },
              {
                text: 'Operation Plan',
                style: 'headerTable',
                margin: [0, 2, 0, 2],
                colSpan: 4,
              },
              {},
              {},
              {},
              {
                text: 'CPO Output',
                style: 'headerTable',
                margin: [0, 6, 0, -6],
                border: [true, true, true, false],
              },
              {
                text: 'CPKO Output',
                style: 'headerTable',
                margin: [0, 6, 0, -6],
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: 'List',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'materials (Mt)',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 6, 0, -6],
                border: [true, false, true, true],
              },
              {
                text: 'Produced',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'Purchased',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'Waste Water',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'Capacity',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'Operation Hour',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'CPO Plan',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'CPKO Plan',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: '(Mt/Week)',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 6, 0, -6],
                border: [true, false, true, true],
              },
              {
                text: '(Mt/Week)',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 6, 0, -6],
                border: [true, false, true, true],
              },
            ],
            [
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(Mt)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(Mt)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(M³)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(30-45 Mt/hrs)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(Hr/Week)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(Hr/Day)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(Hr/Day)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
            ],
            ...TableBody,
            [
              {
                text: 'Remark : ',
                style: 'headerTable',
                alignment: 'left',
                border: [false, false, false, false],
              },
              {
                text: '1.Load CPO,CPKO on Stock',
                style: 'tableContent',
                alignment: 'left',
                colSpan: 10,
                border: [false, false, false, false],
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              {
                text: ' ',
                style: 'headerTable',
                alignment: 'left',
                border: [false, false, false, false],
              },
              {
                text: '2.CC: PD , RM , QC , BG and BM',
                style: 'tableContent',
                alignment: 'left',
                colSpan: 10,
                border: [false, false, false, false],
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
          ],
        },
      },

      {
        margin: [20, 30, 20, 10],
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: 'Prepared:...................................................',
                style: 'headerTable',
                border: [false, false, false, false],
              },
              {
                text: 'Review/approved by:...................................................',
                style: 'headerTable',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '(...................................................)',
                style: 'headerTable',
                margin: [30, 0, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: '(...................................................)',
                style: 'headerTable',
                margin: [80, 0, 0, 0],
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Date:...................................................',
                style: 'headerTable',
                margin: [10, 0, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: 'Date:...................................................',
                style: 'headerTable',
                margin: [60, 0, 0, 0],
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    // footer: function (currentPage, pageCount) {
    //   return []
    // },

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: { font: 'FontAwesome' },
      header: {
        bold: true,
        fontSize: 15,
        margin: [0, 0, 0, 0],
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        margin: [0, -1, 0, -2],
        fontSize: 14,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 13,
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_PD_010
