import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Badge, Select } from '@chakra-ui/react'
// components
import { Button, Link as LinkUI, Box } from '@chakra-ui/react'

import SpinnerLoading from '../../components/Loading/SpinnerLoading'
import _ from 'lodash'
import { arrow } from '@popperjs/core'
import { Chip } from '@mui/material'
import BackButton from 'components/Button/BackButton'

export default function FormList() {
  const { t } = useTranslation()
  const params = useParams()
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me)
  const form = useSelector((state) => state.form)
  const station = useSelector((state) => state.station)

  useEffect(() => {
    dispatch(actions.formAll({ station: params?.id }))
    dispatch(actions.stationGet(params?.id))
    return () => {}
  }, [])

  // if (form?.isLoading && !form.isCompleted) {
  //   return <SpinnerLoading />
  // }

  const renderFormList = () => (
    <div>
      {form?.arr?.length === 0 ? (
        <div className="p-4">
          <div className="text-center">ไม่มีฟอร์มในสถานี</div>
        </div>
      ) : (
        <div className="p-4 w-full">
          {_.map(form?.arr, (eachForm, index) => (
            <Box
              borderRadius="lg"
              borderWidth="1px"
              padding="4"
              className="mb-4"
              key={index}
            >
              {eachForm?.subForm_status ? (
                <Link to={`/humaninput/subformList/${eachForm?._id}`}>
                  <h2 className="text-xl font-bold font-sans">
                    <LinkUI>
                      <i className="fas fa-search text-sm "> </i>{' '}
                      {eachForm?.name}
                    </LinkUI>
                  </h2>
                  <Badge>มีรายการฟอร์มย่อย</Badge>
                  {eachForm?.inputWithSensor?.status && (
                    <Badge background={'red.300'} borderRadius={'lg'}>
                      รับค่าอัตโนมัติ
                    </Badge>
                  )}
                </Link>
              ) : (
                <Link to={`/humaninput/formlist/${eachForm?._id}`}>
                  <h2 className="text-xl font-bold font-sans">
                    <LinkUI>
                      <i className="fas fa-search text-sm "> </i>{' '}
                      {eachForm?.name}{' '}
                      {eachForm?.inputWithSensor?.status && (
                        <Badge background={'red.300'} borderRadius={'lg'}>
                          รับค่าอัตโนมัติ
                        </Badge>
                      )}
                      formlist
                    </LinkUI>
                  </h2>
                </Link>
              )}
              <h5 className="text-base text-gray-700 font-sans">
                {eachForm?.description}
              </h5>
            </Box>
          ))}
        </div>
      )}
    </div>
  )

  return (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              เลือกแบบฟอร์ม
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans ">
              รายการแบบฟอร์มในสถานี {station?.name || ''}
            </h2>
            <BackButton />
          </div>
        </div>
      </div>
      {renderFormList()}
    </div>
  )
}
