import React from 'react'
import _, { each } from 'lodash'
import dayjs from 'dayjs'

const renderHeaderBody = (data, date) => {
  if (data?.length === 0) {
    return {
      text: [
        {
          text: 'วันที่.......................................  ',
          style: 'header2',
        },
        {
          text: 'Shift\t',
          style: 'header2',
        },
        { text: '', style: 'icon' },
        { text: '   A\t', style: 'header2' },
        { text: '', style: 'icon' },
        { text: '   B       ', style: 'header2' },
        {
          text: 'เวลา.......................................',
          style: 'header2',
        },
        {
          text: 'เวลาเก็บตัวอย่าง...............................',
          style: 'header2',
        },
      ],
      alignment: 'center',
      margin: [10, 0, 10, 0],
    }
  } else {
    return {
      text: [
        {
          text: `วันที่ ${dayjs(date).format('D เดือน MMMM พ.ศ. YYYY \t')}`,
          style: 'header2',
        },
        {
          text: 'Shift\t',
          style: 'header2',
        },
        { text: data?.[0].Shift.A ? '' : '', style: 'icon' },
        { text: '   A\t', style: 'header2' },
        { text: data?.[0].Shift.B ? '' : '', style: 'icon' },
        { text: '   B       ', style: 'header2' },
        {
          text: 'เวลา ' + data?.[0].Time + ' น.\t',
          style: 'header2',
        },
        {
          text: 'เวลาเก็บตัวอย่าง ' + data?.[0].TimeEx + ' น.',
          style: 'header2',
        },
      ],
      alignment: 'center',
      margin: [10, 10, 10, 0],
    }
  }
}

export default function RenderQC_007(formInput, form, date) {
  console.log('formInput,007', formInput)
  const colors = '#dddddd'

  ////////////////////Fiber//////////////////////////////////

  const sampleMap = ['Fiber No.1', 'Fiber No.2', 'Fiber No.3']
  const headerBody = renderHeaderBody(formInput.arr, date)
  const header = (colors) => {
    const content = [
      {
        text: 'Sample',
        style: 'headerTable',
        fillColor: colors,
      },
      { text: 'Moisture', style: 'headerTable', fillColor: colors },
      { text: 'Oil/Nos.', style: 'headerTable', fillColor: colors },
      { text: 'Dry/Basis', style: 'headerTable', fillColor: colors },
      { text: 'Oil/Dry', style: 'headerTable', fillColor: colors },
      { text: 'Oil/Basis', style: 'headerTable', fillColor: colors },
      {
        text: 'Remark',
        style: 'headerTable',
        fillColor: colors,
        rowSpan: 2,
        margin: [-3, 10, -3, -10],
      },
    ]
    return content
  }
  const headTable = header(colors)
  const renderDataFiber = (data, sampleMap) => {
    const content = []
    _.map(sampleMap, (Sample, index) => {
      let indexTime = false
      console.log('Data', data)
      if (_.size(data) === 0) {
        return content.push([
          { text: 'Fiber No. ..........', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
        ])
      } else {
        //console.log('Time', time, index, data.length)
        _.map(data, (eachData, indexData) => {
          if (Sample === eachData?.SAMPLE) {
            indexTime = true
            content.push([
              { text: eachData?.SAMPLE || '', style: 'tableContent' },
              { text: eachData?.Moisture || '', style: 'tableContent' },
              { text: eachData?.OilNos || '', style: 'tableContent' },
              { text: eachData?.DryBasis || '', style: 'tableContent' },
              { text: eachData?.OilDry || '', style: 'tableContent' },
              { text: eachData?.OilBasis || '', style: 'tableContent' },
              { text: eachData?.Remark || '', style: 'tableContent' },
            ])
          } else if (indexTime === false && indexData + 1 === data?.length) {
            content.push([
              { text: 'Fiber No. ..........', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
            ])
            return content
          }
        })
      }
    })
    return content
  }
  const dataFiber = _.filter(formInput?.arr, (each) =>
    _.includes(each.SAMPLE, 'Fiber')
  )

  const contentFiber = renderDataFiber(dataFiber, sampleMap)

  /////////////////////////Kernel///////////////////////////////

  const KernelMap = [
    'Step A (Mix)',
    'Kernel Screw No.1',
    'Kernel Screw No.2',
    'Kernel Screw No.3',
  ]

  const renderDataKernel = (data, KernelMap) => {
    const content = []
    _.map(KernelMap, (Sample, index) => {
      let indexTime = false
      console.log('Data', data)
      if (_.size(data) === 0) {
        return content.push([
          { text: Sample, style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
        ])
      } else {
        //console.log('Time', time, index, data.length)
        _.map(data, (eachData, indexData) => {
          if (Sample === eachData?.SAMPLE) {
            indexTime = true
            content.push([
              { text: eachData?.SAMPLE || '', style: 'tableContent' },
              { text: eachData?.Moisture || '', style: 'tableContent' },
              { text: eachData?.OilNos || '', style: 'tableContent' },
              { text: eachData?.DryBasis || '', style: 'tableContent' },
              { text: eachData?.OilDry || '', style: 'tableContent' },
              { text: eachData?.OilBasis || '', style: 'tableContent' },
              { text: eachData?.Remark || '', style: 'tableContent' },
            ])
          } else if (indexTime === false && indexData + 1 === data?.length) {
            content.push([
              { text: Sample, style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
            ])
            return content
          }
        })
      }
    })
    return content
  }

  const dataKernelScrew = _.filter(formInput?.arr, (each) =>
    _.includes(each.SAMPLE, 'Kernel Screw')
  )

  const stepA = _.filter(formInput?.arr, (each) =>
    _.includes(each.SAMPLE, 'Step')
  )

  const dataKernelAndStepA = _.union(dataKernelScrew, stepA)

  const contentKernelScrew = renderDataKernel(dataKernelAndStepA, KernelMap)

  //////////////Decanter//////////////////////////
  const DecenterMap = [
    'Before Decanter No.1',
    'Before Decanter No.2',
    'Sludge Decanter No.1',
    'Sludge Decanter No.2',
  ]
  const renderdataDecanter = (data, DecenterMap) => {
    const content = []
    _.map(DecenterMap, (Sample, index) => {
      let indexTime = false
      console.log('Data', data)
      if (_.size(data) === 0) {
        return content.push([
          { text: Sample, style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
        ])
      } else {
        //console.log('Time', time, index, data.length)
        _.map(data, (eachData, indexData) => {
          if (Sample === eachData?.SAMPLE) {
            indexTime = true
            content.push([
              { text: eachData?.SAMPLE || '', style: 'tableContent' },
              { text: eachData?.Moisture || '', style: 'tableContent' },
              { text: eachData?.OilNos || '', style: 'tableContent' },
              { text: eachData?.DryBasis || '', style: 'tableContent' },
              { text: eachData?.OilDry || '', style: 'tableContent' },
              { text: eachData?.OilBasis || '', style: 'tableContent' },
              { text: eachData?.Remark || '', style: 'tableContent' },
            ])
          } else if (indexTime === false && indexData + 1 === data?.length) {
            content.push([
              { text: Sample, style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
            ])
            return content
          }
        })
      }
    })
    return content
  }
  const dataBeforeDecanter = _.filter(formInput?.arr, (each) =>
    _.includes(each.SAMPLE, 'Before Decanter')
  )
  const dataSludgeDecanter = _.filter(formInput?.arr, (each) =>
    _.includes(each.SAMPLE, 'Sludge Decanter')
  )
  const dataDecanter = _.union(dataBeforeDecanter, dataSludgeDecanter)

  const contentDataDecanter = renderdataDecanter(dataDecanter, DecenterMap)

  //////////////SCD////////////////////////
  const SCDMap = ['SCD No.1', 'SCD No.2']

  const renderdataSCD = (data, SCDMap) => {
    const content = []
    _.map(SCDMap, (Sample, index) => {
      let indexTime = false
      console.log('Data', data)
      if (_.size(data) === 0) {
        return content.push([
          { text: Sample, style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
        ])
      } else {
        //console.log('Time', time, index, data.length)
        _.map(data, (eachData, indexData) => {
          if (Sample === eachData?.SAMPLE) {
            indexTime = true
            content.push([
              { text: eachData?.SAMPLE || '', style: 'tableContent' },
              { text: eachData?.Moisture || '', style: 'tableContent' },
              { text: eachData?.OilNos || '', style: 'tableContent' },
              { text: eachData?.DryBasis || '', style: 'tableContent' },
              { text: eachData?.OilDry || '', style: 'tableContent' },
              { text: eachData?.OilBasis || '', style: 'tableContent' },
              { text: eachData?.Remark || '', style: 'tableContent' },
            ])
          } else if (indexTime === false && indexData + 1 === data?.length) {
            content.push([
              { text: Sample, style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
            ])
            return content
          }
        })
      }
    })
    return content
  }

  const dataSCD = _.filter(formInput?.arr, (each) =>
    _.includes(each.SAMPLE, 'SCD')
  )
  const contentDataSCD = renderdataSCD(dataSCD, SCDMap)

  /////////////////Feed/////////////////////////////////
  const FeedterMap = [
    'Feed',
    'Separator No.1',
    'Separator No.2',
    'Separator No.3',
    'Separator No.4',
  ]
  const renderdataFeed = (data, FeedterMap) => {
    const content = []
    _.map(FeedterMap, (Sample, index) => {
      let indexTime = false
      console.log('Data', data)
      if (_.size(data) === 0) {
        return content.push([
          { text: Sample, style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
        ])
      } else {
        //console.log('Time', time, index, data.length)
        _.map(data, (eachData, indexData) => {
          if (Sample === eachData?.SAMPLE) {
            indexTime = true
            content.push([
              { text: eachData?.SAMPLE || '', style: 'tableContent' },
              { text: eachData?.Moisture || '', style: 'tableContent' },
              { text: eachData?.OilNos || '', style: 'tableContent' },
              { text: eachData?.DryBasis || '', style: 'tableContent' },
              { text: eachData?.OilDry || '', style: 'tableContent' },
              { text: eachData?.OilBasis || '', style: 'tableContent' },
              { text: eachData?.Remark || '', style: 'tableContent' },
            ])
          } else if (indexTime === false && indexData + 1 === data?.length) {
            content.push([
              { text: Sample, style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
            ])
            return content
          }
        })
      }
    })
    return content
  }
  const dataFeed = _.filter(formInput?.arr, (each) =>
    _.includes(each.SAMPLE, 'Feed')
  )

  const dataSeparator = _.filter(formInput?.arr, (each) =>
    _.includes(each.SAMPLE, 'Separator ')
  )

  const dataFeedSeparator = _.union(dataSeparator, dataFeed)

  // const dataFeedNozzle = _.filter(formInput?.arr, {
  //   subForm_name: 'Feed & Nozzle',
  // })
  const contentDataFeed = renderdataFeed(dataFeedSeparator, FeedterMap)

  ////////////////Cooling//////////////////////////////
  const CoolingMap = ['Cooling']
  const renderdataCooling = (data, CoolingMap) => {
    const content = []
    _.map(CoolingMap, (Sample, index) => {
      let indexTime = false
      console.log('Data', data)
      if (_.size(data) === 0) {
        return content.push([
          { text: Sample, style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
          { text: '', style: 'tableContent' },
        ])
      } else {
        //console.log('Time', time, index, data.length)
        _.map(data, (eachData, indexData) => {
          if (Sample === eachData?.SAMPLE) {
            indexTime = true
            content.push([
              { text: eachData?.SAMPLE || '', style: 'tableContent' },
              { text: eachData?.Moisture || '', style: 'tableContent' },
              { text: eachData?.OilNos || '', style: 'tableContent' },
              { text: eachData?.DryBasis || '', style: 'tableContent' },
              { text: eachData?.OilDry || '', style: 'tableContent' },
              { text: eachData?.OilBasis || '', style: 'tableContent' },
              { text: eachData?.Remark || '', style: 'tableContent' },
            ])
          } else if (indexTime === false && indexData + 1 === data?.length) {
            content.push([
              { text: Sample, style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
              { text: '', style: 'tableContent' },
            ])
            return content
          }
        })
      }
    })
    return content
  }
  const dataCooling = _.filter(formInput?.arr, (each) =>
    _.includes(each.SAMPLE, 'Cooling')
  )
  const contentDataCooling = renderdataCooling(dataCooling, CoolingMap)

  return [
    {
      table: {
        widths: ['30%', '45%', '25%'],
        body: [
          [
            {
              image: 'logo_imge',
              rowSpan: 2,
              fit: [35, 35],
              alignment: 'center',
              margin: [0, 2, 0, 0],
              border: [true, true, true, false],
            },
            {
              text: `TTO3: รายงานผลการวิเคราะห์ OIL LOSS\nในกระบวนการผลิต`,
              alignment: 'center',
              bold: true,
              rowSpan: 3,
              fontSize: 20,
              margin: [-50, 5],
            },
            {
              text: `เอกสารหมายเลข : FM-QC-007`,
              style: 'header',
              border: [true, true, true, false],
            },
          ],
          [
            '',
            { text: ``, alignment: 'center' },
            {
              text: `วันที่บังคับใช้ : 15 ต.ค. 2565`,
              style: 'header',
              border: [true, false, true, false],
            },
          ],
          [
            {
              text: `THAI TALLOW AND OIL CO.,LTD.`,
              alignment: 'center',
              bold: true,
              font: 'Impac',
              fontSize: 12,
              border: [true, false, true, true],
            },
            '',
            {
              text: `แก้ไขครั้งที่: 04`,
              style: 'header',
              border: [true, false, true, true],
            },
          ],
        ],
      },
    },
    headerBody,
    {
      margin: [0, 2],
      table: {
        widths: ['20%', '12%', '12%', '12%', '12%', '12%', '20%'],
        body: [
          headTable,
          [
            { text: 'STD', style: 'headerTable', fillColor: colors },
            { text: '40-50', style: 'headerTable', fillColor: colors },
            { text: '<9', style: 'headerTable', fillColor: colors },
            { text: '50-60', style: 'headerTable', fillColor: colors },
            { text: '<7', style: 'headerTable', fillColor: colors },
            { text: '<4.5', style: 'headerTable', fillColor: colors },
            { text: '', style: 'headerTable', fillColor: colors },
          ],
          ...contentFiber,
          [
            {
              text: 'Sample',
              style: 'headerTable',
              fillColor: colors,
            },
            { text: 'Moisture', style: 'headerTable', fillColor: colors },
            { text: 'Oil/Nos.', style: 'headerTable', fillColor: colors },
            { text: 'Dry/Basis', style: 'headerTable', fillColor: colors },
            { text: 'Oil/Dry', style: 'headerTable', fillColor: colors },
            { text: 'Oil/Basis', style: 'headerTable', fillColor: colors },
            {
              text: 'Remark',
              style: 'headerTable',
              fillColor: colors,
              rowSpan: 2,
              margin: [-3, 10, -3, -10],
            },
          ],
          [
            { text: 'STD', style: 'headerTable', fillColor: colors },
            { text: '<3', style: 'headerTable', fillColor: colors },
            { text: '<15', style: 'headerTable', fillColor: colors },
            { text: '>97', style: 'headerTable', fillColor: colors },
            { text: '<13', style: 'headerTable', fillColor: colors },
            { text: '<8', style: 'headerTable', fillColor: colors },
            { text: '', style: 'headerTable', fillColor: colors },
          ],
          ...contentKernelScrew,
          [
            //5
            {
              text: 'Sample',
              style: 'headerTable',
              fillColor: colors,
            },
            { text: 'Moisture', style: 'headerTable', fillColor: colors },
            { text: 'Oil/Nos.', style: 'headerTable', fillColor: colors },
            { text: 'Dry/Basis', style: 'headerTable', fillColor: colors },
            { text: 'Oil/Dry', style: 'headerTable', fillColor: colors },
            { text: 'Oil/Basis', style: 'headerTable', fillColor: colors },
            {
              text: 'Remark',
              style: 'headerTable',
              fillColor: colors,
              rowSpan: 2,
              margin: [-3, 10, -3, -10],
            },
          ],
          [
            { text: 'STD', style: 'headerTable', fillColor: colors },
            { text: '<95', style: 'headerTable', fillColor: colors },
            { text: '<18', style: 'headerTable', fillColor: colors },
            { text: '<5', style: 'headerTable', fillColor: colors },
            { text: '<14', style: 'headerTable', fillColor: colors },
            { text: '<10', style: 'headerTable', fillColor: colors },
            { text: '', style: 'headerTable', fillColor: colors },
          ],
          ...contentDataDecanter,
          [
            //7
            {
              text: 'Sample',
              style: 'headerTable',
              fillColor: colors,
            },
            { text: 'Moisture', style: 'headerTable', fillColor: colors },
            { text: 'Oil/Nos.', style: 'headerTable', fillColor: colors },
            { text: 'Dry/Basis', style: 'headerTable', fillColor: colors },
            { text: 'Oil/Dry', style: 'headerTable', fillColor: colors },
            { text: 'Oil/Basis', style: 'headerTable', fillColor: colors },
            {
              text: 'Remark',
              style: 'headerTable',
              fillColor: colors,
              rowSpan: 2,
              margin: [-3, 10, -3, -10],
            },
          ],
          [
            //8
            { text: 'STD', style: 'headerTable', fillColor: colors },
            { text: '<75', style: 'headerTable', fillColor: colors },
            { text: '<13', style: 'headerTable', fillColor: colors },
            { text: '>25', style: 'headerTable', fillColor: colors },
            { text: '<9', style: 'headerTable', fillColor: colors },
            { text: '<3.5', style: 'headerTable', fillColor: colors },
            { text: '', style: 'headerTable', fillColor: colors },
          ],
          ...contentDataSCD,
          [
            //9
            {
              text: 'Sample',
              style: 'headerTable',
              fillColor: colors,
            },
            { text: 'Moisture', style: 'headerTable', fillColor: colors },
            { text: 'Oil/Nos.', style: 'headerTable', fillColor: colors },
            { text: 'Dry/Basis', style: 'headerTable', fillColor: colors },
            { text: 'Oil/Dry', style: 'headerTable', fillColor: colors },
            { text: 'Oil/Basis', style: 'headerTable', fillColor: colors },
            {
              text: 'Remark',
              style: 'headerTable',
              fillColor: colors,
              rowSpan: 2,
              margin: [-3, 10, -3, -10],
            },
          ],
          [
            //10
            { text: 'STD', style: 'headerTable', fillColor: colors },
            { text: '<95', style: 'headerTable', fillColor: colors },
            { text: '<18', style: 'headerTable', fillColor: colors },
            { text: '>5', style: 'headerTable', fillColor: colors },
            { text: '<14', style: 'headerTable', fillColor: colors },
            { text: '<1', style: 'headerTable', fillColor: colors },
            { text: '', style: 'headerTable', fillColor: colors },
          ],
          ...contentDataFeed,
          [
            //11
            {
              text: 'Sample',
              style: 'headerTable',
              fillColor: colors,
            },
            { text: 'Moisture', style: 'headerTable', fillColor: colors },
            { text: 'Oil/Nos.', style: 'headerTable', fillColor: colors },
            { text: 'Dry/Basis', style: 'headerTable', fillColor: colors },
            { text: 'Oil/Dry', style: 'headerTable', fillColor: colors },
            { text: 'Oil/Basis', style: 'headerTable', fillColor: colors },
            {
              text: 'Remark',
              style: 'headerTable',
              fillColor: colors,
              rowSpan: 2,
              margin: [-3, 10, -3, -10],
            },
          ],
          [
            //12
            { text: 'STD', style: 'headerTable', fillColor: colors },
            { text: '<95', style: 'headerTable', fillColor: colors },
            { text: '<18', style: 'headerTable', fillColor: colors },
            { text: '<5', style: 'headerTable', fillColor: colors },
            { text: '<14', style: 'headerTable', fillColor: colors },
            { text: '<1', style: 'headerTable', fillColor: colors },
            { text: '', style: 'headerTable', fillColor: colors },
          ],
          ...contentDataCooling,
        ],
      },
    },
    {
      margin: [0, 50],
      table: {
        widths: ['33.33%', '33.33%', '33.33%'],
        heights: [20, 20, 20],
        body: [
          [
            {
              text: 'ลงชื่อ...................................',
              style: 'tableContent',
              border: [false, false, false, false],
            },
            {
              text: 'ลงชื่อ...................................',
              style: 'tableContent',
              border: [false, false, false, false],
            },
            {
              text: 'ลงชื่อ...................................',
              style: 'tableContent',
              border: [false, false, false, false],
            },
          ],
          [
            {
              text: '(พนักงานควบคุมคุณภาพ)',
              style: 'tableContent',
              border: [false, false, false, false],
            },
            {
              text: '(ผู้ตรวจสอบ)',
              style: 'tableContent',
              border: [false, false, false, false],
            },
            {
              text: '(หัวหน้างานผลิต)',
              style: 'tableContent',
              border: [false, false, false, false],
            },
          ],
        ],
      },
    },
  ]
}
