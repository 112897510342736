import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import currencyFormatter from 'currency-formatter'
import _, { each } from 'lodash'
import moment from 'moment'

import FooterReportSigleForm from './FooterFile/FooterReportSigleForm'
import FooterReportSigleComment from './FooterFile/FooterReportSigleComment'
import FooterReportWastWater from './FooterFile/FooterReportWastWater'
import FooterReportQC from './FooterFile/FooterReportQC'
import FooterReportBiomass from './FooterFile/FooterReportBiomass'
import FooterReportBlank from './FooterFile/FooterReportBlank'
import HeaderReportType1 from './HeaderFile/HeaderReportType1'
import HeaderReportSteam from './HeaderFile/HeaderReportSteam'
import HeaderReportType2 from './HeaderFile/HeaderReportType2'
import FooterReportType2 from './FooterFile/FooterReportType2'
import { auto } from '@popperjs/core'
import dayjs from 'dayjs'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
}

const checkType = (data) => {
  if (typeof data === 'number') {
    return data.toFixed(2)
  }

  return data
}

const genContent = (eachFormInput, form) => {
  const content = []
  _.map(form?.components, (dataForm) =>
    dataForm?.attribute?.length === 0 || dataForm?.formType === 'SELECTOR'
      ? content.push({
          text: ` ${checkType(eachFormInput[dataForm?.name]) || '-'}`,
          alignment: 'center',
        })
      : _.map(dataForm?.attribute, (eachData) =>
          content.push({
            text: ` ${
              checkType(
                eachFormInput?.[dataForm?.name]?.[eachData?.subTopic]
              ) || '-'
            }`,
            alignment: 'center',
          })
        )
  )
  return content
}

const genbodyTable = (form, formInput) =>
  _.map(formInput?.arr, (eachFormInput) => genContent(eachFormInput, form))

const genHeader = (data) => {
  const headerTable = []
  _.map(data?.components, (row, index) =>
    row?.attribute?.length === 0 || row?.formType === 'SELECTOR'
      ? headerTable.push({
          text: `${row?.topic}`,
          alignment: 'center',
          bold: true,
          margin: [0, 12],
          colSpan: 1,
          rowSpan: 2,
        })
      : _.map(row?.attribute, (each) =>
          headerTable.push({
            text: `${row?.topic}`,
            alignment: 'center',
            bold: true,
            colSpan:
              row?.attribute?.length !== 0 || row?.formType !== 'SELECTOR'
                ? row?.attribute?.length
                : 1,
          })
        )
  )
  return headerTable
}
const genSubHeader = (data) => {
  const headerTable = []
  _.map(data?.components, (row, index) =>
    row?.attribute?.length === 0 || row?.formType === 'SELECTOR'
      ? headerTable.push({ text: '', border: [false, false, false, false] })
      : _.map(row?.attribute, (each) =>
          headerTable.push({
            text: `${each?.subTopic}`,
            alignment: 'center',
            bold: true,
            colSpan: 1,
          })
        )
  )
  return headerTable
}

const ColWidthTable = (form, countAttribute, countComponentAttribute) => {
  const widths = []
  _.map(form?.components, (row) =>
    row?.attribute?.length === 0 || row?.formType === 'SELECTOR'
      ? widths.push(
          `${(
            100 /
              (form?.components?.length +
                countAttribute -
                countComponentAttribute) || 1
          ).toFixed(2)}%`
          // '*'
        )
      : _.map(row?.attribute, (each) =>
          widths.push(
            `${(
              100 /
                (form?.components?.length +
                  countAttribute -
                  countComponentAttribute) || 1
            ).toFixed(2)}%`
            // 'auto'
          )
        )
  )
  return widths
}

const ReportForm = async (form, formInput, system, date) => {
  const countAttribute = _.sumBy(form?.components, (row) =>
    row?.formType === 'SELECTOR' ? 0 : row?.attribute?.length
  )
  const countComponentAttribute = _.countBy(form?.components, (row) =>
    row?.formType === 'SELECTOR' ? 0 : row?.attribute?.length !== 0
  )

  // console.log('countComponentAttribute', countComponentAttribute?.true || 0)

  const widthFeild = ColWidthTable(
    form,
    countAttribute,
    countComponentAttribute?.true || 0
  )
  const headTable = genHeader(form)
  const subHeader = genSubHeader(form)
  const meterialData = genbodyTable(form, formInput)

  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: form?.pdfSetting?.orientations || 'portrait',
    pageMargins: [10, 90, 10, 190],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      form?.pdfSetting?.header === 'type_1'
        ? HeaderReportType1(form, date)
        : '',
      form?.pdfSetting?.header === 'type_2'
        ? HeaderReportType2(form, date)
        : '',
      form?.pdfSetting?.header === 'type_3'
        ? HeaderReportSteam(form, date)
        : '',
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        table: {
          headerRows: 2,
          widths: widthFeild,
          body: [headTable, subHeader, ...meterialData],
        },
      },
    ],
    footer: function (currentPage, pageCount) {
      return [
        form?.pdfSetting?.footer === 'type_1' ? FooterReportSigleForm() : '',
        form?.pdfSetting?.footer === 'type_2' ? FooterReportSigleComment() : '',
        form?.pdfSetting?.footer === 'type_3' ? FooterReportType2() : '',
        form?.pdfSetting?.footer === 'type_4' ? FooterReportWastWater() : '',
        form?.pdfSetting?.footer === 'type_5' ? FooterReportQC() : '',
        form?.pdfSetting?.footer === 'type_6' ? FooterReportBlank() : '',
        form?.pdfSetting?.footer === 'type_7' ? FooterReportBiomass() : '',
        {},
      ]
    },

    images: {
      logo_imge: system?.logo,
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default ReportForm
