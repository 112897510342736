import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// components
import { Button, Link as LinkUI, Box } from '@chakra-ui/react'

import SpinnerLoading from '../../components/Loading/SpinnerLoading'
import _ from 'lodash'

export default function HumanInput() {
  const { t } = useTranslation()
  const me = useSelector((state) => state.me)

  // ตรวจสอบว่า User ที่เข้ามามี Permission ที่เข้าสถานีไหนได้บ้าง
  // ตอนนี้ยังเก็บเป็น Text อยู่ เก็บเฉพาะใน User ไม่ได้เก็บในสถานี
  // ถ้าอนุญาติหมดจะเป็น ALLAREA
  // TODO: ถ้าเป็น Array จะดีมาก

  if (me?.role === 'GENERAL') {
    return (
      <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                แบบฟอร์ม
              </h6>
              <h2 className="text-gray-800 text-2xl font-bold font-sans">
                แบบฟอร์ม
              </h2>
            </div>
          </div>
        </div>
        <div className="my-4  px-4">
          <Link to={'/humaninput/stationlist'}>
            <div className="bg-blue-800 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
              <i className="fas fa-th-list text-4xl text-white "></i>
              <p className="text-white mt-4 ">กรอกข้อมูล</p>
            </div>
          </Link>
        </div>
        <div className="my-4  px-4">
          <Link to={'/humaninput/history'}>
            <div className="bg-yellow-800 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
              <i className="fas fa-th-list text-4xl text-white "></i>
              <p className="text-white mt-4 ">ประุวัติการกรอกข้อมูล</p>
            </div>
          </Link>
        </div>
      </div>
    )
  } else {
    return (
      <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                แบบฟอร์ม
              </h6>
              <h2 className="text-gray-800 text-2xl font-bold font-sans">
                แบบฟอร์ม
              </h2>
            </div>
          </div>
        </div>
        <div className="lg:grid grid-cols-2 gap-1 ">
          <div className="my-4  px-4 l ">
            <Link to={'/humaninput/stationlist'}>
              <div className="bg-blue-800 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                <i className="fas fa-th-list text-4xl text-white "></i>
                <p className="text-white mt-4 ">กรอกข้อมูล</p>
              </div>
            </Link>
          </div>
          <div className="my-4  px-4  ">
            <Link to={'/humaninput/report/stationlist'}>
              <div className="bg-green-800 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                <i className="fas fa-poll text-4xl text-white "></i>
                <p className="text-white mt-4 ">รายงาน</p>
              </div>
            </Link>
          </div>
          <div className="my-4  px-4">
            <Link to={'/humaninput/history'}>
              <div className="bg-yellow-800 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                <i className="fas fa-history text-4xl text-white "></i>
                <p className="text-white mt-4 ">ประวัติการกรอกข้อมูล</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
