import React from 'react'
import PropTypes from 'prop-types'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/th'
import dayjs from 'dayjs'
dayjs.locale('th')
dayjs.extend(buddhistEra)
dayjs.extend(LocalizedFormat)

const HeaderReportType1 = (form, date) => ({
  margin: [10, 10],
  style: 'tableExample',
  table: {
    widths: ['23%', '54%', '23%'],
    body: [
      [
        {
          image: 'logo_imge',
          rowSpan: 3,
          fit: [70, 70],
          alignment: 'center',
          margin: [0, 2, 0, 0],
        },
        {
          text: `${form?.name || ''}`,
          alignment: 'center',
          bold: true,
          rowSpan: 2,
          fontSize: '18',
          margin: [0, 10],
          border: [true, true, true, false],
        },
        `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
      ],
      [
        '',
        { text: ``, alignment: 'center' },
        `วันที่บังคับใช้ ${
          dayjs(form?.enforceDate).format('DD/MM/BBBB') || '-'
        }`,
      ],
      [
        '',
        {
          text: `วันที่ ${dayjs(date).format('DD เดือน MMMM พ.ศ. BBBB ')}`,
          alignment: 'center',
          border: [true, false, true, true],
        },
        `แก้ไขครั้งที่: ${form?.editVersion || '-'}`,
      ],
    ],
  },
})

HeaderReportType1.propTypes = {
  form: PropTypes.object,
}

export default HeaderReportType1
