import React, { useEffect, useState } from 'react'
import {
  Badge,
  Box,
  Button,
  FormControl,
  Input,
  Select,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import _, { eq } from 'lodash'
import { useTranslation } from 'react-i18next'
import { InlineMath, BlockMath } from 'react-katex'
import 'katex/dist/katex.min.css'
import Latex from 'react-latex'

import * as actions from '../../../redux/actions'
import SubVariableAddingComponent from '../SubVariables/SubVariableAddingComponent'
import OperationAddingComponent from '../Operations/OperationListAddingComponent'
import SubvariableShowing from '../SubVariables/SubvariableShowing'
import OperationListShowing from '../Operations/OperationListShowing'
import VariableMainRendering from '../Function/VariableMainRendering'
import { DataType } from '../../../config/DataType'
import Gauge from '../../Gagues/common'

export default function VariableMakingComponent({
  index,
  stationData,
  variableData,
}) {
  const { t } = useTranslation()
  const [isEditorMode, setIsEditorMode] = useState(false)
  const { register, handleSubmit, watch } = useForm()
  const [rendered, setRendered] = useState(false)
  const [allSubVariables, setAllSubVariables] = useState(
    variableData.multiLevelVariables
  )
  const [operationList, setOperationList] = useState(
    variableData.multiLevelOperations
  )

  const [selectedDataType, setSelectedDataType] = useState(
    variableData.wantedDataType
  )
  const dispatch = useDispatch()
  const [showEquation, setShowEquation] = useState(variableData.latex)
  const [payload, setPayload] = useState({})
  const [isInputData, setIsInputData] = useState('')
  const [isVariableData, setIsVariableData] = useState('')
  useEffect(() => {
    const eq = _.filter(stationData.equations, (member) => {
      return member.latex === showEquation
    })
    console.log('Show ', eq)
    if (_.size(eq) !== 0) {
      setPayload(...eq)
      let tempSym = eq[0].symbol
      console.log('ERROR: ', tempSym)
      setIsVariableData(tempSym)
    }
  }, [showEquation])

  const getValue = (inputData) => {
    if (inputData === 'SENSOR') {
      return _.map(stationData.sensors, (sensor, key) => {
        return (
          <option key={index} value={sensor.refString}>
            {sensor.name}
          </option>
        )
      })
    }
    if (inputData === 'VARIABLE') {
      return _.map(stationData.variables, (variable) => {
        return <option key={index}>{variable.name}</option>
      })
    }
    // // if(isInputData === "HUMANINPUT"){
    // //   inputValue = _.map(stationData.variable, (variable) => {return variable.name})
    // // }
  }

  // Merged the Sensor Data and Make new Station Data to Database
  const prepareUpdateDatabase = (data) => {
    console.log('Onsubmit Data: ', data)
    let prepareValue = {}
    _.map(data.value, (e) => {
      _.merge(prepareValue, e)
    })
    console.log('Prepare value', prepareValue)
    data.value = prepareValue
    data.valueDetail = prepareValue
    const selectedVariable = stationData.variables[index]
    // console.log('Variable Data: ', data)
    data = _.merge(data, payload)
    const selectedVariableIndex = index
    const mergedvariableData = _.merge(selectedVariable, data)
    console.log('Payload : ', mergedvariableData)
    let editedStation = stationData
    editedStation.variables[selectedVariableIndex] = mergedvariableData
    dispatch(actions.stationPut(stationData._id, editedStation)).then(() => {
      dispatch(actions.stationAll())
    })
    setIsEditorMode(false)
  }

  const prepareDeleteVariable = () => {
    let confirm = window.confirm('ยืนยันการลบตัวแปร')
    if (confirm) {
      const selectedSensorIndex = index
      let editedStation = stationData
      if (editedStation.variables) {
        editedStation.variables.splice(selectedSensorIndex, 1)
      }
      dispatch(actions.stationPut(stationData._id, editedStation)).then(() => {
        dispatch(actions.stationAll())
      })
      setIsEditorMode(false)
    }
  }

  const deleteSubvariable = (index) => {
    let tempSubvariableList = allSubVariables
    tempSubvariableList.splice(index, 1)
    setAllSubVariables(tempSubvariableList)
    setRendered(!rendered)
  }

  const deleteOperation = (index) => {
    let tempOperationList = operationList
    tempOperationList.splice(index, 1)
    setOperationList(tempOperationList)
    setRendered(!rendered)
  }

  const showIndex = () => {
    console.log('INDEX: ', variableData)
  }

  return (
    <Box borderRadius="lg" padding="4" className="bg-white my-2">
      <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
        <div className="flex gap-3">
          <div className="w-5/6">
            <h5 className="text-lg font-bold font-sans">
              {variableData.variableName}{' '}
              <Badge colorScheme="teal" size="sm">
                {variableData.wantedDataType}{' '}
              </Badge>{' '}
            </h5>
          </div>

          <div className="flex gap-1 ">
            {isEditorMode === true ? (
              <>
                <Button
                  size="sm"
                  type="button"
                  colorScheme="gray"
                  onClick={() => setIsEditorMode(false)}
                >
                  {t('utility.cancel')}
                </Button>{' '}
                <Button size="sm" colorScheme="green" type="submit">
                  {t('utility.save')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="sm"
                  colorScheme="yellow"
                  onClick={
                    () => setIsEditorMode(true)
                    // setShowEquation(variableData.latex)
                    // setIsVariableData(variableData.symbol),
                    // showIndex()
                  }
                  type="button"
                >
                  {t('utility.edit')}
                </Button>
                <Button
                  size="sm"
                  colorScheme="red"
                  type="button"
                  onClick={() => prepareDeleteVariable()}
                >
                  {t('utility.delete')}
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="w-full">
          {isEditorMode === true ? (
            <>
              <FormControl>
                <label className="text-sm font-bold">
                  {' '}
                  {t('variable.name')}
                </label>
                <Input
                  type="text"
                  name="variableName"
                  size="sm"
                  key={index}
                  defaultValue={variableData.variableName}
                  ref={register}
                />
              </FormControl>
              <FormControl>
                <label className="text-sm font-bold">
                  {' '}
                  {t('variable.type')}
                </label>
                <Select
                  defaultValue={variableData.wantedDataType}
                  size="sm"
                  key={index}
                  ref={register}
                  name="wantedDataType"
                  onChange={(event) => setSelectedDataType(event.target.value)}
                >
                  <option value="">{t('variable.typeSelect')}</option>
                  <option value={DataType.boolean}>Boolean</option>
                  <option value={DataType.number}>Number</option>
                </Select>
              </FormControl>
              <FormControl>
                <label className="text-sm font-bold"> {'เลือกสมการ'}</label>
                <Select
                  // defaultValue={}
                  defaultValue={variableData.latex}
                  key={index}
                  size="sm"
                  ref={register}
                  name="latex"
                  placeholder="เลือกสมการที่ต้องการใช้"
                  onChange={(event) => setShowEquation(event.target.value)}
                >
                  {_.map(stationData.equations, (equation, index) => {
                    return (
                      <option key={index} value={equation.latex}>
                        {equation.name}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
              <div className="flex justify-center mx-2 my-2 text-lg font-bold mb-2 bg-gray-100 shadow-md rounded-md">
                {showEquation !== undefined ? (
                  <Latex displayMode={true}>
                    {'$$ f(x) =' + showEquation + '$$'}
                  </Latex>
                ) : (
                  <Latex displayMode={true}>{'$$ f(x) =' + '$$'}</Latex>
                )}
              </div>
              <br />
              {/* ส่วนของการกำหนดตัวแปร */}
              <div className="">
                <div className="w-full">
                  <label className="text-sm font-bold py-2">
                    {t('variable.subVariableList')}{' '}
                  </label>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>{t('variable.subName')}</Th>
                        <Th>{t('sensor.dataSource')}</Th>
                        <Th>{t('variable.value')}</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {_.map(isVariableData, (symbol, index) => {
                        return (
                          <Tr className="text-sm" key={index}>
                            <Td>
                              {' '}
                              <Latex>{'$$' + symbol + ' : $$'}</Latex>{' '}
                            </Td>
                            <Td>
                              <Select
                                defaultValue={variableData.inputData[index]}
                                key={index}
                                size="sm"
                                ref={register}
                                name={`inputData[${index}]`}
                                // onChange={(event) =>
                                //   setIsInputData(event.target.value)
                                // }
                                placeholder={'เลือกที่มาของข้อมูล'}
                              >
                                <option value={'SENSOR'}>เซนเซอร์</option>
                                <option value={'VARIABLE'}>ตัวแปร</option>
                                <option value={'HUMANINPUT'}>
                                  การป้อนข้อมูล
                                </option>
                                <option value={'CONST'}>ค่าคงที่</option>
                              </Select>
                            </Td>
                            <Td>
                              {watch(`inputData[${index}]`) === 'CONST' ||
                              variableData.inputData[index] === 'CONST' ? (
                                <Input
                                  defaultValue={
                                    variableData.value !== undefined
                                      ? Object.values(variableData.value)[index]
                                      : '0'
                                  }
                                  ref={register}
                                  key={index}
                                  name={`value[${index}.${symbol}]`}
                                  type="number"
                                />
                              ) : (
                                <Select
                                  // defaultValue={variableData.latex}
                                  key={index}
                                  size="sm"
                                  ref={register}
                                  name={`value[${index}.${symbol}]`}
                                  // onChange={(event) =>
                                  //   setIsInputData(event.target.value)
                                  // }
                                >
                                  {getValue(watch(`inputData[${index}]`))}
                                </Select>
                              )}
                            </Td>
                          </Tr>
                        )
                      })}
                    </Tbody>
                  </Table>
                </div>
                <br />
              </div>
            </>
          ) : (
            <div>
              <div className="flex flex-wrap ">
                <div className="lg:w-1/3 font-sans">
                  {variableData.wantedDataType === DataType.boolean && (
                    <Gauge.StaticGauge.StaticBoolean
                      staticData={VariableMainRendering(
                        stationData,
                        variableData.id
                      )}
                      graphLabel={variableData.variableName}
                    />
                  )}
                  {console.log('variableData.numericStatus', variableData)}

                  {variableData.wantedDataType === DataType.number && (
                    <Gauge.StaticGauge.StaticNumber
                      staticData={variableData.numericStatus}
                      graphLabel={variableData.variableName}
                    />
                  )}
                </div>
                <div className="lg:w-2/3">
                  <ul className="list-inside list-disc ml-6">
                    <li>
                      <span className="text-sm font-semibold text-gray-700">
                        {t('sensor.subSensorType')} :{' '}
                      </span>
                      <span className="text-sm font-semibold text-blue-700">
                        {variableData.wantedDataType}{' '}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </Box>
  )
}
