import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import IndexNavbar from 'components/common/Navbars/IndexNavbar.js'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// components
import { Box, Button, Link as LinkUI, Select } from '@chakra-ui/react'

import SpinnerLoading from 'components/Loading/SpinnerLoading'
import _ from 'lodash'
import DashboardMonitorComponent from './Components/DashboardMonitorComponent'
import DashboardMonitorComponentSlide from './Components/DashboardMonitorComponent2'

export default function Dashboard({ sidebarStatus, setIsShowSidebar }) {
  const [isLoading, setIsLoading] = useState(false)
  const [selectType, setSelectType] = useState('slide01')
  const [fetchingNewOne, setFetchingNewOne] = useState(true)
  const [allowedDashboard, setAllowedDashboard] = useState()
  const stations = useSelector((state) => state.station)
  const me = useSelector((state) => state.me)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (fetchingNewOne) {
      dispatch(actions.stationAll())
      setIsLoading(true)
      setFetchingNewOne(!fetchingNewOne)
    }
    return () => {}
  }, [fetchingNewOne])

  // ตรวจสอบว่า User ที่เข้ามามี Permission ที่เข้าสถานีไหนได้บ้าง
  // ตอนนี้ยังเก็บเป็น Text อยู่ เก็บเฉพาะใน User ไม่ได้เก็บในสถานี
  // ถ้าอนุญาติหมดจะเป็น ALLAREA
  // TODO: ถ้าเป็น Array จะดีมาก
  useEffect(() => {
    if (me && me._id) {
      if (_.size(stations.arr)) {
        if (_.includes(me.duty, 'ALLAREA')) {
          let allowedStationStack = stations.arr
          setAllowedDashboard(allowedStationStack)
        } else {
          let allowedStation = _.filter(
            stations.arr,
            (station, index) => station._id === me.duty[index]
          )
          setAllowedDashboard(allowedStation)
        }
      }
    }
    return () => {}
  }, [stations, me])

  // รีเฟรชทุกๆ 5 วินาที
  useEffect(() => {
    if (fetchingNewOne === false)
      setTimeout(() => {
        setFetchingNewOne(!fetchingNewOne)
      }, 5000)
    return () => {}
  }, [fetchingNewOne])

  const settingsSlide = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 4000,
    cssEase: 'linear',
  }

  const handleSelectType = (e) => {
    setSelectType(e.target.value)
  }
  console.log('allowedDashboard in main', allowedDashboard)

  const findProduction = _.find(
    allowedDashboard,
    (e) => e?.externalRef === 'PRODUCTION'
  )
  console.log('findProduction : ', _.chunk(findProduction?.sensors, 20))
  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              {t('dashboard.dashboardMonitor')}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              {t('dashboard.dashboard')}
            </h2>
          </div>
        </div>
      </div>
      {allowedDashboard && _.size(allowedDashboard) ? (
        <>
          <div className="flex justify-between p-4">
            <div className="flex justify-items-start">
              <Select
                placeholder="รูปแบบการโชว์"
                type="text"
                name="electricType"
                defaultValue={selectType}
                onChange={handleSelectType}
              >
                <option value="slide01">เลื่อนโชว์</option>
                <option value="slide02">เลือกแผนก</option>
              </Select>
            </div>
            <div className="flex justify-items-end">
              <Button
                colorScheme="gray"
                variant="solid"
                size="sm"
                onClick={() => setIsShowSidebar(!sidebarStatus)}
              >
                {t('dashboard.toggleSidebar')}
              </Button>
            </div>
          </div>{' '}
          {selectType === 'slide01' ? (
            <div className="w-full">
              <div className="p-4 w-full ">
                <Slider {...settingsSlide}>
                  {_.map(allowedDashboard, (station, index) => {
                    if (station?.externalRef === 'PRODUCTION') {
                      return _.map(
                        _.chunk(
                          _.orderBy(station?.gauges, 'orderLevel', 'asc'),
                          20
                        ),
                        (e) => (
                          <Box
                            borderRadius="lg"
                            borderWidth="1px"
                            padding="4"
                            className="mb-6 shadow-sm  "
                            key={index}
                          >
                            <Link to={'/dashboards/board/' + station._id}>
                              <h2 className="text-xl font-bold  ">
                                {' '}
                                <LinkUI className="font-sans font-bold">
                                  {station.name}{' '}
                                </LinkUI>{' '}
                              </h2>{' '}
                            </Link>
                            <h5 className="text-base text-gray-700 font-sans">
                              {' '}
                              {station.description} | {station.location}{' '}
                            </h5>

                            {/**ในหน้านี้ ถ้าเป็น Mobile จะให้แสดงเฉพาะลิสต์ของสถานี ไม่โชว์แดชบอร์ดเต็มๆ */}

                            <div className="font-sans hidden md:block">
                              <DashboardMonitorComponentSlide
                                currentStation={e}
                                station={station}
                              />
                            </div>
                          </Box>
                        )
                      )
                    } else {
                      return (
                        <Box
                          borderRadius="lg"
                          borderWidth="1px"
                          padding="4"
                          className="mb-6 shadow-sm "
                          key={index}
                        >
                          <Link to={'/dashboards/board/' + station._id}>
                            <h2 className="text-xl font-bold ">
                              {' '}
                              <LinkUI className="font-sans font-bold">
                                {station.name}{' '}
                              </LinkUI>{' '}
                            </h2>{' '}
                          </Link>
                          <h5 className="text-base text-gray-700 font-sans">
                            {' '}
                            {station.description} | {station.location}{' '}
                          </h5>

                          {/**ในหน้านี้ ถ้าเป็น Mobile จะให้แสดงเฉพาะลิสต์ของสถานี ไม่โชว์แดชบอร์ดเต็มๆ */}
                          <div className="font-sans hidden md:block">
                            <DashboardMonitorComponent
                              currentStation={station}
                            />
                          </div>
                        </Box>
                      )
                    }
                  })}
                </Slider>
              </div>
            </div>
          ) : (
            <div className="p-4 w-full">
              {_.map(allowedDashboard, (station, index) => (
                <Box
                  borderRadius="lg"
                  borderWidth="1px"
                  padding="4"
                  className="mb-6 shadow-sm "
                  key={index}
                >
                  <Link to={'/dashboards/board/' + station._id}>
                    <h2 className="text-xl font-bold ">
                      {' '}
                      <LinkUI className="font-sans font-bold">
                        {station.name}{' '}
                      </LinkUI>{' '}
                    </h2>{' '}
                  </Link>
                  <h5 className="text-base text-gray-700 font-sans">
                    {' '}
                    {station.description} | {station.location}{' '}
                  </h5>

                  {/**ในหน้านี้ ถ้าเป็น Mobile จะให้แสดงเฉพาะลิสต์ของสถานี ไม่โชว์แดชบอร์ดเต็มๆ */}
                  <div className="font-sans hidden md:block">
                    <DashboardMonitorComponent currentStation={station} />
                  </div>
                </Box>
              ))}
            </div>
          )}
        </>
      ) : (
        <div className="p-4 w-full">{t('dataAndStat.empty')}</div>
      )}
    </div>
  ) : (
    <SpinnerLoading />
  )
}
