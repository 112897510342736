import React from 'react'
import { useForm } from 'react-hook-form'
import GeneralFormSelector from './GeneralFormSelector'
import _ from 'lodash'
import { Box, Button } from '@mui/material'
import { log10, typeOf } from 'mathjs'
import dayjs from 'dayjs'
import MathCalculateFormComponent from './MathCalculateFormComponent'

export default function GeneratEditForm({ formInput, handleEdit }) {
  const { register, control, setValue, watch, handleSubmit } = useForm({
    defaultValues: formInput,
  })

  const renderForm = (data, dataKey) => {
    if (
      dataKey !== '_id' &&
      dataKey !== 'recorder' &&
      dataKey !== 'createdAt' &&
      dataKey !== 'isLoading' &&
      dataKey !== 'isCompleted' &&
      dataKey !== 'subForm_name' &&
      dataKey !== 'subForm_id' &&
      dataKey !== 'date' &&
      dataKey !== 'name'
    ) {
      if (typeof data === 'object') {
        return (
          <Box
            maxW="sm"
            borderWidth="1px"
            borderRadius="lg"
            className=" ml-4 my-2 p-4 w-auto h-36 "
          >
            <label className="block uppercase text-gray-700 text-md font-bold mb-2">
              {dataKey}
            </label>

            {_.map(data, (each, key) => (
              <div>
                {console.log(dataKey?.key)}
                <label className="block uppercase text-gray-500 text-sm  mb-2">
                  {key}
                </label>
                <input
                  name={`${dataKey}.${key}`}
                  type="object"
                  defaultValue={each}
                  ref={register}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-md shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
                />
              </div>
            ))}
          </Box>
        )
      }
      if (typeof data === 'number') {
        return (
          <Box
            maxW="sm"
            borderWidth="1px"
            borderRadius="lg"
            className=" ml-4 my-2 p-4 w-auto h-32"
          >
            <div className="flex justify-between ">
              <label className="block uppercase text-gray-700 text-md font-bold mb-2">
                {dataKey}
              </label>
            </div>
            <input
              name={dataKey}
              type="text"
              ref={register}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-md shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            />
          </Box>
        )
      }
      if (typeof data === 'string') {
        if (data === dayjs(data).format('YYYY-MM-DD')) {
          return (
            <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              className=" ml-4 my-2 p-4 w-auto  h-36"
            >
              <label className="block uppercase text-gray-700 text-md font-bold mb-2">
                {dataKey}
              </label>
              <label className="block uppercase text-gray-500 text-sm  mb-2"></label>
              <input
                name={dataKey}
                type="date"
                defaultValue={dayjs(data).format('D-MM-BBBB')}
                ref={register}
                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-md shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
              />
            </Box>
          )
        }
        return (
          <Box
            maxW="sm"
            borderWidth="1px"
            borderRadius="lg"
            className=" ml-4 my-2 p-4 w-auto h-32"
          >
            <div className="flex justify-between ">
              <label className="block uppercase text-gray-700 text-md font-bold mb-2">
                {dataKey}
              </label>
            </div>
            <input
              name={dataKey}
              type="text"
              ref={register}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-md shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            />
          </Box>
        )
      }
    }
    // console.log(typeof data)
  }

  return (
    <div>
      {/* {JSON.stringify(watch())} */}
      <form onSubmit={handleSubmit(handleEdit)}>
        <div className="md:grid lg:grid-cols-4 md:grid-cols-2">
          {console.log('f', formInput)}
          {_.map(formInput, (each, key) => renderForm(each, key))}
        </div>
        <div className="flex justify-center py-2 ">
          <Button type="submit" variant="contained">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  )
}
