import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderCheckContRows = (data) => {
  const content = _.map(data, (row) => [
    {
      text: dayjs(row?.DateAfter).format('D/MM/BBBB') || '',
      style: 'tableContent',
    },
    { text: row?.TimeStart || '', style: 'tableContent' },
    { text: row?.NameTTL || '', style: 'tableContent' },
    { text: row?.NamePEA || '', style: 'tableContent' },
    { text: row?.SystemTime || '', style: 'tableContent' },
    {
      text: dayjs(row?.DateAfter).format('D/MM/BBBB') || '',
      style: 'tableContent',
    },
    { text: row?.UnlockTime || '', style: 'tableContent' },
    { text: row?.NameUser || '', style: 'tableContent' },
    { text: row?.comment || '', style: 'tableContent' },
  ])
  return content
}

const renderTableBody = (data) => {
  const DataSet = renderCheckContRows(data)
  console.log(DataSet)

  if (DataSet?.length !== 32) {
    for (let i = DataSet?.length; i < 32; i++) {
      DataSet.push([
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
      ])
    }
  }
  return DataSet
}

const FM_BG_005 = async (form, formInput, subForm, system, date) => {
  console.log('system', system)

  const TableBody = renderTableBody(formInput.arr)

  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [10, 69, 10, 10],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      {
        margin: [10, 8, 10, 0],
        style: 'tableExample',
        table: {
          widths: ['17.8%', '51.85%', '30.35%'],
          body: [
            [
              {
                image: 'logo_imge',
                rowSpan: 2,
                fit: [27, 27],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: `${form?.name || ''}`,
                alignment: 'center',
                bold: true,
                rowSpan: 2,
                font: 'AngsanaNew',
                fontSize: '18',
                margin: [0, 5],
                border: [true, true, true, false],
              },
              {
                text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
                style: 'headerTable',
                margin: [0, 2, 0, 0],
              },
            ],
            [
              { text: '', alignment: 'center' },
              { text: '', alignment: 'center' },
              {
                text: 'แก้ไขครั้งที่: 00' + ' ' + ' ' + ' ' + 'หน้าที่: 1/1',
                style: 'headerTable',
                margin: [0, 2, 0, -2],
              },
            ],
            [
              {
                text: 'THAITALLOW AND OIL CO.,LTD.',
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: '8',
                border: [true, false, true, true],
              },
              {
                text: `${dayjs(date).format('เดือน MMMM')}`,
                alignment: 'center',
                bold: true,
                fontSize: '14',
                border: [true, false, true, true],
              },

              {
                text: `วันที่บังคับใช้: ${
                  dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                }`,
                style: 'headerTable',
                margin: [0, 2, 0, -2],
              },
            ],
          ],
        },
      },
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        table: {
          widths: ['9%', '7%', '15%', '15%', '15%', '9%', '12%', '7%', '11%'],
          body: [
            [
              // Row 1
              {
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: 'ชื่อผู้แจ้ง',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: 'ชื่อผู้รับแจ้ง',
                alignment: 'center',
                border: [true, true, true, false],
                bold: true,
              },
              {
                text: 'เชื่อมโยงระบบ',
                alignment: 'center',
                border: [true, true, true, false],
                bold: true,
              },
              {
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },

              {
                text: 'ปลดการเชื่อมโยง',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: 'ลงชื่อ',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
            ],
            [
              //Row 2
              {
                text: 'ว/ด/ป',
                style: 'headerTable',
                bold: true,
                border: [true, false, true, false],
              },
              {
                text: 'เวลา',
                style: 'headerTable',
                bold: true,
                border: [true, false, true, false],
              },
              {
                text: 'บ.ไทยทาโลว์',
                style: 'headerTable',
                bold: true,
                border: [true, false, true, false],
              },
              {
                text: 'การไฟฟ้าเขต 2',
                style: 'headerTable',
                bold: true,
                border: [true, false, true, false],
              },
              {
                text: 'เวลา',
                style: 'headerTable',
                rowSpan: 2,
                border: [true, false, true, true],
              },
              {
                text: 'ว/ด/ป',
                style: 'headerTable',
                bold: true,
                border: [true, false, true, false],
              },
              {
                text: 'เวลา',
                style: 'headerTable',
                rowSpan: 2,
                border: [true, false, true, true],
              },
              {
                text: 'ผู้บันทึก',
                style: 'headerTable',
                rowSpan: 2,
                border: [true, false, true, true],
              },
              {
                text: 'หมายเหตุ',
                style: 'headerTable',
                bold: true,
                border: [true, false, true, false],
              },
            ],
            [
              //Row 3
              {
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, false, true, true],
              },
              {
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, false, true, true],
              },
              {
                text: 'แอนด์ ออยล์ จำกัด',
                alignment: 'center',
                bold: true,
                border: [true, false, true, true],
              },
              {
                text: 'นครศรีฯ',
                alignment: 'center',
                bold: true,
                border: [true, false, true, true],
              },
              {},
              {
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, false, true, true],
              },
              {},
              {},
              {
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, false, true, true],
              },
            ],
            ...TableBody,
          ],
        },
      },
      {
        alignment: 'between',
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: ' ',
                alignment: 'left',

                border: [true, true, false, false],
              },
              {
                text: '\nลงชื่อ....................................................',

                alignment: 'center',
                fontSize: 14,
                border: [false, true, true, false],
              },
            ],
            [
              {
                text: ' ',
                alignment: 'center',
                border: [true, false, false, false],
              },
              {
                text: 'หัวหน้าแผนกก๊าซชีวภาพ',

                alignment: 'center',
                fontSize: 14,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: '',
                alignment: 'left',

                border: [true, false, false, false],
              },
              {
                text: 'วันที่........./.................../..........',

                alignment: 'center',
                fontSize: 14,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: '',
                alignment: 'center',
                border: [true, false, false, true],
              },
              {
                text: ' ',
                alignment: 'center',
                border: [false, false, true, true],
              },
            ],
          ],
        },
        styles: {
          header: {
            fontSize: 8,
            bold: true,
            alignment: 'right',
          },
          header1: {
            fontSize: 12,
            bold: true,
          },
        },
      },
    ],

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: { font: 'FontAwesome' },
      header: {
        fontSize: 14,
        bold: true,
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        fontSize: 12,
        margin: [0, -2],
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 14,
        margin: [0, -2],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_BG_005
