import React from 'react'
import Gauge from '../common'
import ComponentType from '../../../config/ComponentType'
import _ from 'lodash'

/**
 * @function GeneralGagueSelector
 * @param {Gauge} gaugeData Gauge Data from station Data
 * @param {Station} stationData Data from station
 * @param {boolean|number|string} staticValue   static data that want to show to gauge it will instead of gauge data in future @default 0
 * @returns Component
 */

export default function GeneralGagueSelector({
  gaugeData,
  stationData,
  staticValue = 0,
  key,
}) {
  //console.log('gaugeData', gaugeData)
  //console.log('stationData', stationData)
  //console.log('key', key)
  const selectedGaugeType = gaugeData.gaugeType
  if (gaugeData.isMultiStateBoolean === true) {
    const multiStateData = gaugeData.onMultiStateBoolean
    const highRef = multiStateData.highStateRef
    const lowRef = multiStateData.lowStateRef
    const isHighValueActiveHigh = multiStateData.highStateActiveHigh
    const isLowValueActiveHigh = multiStateData.lowStateActiveHigh

    const highLevelSensorData = _.find(
      stationData.sensors,
      (sensor) => sensor.refString === highRef
    )
    const lowLevelSensorData = _.find(
      stationData.sensors,
      (sensor) => sensor.refString === lowRef
    )

    switch (selectedGaugeType) {
      case ComponentType.GaugeType.MultiStepBooleanWaterDisplay:
        return (
          <Gauge.MultiStepBooleanWaterDisplay
            graphLabel={gaugeData.gaugeLabel}
            highLabel={gaugeData.valueLabel?.high}
            lowLabel={gaugeData.valueLabel?.low}
            swingLabel={gaugeData.valueLabel?.swing}
            lowerSensorData={lowLevelSensorData}
            upperSensorData={highLevelSensorData}
            isLowerActiveHigh={isLowValueActiveHigh}
            isUpperActiveHigh={isHighValueActiveHigh}
          />
        )
      default:
        return <></>
    }
  } else {
    const refString = gaugeData.sensorRefString
    const selectedSensorData = _.find(
      stationData.sensors,
      (sensor) => sensor.refString === refString
    )

    switch (selectedGaugeType) {
      case ComponentType.GaugeType.BooleanWaterDisplay:
        return (
          <Gauge.BackCompatBooleanWaterDisplay
            graphLabel={gaugeData.gaugeLabel}
            isActiveHigh={gaugeData.isActiveHigh}
            labelOnHigh={gaugeData.valueLabel?.high}
            labelOnLow={gaugeData.valueLabel?.low}
            sensorData={selectedSensorData}
          />
        )
      case ComponentType.GaugeType.BooleanDisplay:
        return (
          <Gauge.BackCompatBooleanDisplay
            graphLabel={gaugeData.gaugeLabel}
            sensorData={selectedSensorData}
            labelOnHigh={gaugeData.valueLabel?.high}
            labelOnLow={gaugeData.valueLabel?.low}
            highLevelColor={gaugeData.valueColor?.high}
            lowLevelColor={gaugeData.valueColor?.low}
          />
        )
      case ComponentType.GaugeType.Meter:
        return (
          <Gauge.BackedMeter
            graphLabel={gaugeData.gaugeLabel}
            highResolution={gaugeData.valueResolution?.high}
            lowResolution={gaugeData.valueResolution?.low}
            sensorData={selectedSensorData}
            unit={gaugeData.valueUnit}
            valueLable={gaugeData.valueNote}
          />
        )
      case ComponentType.GaugeType.NumberDisplay:
        return (
          <Gauge.BackCompatNumberDisplay
            graphLabel={gaugeData.gaugeLabel}
            sensorData={selectedSensorData}
            unit={gaugeData.valueUnit}
          />
        )

      case ComponentType.GaugeType.StringDisplay:
        return (
          <Gauge.BackCompatStringDisplay
            graphLabel={gaugeData.gaugeLabel}
            sensorData={selectedSensorData}
          />
        )
      case ComponentType.GaugeType.StaticBoolean:
        return (
          <Gauge.StaticGauge.StaticBoolean
            graphLabel={gaugeData.gaugeLabel}
            staticData={staticValue}
            labelOnHigh={gaugeData.valueLabel?.high}
            labelOnLow={gaugeData.valueLabel?.low}
            highLevelColor={gaugeData.valueColor?.high}
            lowLevelColor={gaugeData.valueColor?.low}
          />
        )
      case ComponentType.GaugeType.StaticNumber:
        return (
          <Gauge.StaticGauge.StaticNumber
            graphLabel={gaugeData.gaugeLabel}
            staticData={staticValue}
            unit={gaugeData.valueUnit}
          />
        )
      case ComponentType.GaugeType.CountingNumber:
        return (
          <Gauge.StaticGauge.StaticCountingNumber
            graphLabel={gaugeData.gaugeLabel}
            staticData={staticValue}
            unit={gaugeData.valueUnit}
          />
        )
      case ComponentType.GaugeType.StaticString:
        return (
          <Gauge.StaticGauge.StaticString
            graphLabel={gaugeData.gaugeLabel}
            staticData={staticValue}
          />
        )
      case ComponentType.GaugeType.StaticMeter:
        return (
          <Gauge.StaticGauge.StaticMeter
            graphLabel={gaugeData.gaugeLabel}
            highResolution={gaugeData.valueResolution?.high}
            lowResolution={gaugeData.valueResolution?.low}
            staticData={staticValue}
            unit={gaugeData.valueUnit}
            valueLable={gaugeData.valueNote}
          />
        )
      case ComponentType.GaugeType.StaticBooleanWater:
        return (
          <Gauge.StaticGauge.StaticBooleanWater
            graphLabel={gaugeData.gaugeLabel}
            isActiveHigh={gaugeData.isActiveHigh}
            labelOnHigh={gaugeData.valueLabel?.high}
            labelOnLow={gaugeData.valueLabel?.low}
            staticData={staticValue}
          />
        )
      case ComponentType.GaugeType.StaticMeterClassic:
        return (
          <Gauge.StaticGauge.StaticMeterClassic
            graphLabel={gaugeData.gaugeLabel}
            highResolution={gaugeData.valueResolution?.high}
            lowResolution={gaugeData.valueResolution.low}
            staticData={staticValue}
            unit={gaugeData.valueUnit}
            //valueLable={gaugeData.valueNote}
          />
        )
      case ComponentType.GaugeType.StaticMeterColors:
        return (
          <Gauge.StaticGauge.StaticMeterColors
            graphLabel={gaugeData.gaugeLabel}
            highResolution={gaugeData.valueResolution?.high}
            lowResolution={gaugeData.valueResolution?.low}
            staticData={staticValue}
            unit={gaugeData.valueUnit}
            valueLable={gaugeData.valueNote}
          />
        )
      default:
        return <></>
    }
  }
}
