import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}
const renderCheckContRows = (data) => {
  const content = _.map(data, (row) => [
    {
      text: dayjs(row?.Date).format('D/MM/BBBB') || '',
      fontSize: 16,
      style: 'tableContent',
    },
    { text: row?.Tank1['B-03'] || '', style: 'tableContent' },
    { text: row?.Tank1.น้ำ || '', style: 'tableContent' },
    { text: row?.Tank2['B-02(D)'] || '', style: 'tableContent' },
    { text: row?.Tank2.น้ำ || '', style: 'tableContent' },
    { text: row?.Tank3.P550 || '', style: 'tableContent' },
    { text: row?.Tank3.น้ำ || '', style: 'tableContent' },
    { text: row?.User || '', style: 'tableContent' },
    { text: row?.comment || '', style: 'tableContent' },
  ])
  return content
}

const renderMapTime = (data) => {
  const DataSet = renderCheckContRows(data)
  console.log(DataSet)
  if (DataSet?.length !== 16) {
    for (let i = DataSet?.length; i < 16; i++) {
      DataSet.push([
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
      ])
    }
  }
  return DataSet
}

const FM_BM_004 = async (form, formInput, subForm, system, date) => {
  console.log('system', system)

  const TableBody = renderMapTime(formInput.arr)
  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [10, 120, 10, 10],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      {
        margin: [10, 30, 10, 10],
        table: {
          widths: ['23%', '54%', '12%', '11%'],
          body: [
            [
              {
                image: 'logo_imge',
                rowSpan: 2,
                fit: [35, 35],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: 'แบบบันทึกการเติมสารเคมี',
                alignment: 'center',
                bold: true,
                rowSpan: 3,
                fontSize: 20,
                margin: [0, 10],
              },
              {
                text: `รหัสเอกสาร:  ${form?.docNo || '-'}  `,
                style: 'header',
                border: [true, true, true, false],
                colSpan: 2,
              },
              {},
            ],
            [
              {},
              { text: '', alignment: 'center' },
              {
                text: `แก้ไขครั้งที่ : ${form?.editVersion || '-'}`,
                style: 'header',
                border: [true, true, true, true],
              },
              {
                text: `หน้าที่ : 1/1`,
                style: 'header',
                border: [true, true, true, true],
              },
            ],
            [
              {
                text: `THAI TALLOW AND OIL CO.,LTD.`,
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: 12,
                border: [true, false, true, true],
              },
              '',
              {
                text: `วันที่บังคับใช้: ${
                  dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                }`,
                style: 'header',
                border: [true, true, true, true],
                colSpan: 2,
              },
              {},
            ],
          ],
        },
      },
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        margin: [0, -20, 0, -20],
        table: {
          headerRows: 0,
          heights: [1],
          widths: [
            '10%',
            '11.25%',
            '11.25%',
            '11.25%',
            '11.25%',
            '11.25%',
            '11.25%',
            '11.25%',
            '11.25%',
          ],
          body: [
            [
              {
                text: 'ว/ด/ป',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 10, 0, 0],
                bold: true,
              },
              {
                text: 'ถังเคมี 1',
                style: 'headerTable',
                colSpan: 2,
                bold: true,
              },
              { text: '3', style: 'headerTable' },
              {
                text: 'ถังเคมี 2',
                style: 'headerTable',
                colSpan: 2,
                bold: true,
              },
              { text: '5', style: 'headerTable' },
              {
                text: 'ถังเคมี 3',
                style: 'headerTable',
                colSpan: 2,
                bold: true,
              },
              { text: '', style: 'headerTable' },
              {
                text: 'ผู้รับผิดชอบ',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 10, 0, 0],
                bold: true,
              },
              {
                text: 'หมายเหตุ',
                style: 'headerTable',
                bold: true,
                rowSpan: 2,
                margin: [0, 10, 0, 0],
              },
            ],
            [
              { text: '1', style: 'headerTable' },
              { text: 'B-03(L)', style: 'headerTable', bold: true },
              { text: 'น้ำ(L)', style: 'headerTable', bold: true },
              { text: 'B-02(L)', style: 'headerTable', bold: true },
              { text: 'น้ำ(L)', style: 'headerTable', bold: true },
              { text: 'P550(L)', style: 'headerTable', bold: true },
              { text: 'น้ำ(L)', style: 'headerTable', bold: true },
              { text: 'A\n30-54', style: 'headerTable' },
              { text: 'KV\n50-110', style: 'headerTable' },
            ],
            ...TableBody,
          ],
        },
      },
      {
        margin: [0, 20, 0, 20],
        table: {
          widths: ['70%', '30%'],
          body: [
            [
              {
                text: ``,
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: `ผู้ตรวจสอบ`,
                bold: true,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: ``,
                alignment: 'center',
                margin: [0, 5, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: `...................................................`,
                bold: true,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: ``,
                alignment: 'center',
                margin: [0, 5, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: `(...................................................)`,

                bold: true,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: ``,
                alignment: 'center',
                margin: [0, 5, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: `หัวหน้าแผนก Biomass`,
                alignment: 'center',
                bold: true,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    // footer: function (currentPage, pageCount) {
    //   return []
    // },

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: { font: 'FontAwesome' },
      header: {
        fontSize: 14,
        bold: true,
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        fontSize: 14,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 15,
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_BM_004
