import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import EditHistoryFrom from './EditHistoryFrom'
import {
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Button,
} from '@chakra-ui/react'
import _ from 'lodash'

export default function RenderDataHistoryForm({
  form,
  formInput,
  formId,
  modalOpen = () => {},
}) {
  const checkHeadTable = (data) => {
    if (data?.attribute?.length === 0 || data?.formType === 'SELECTOR') {
      return (
        <Td w="10%" borderColor={'black'} borderBottom={'1px'} borderX="1px">
          <div className="text-center"></div>
        </Td>
      )
    } else {
      return _.map(data?.attribute, (eachCol) => (
        <Th w="10%" borderBlock={'black'} border="1px">
          <div className="text-center">{eachCol?.subTopic}</div>
        </Th>
      ))
    }
  }

  const checkType = (data) => {
    if (typeof data === 'number') {
      return data.toFixed(2)
    }
    return data
  }

  const renderTableBody = (col, row, index) => {
    if (col?.attribute?.length === 0 || col?.formType === 'SELECTOR') {
      return (
        <Td w="10%" borderBlock={'black'} border={'1px'}>
          <div className="text-center">{checkType(row[col?.name])}</div>
        </Td>
      )
    } else {
      return _.map(col?.attribute, (eachCol) => (
        <Td w="10%" borderBlock={'black'} border={'1px'}>
          <div className="text-center">
            {checkType(row?.[col.name]?.[eachCol?.subTopic])}
          </div>
        </Td>
      ))
    }
  }

  const renderCard = () => (
    <div>
      <TableContainer>
        <Table colorScheme="gray" size="sm" border="2px">
          <Thead bg={'gray.200'} border="1px">
            <Tr borderColor="black" borderTop="1px">
              {_.map(form?.components, (row) => (
                <Td
                  borderBlock={'black'}
                  borderBottom={'1px'}
                  borderX="1px"
                  sx={{ width: '10' }}
                  w="100%"
                  colSpan={_.size(
                    row?.formType === 'SELECTOR' ? 1 : row.attribute
                  )}
                >
                  <div className="text-center font-bold">{row?.topic}</div>
                </Td>
              ))}
              {/* <Td
                borderBlock={'black'}
                borderBottom={'1px'}
                borderX="1px"
                sx={{ width: '10' }}
                w="100%"
              >
                <div className="text-center font-bold">ดำเนินการ</div>
              </Td> */}
            </Tr>
            <Tr> {_.map(form?.components, (row) => checkHeadTable(row))}</Tr>
          </Thead>
          <Tbody>
            {/* {console.log('tttt', formInput?.arr?.[0]?.time)}{' '}
            {console.log('formm', formInput)} */}
            {_.map(formInput?.arr, (row, index) => (
              <Tr key={index} borderColor="black" border="1px">
                {_.map(form?.components, (col) =>
                  renderTableBody(col, row, index)
                )}
                <Td style={{ border: 'none' }}>
                  <Button
                    colorScheme="yellow"
                    size="xs"
                    onClick={() => modalOpen(row)}
                  >
                    <Link
                      to={`/humaninput/history/formList/historyDetail/edit/${formId}/${row?._id}`}
                    >
                      {/* <EditHistoryFrom formInput={formInput} /> */}
                      แก้ไข
                    </Link>
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  )

  return <div> {renderCard()}</div>
}
