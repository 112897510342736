import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _, { each } from 'lodash'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

// const renderMapTime = (data) => {
//   // const content = []
//   console.log('data in table', data)
//   _.map(data, (eachData, index) => {
//     return [
//       { text: index, style: 'tableContent' },
//       { text: eachData?.Time01 ? eachData?.Time01 : '', style: 'tableContent' },
//       { text: eachData?.Time04 ? eachData?.Time04 : '', style: 'tableContent' },
//       { text: eachData?.Time08 ? eachData?.Time08 : '', style: 'tableContent' },
//       { text: eachData?.Time12 ? eachData?.Time12 : '', style: 'tableContent' },
//       { text: eachData?.Time16 ? eachData?.Time16 : '', style: 'tableContent' },
//       { text: eachData?.Time20 ? eachData?.Time20 : '', style: 'tableContent' },
//       { text: eachData?.Time24 ? eachData?.Time24 : '', style: 'tableContent' },
//       {
//         text: eachData?.comment ? eachData?.Time01 : '',
//         style: 'tableContent',
//       },
//     ]
//   })
// }
// const renderTableBody = (data) => {
//   const content = _.map(data?.arr, (row, key) => [
//     { text: key + 1 || '', style: 'tableContent' },
//     { text: '' || '', style: 'tableContent' },
//     { text: '' || '', style: 'tableContent' },
//     { text: '' || '', style: 'tableContent' },
//     { text: '' || '', style: 'tableContent' },
//     { text: '' || '', style: 'tableContent' },
//     { text: '' || '', style: 'tableContent' },
//     { text: '' || '', style: 'tableContent' },
//     { text: row?.comment || '', style: 'tableContent' },
//   ])
//   return content
// }

const FM_BM_002 = async (form, formInput, subForm, system, date) => {
  const timeMap = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ]

  const { arr } = formInput

  console.log('form', form)
  console.log('formInput', formInput)

  const dataGroup = _.groupBy(arr, (e) => dayjs(e.date).format('D'))

  const dataBeforeMergeFromDataGroup = _.map(timeMap, (eachTime) => {
    if (dataGroup[eachTime]) {
      return _.map(dataGroup[eachTime], (each) => {
        const payload = {
          Time01: each?.Time01 === '' ? undefined : each?.Time01,
          Time04: each?.Time04 === '' ? undefined : each?.Time04,
          Time08: each?.Time08 === '' ? undefined : each?.Time08,
          Time12: each?.Time12 === '' ? undefined : each?.Time12,
          Time16: each?.Time16 === '' ? undefined : each?.Time16,
          Time20: each?.Time20 === '' ? undefined : each?.Time20,
          Time24: each?.Time24 === '' ? undefined : each?.Time24,
          comment: each?.comment === '' ? undefined : each?.comment,
        }
        return payload
      })
    } else {
      return undefined
    }
  })

  console.log('form2', form)
  console.log('formInput2', formInput)

  console.log('dataGroup', dataGroup)
  console.log('dataBeforeMergeFromDataGroup', dataBeforeMergeFromDataGroup)

  const dataAfterMergeFromDataGroup = _.map(
    dataBeforeMergeFromDataGroup,
    (each) => {
      console.log('each in dataBeforeMergeFromDataGroup', each)
      const obj1 = {}

      _.map(each, (e) => {
        const definedProps = (obj) =>
          Object.fromEntries(
            Object.entries(obj).filter(([k, v]) => v !== undefined)
          )
        Object.assign(obj1, definedProps(e))
      })
      return obj1
    }
  )

  console.log('dataAfterMergeFromDataGroup', dataAfterMergeFromDataGroup)

  const TableBody = _.map(dataAfterMergeFromDataGroup, (eachData, index) => {
    return [
      { text: index + 1, style: 'tableContent' },
      {
        text: eachData?.Time01 ? eachData?.Time01 : '',
        style: 'tableContent',
      },
      {
        text: eachData?.Time04 ? eachData?.Time04 : '',
        style: 'tableContent',
      },
      {
        text: eachData?.Time08 ? eachData?.Time08 : '',
        style: 'tableContent',
      },
      {
        text: eachData?.Time12 ? eachData?.Time12 : '',
        style: 'tableContent',
      },
      {
        text: eachData?.Time16 ? eachData?.Time16 : '',
        style: 'tableContent',
      },
      {
        text: eachData?.Time20 ? eachData?.Time20 : '',
        style: 'tableContent',
      },
      {
        text: eachData?.Time24 ? eachData?.Time24 : '',
        style: 'tableContent',
      },
      {
        text: eachData?.comment ? eachData?.comment : '',
        style: 'tableContent',
      },
    ]
  })

  console.log('TableBody')

  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [10, 75, 10, 10],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '14',
    },

    header: [
      {
        margin: [10, 10, 10, 2],
        style: 'tableExample',
        table: {
          widths: ['30%', '45%', '12.45%', '12.455%'],
          body: [
            [
              // Row 1
              {
                image: 'logo_imge',
                rowSpan: 2,
                fit: [30, 30],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: `${form?.name || ''}`,
                alignment: 'center',
                bold: true,
                fontSize: 16,
                margin: [0, 0, 0, -3],
                border: [true, true, true, false],
              },
              {
                text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
                style: 'header',
                alignment: 'center',
                colSpan: 2,
                border: [true, true, true, true],
              },
              { text: '', border: [false, true, true, true] },
            ],
            [
              // Row 2
              { text: '', border: [true, false, true, false] },
              {
                text: '',
                border: [true, false, true, false],
              },
              {
                text: `แก้ไขครั้งที่: 0${form?.editVersion || '-'}`,
                style: 'header',
                margin: [0, 2, 0, 0],
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'หน้าที่ : 1/1',
                style: 'header',
                margin: [0, 2, 0, 0],
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            [
              // Row 3
              {
                text: `THAI TALLOW AND OIL CO.,LTD.`,
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: 12,
                border: [true, false, true, true],
              },
              {
                text: `${dayjs(date).format('ประจำเดือน MMMM พ.ศ. BBBB')}`,
                alignment: 'center',
                style: 'header',
                border: [true, false, true, true],
              },
              {
                text: `วันที่บังคับใช้ : ${
                  dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                }`,
                style: 'header',
                colSpan: 2,
                margin: [0, 2, 0, 0],
                alignment: 'center',
                border: [true, true, true, true],
              },
              {},
            ],
          ],
        },
      },
    ],
    info: {
      title: `FM_PD_001 ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        table: {
          widths: [
            '7%', //1
            '11%', //2
            '11%', //3
            '11%', //4
            '11%', //5
            '11%', //6
            '11%', //7
            '11%', //8
            '16%', //9
          ],
          body: [
            [
              // Row 1
              {
                //1
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
              {
                //2
                text: 'ช่วงเวลาการวัด',
                alignment: 'center',
                fontSize: '14',
                bold: true,
                border: [true, true, false, true],
                colSpan: 7,
                margin: [0, -2],
              },
              {
                //3
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
              {
                //4
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
              {
                //5
                text: '',
                colSpan: 2,
                alignment: 'center',
                border: [true, true, true, false],
                bold: true,
              },
              {}, //6
              {
                //7
                text: '',
                colSpan: 2,
                alignment: 'center',
                border: [true, true, true, false],
                bold: true,
              },
              {}, //8
              {
                //9
                text: '',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],

                // margin: [0, 2],
              },
            ],
            [
              // Row 2
              {
                //1
                text: 'วันที่',
                alignment: 'center-bottom',
                margin: [6, -9],
                bold: true,
                border: [true, false, true, false],
              },
              {
                //2
                text: '01.00',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
                margin: [0, 2],
              },
              {
                //3
                text: '04.00',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
                margin: [0, 2],
              },
              {
                //4
                text: '08.00',
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
                margin: [0, 2],
              },
              {
                //5
                text: '12.00',
                // colSpan: 2,
                alignment: 'center',
                border: [true, true, true, false],
                margin: [0, 2],
                bold: true,
              },
              {
                //6
                text: '16.00',
                // colSpan: 2,
                alignment: 'center',
                border: [true, true, true, false],
                margin: [0, 2],
                bold: true,
              },
              {
                //7
                text: '20.00',
                // colSpan: 2,
                alignment: 'center',
                border: [true, true, true, false],
                margin: [0, 2],
                bold: true,
              },
              {
                //8
                text: '24.00',
                // colSpan: 2,
                alignment: 'center',
                border: [true, true, true, false],
                margin: [0, 2],
                bold: true,
              },
              {
                //9
                text: 'หมายเหตุ',
                alignment: 'center',
                margin: [0, -9],
                bold: true,
                border: [true, false, true, false],
              },
            ],
            ...TableBody,
            // ...TableBody,
          ],
        },
      },

      {
        alignment: 'between',
        table: {
          widths: [`50%`, `50%`],
          body: [
            [
              {
                text: '',
                alignment: 'left',
                border: [false, false, false, false],
              },
              {
                text: ` `,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '     ',
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: ' ......................................................... ',
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                alignment: 'left',
                border: [false, false, false, false],
              },
              {
                text: '(.........................................................)   ',
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: ' หัวหน้าแผนก Biomass ',
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
          ],
        },
        styles: {
          header: {
            fontSize: 8,
            bold: true,
            alignment: 'right',
          },
          header1: {
            fontSize: 12,
            bold: true,
          },
        },
      },
    ],

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: {
        font: 'FontAwesome',
        bold: true,
        alignment: 'center',
        fontSize: 12,
      },
      header: {
        bold: true,
        fontSize: 12,
        margin: [0, -2, 0, -3],
      },
      header2: {
        fontSize: 12,
      },
      headerTable: {
        margin: [-3, -3, -3, -2],
        fontSize: 13,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 13,
        margin: [0, 0, 0, 0],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_BM_002
