import React, { useEffect, useState } from 'react'
import {
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Button,
} from '@chakra-ui/react'
import _ from 'lodash'
import dayjs from 'dayjs'

export default function RenderDataForm({
  form,
  formInput,
  modalOpen = () => {},
}) {
  const checkHeadTable = (data) => {
    if (data?.formType === 'CALCULATE') {
      return (
        <Td w="10%" borderColor={'black'} borderBottom={'1px'} borderX="1px">
          <div className="text-center"></div>
        </Td>
      )
    } else if (data?.attribute?.length === 0 || data?.formType === 'SELECTOR') {
      return (
        <Td w="10%" borderColor={'black'} borderBottom={'1px'} borderX="1px">
          <div className="text-center"></div>
        </Td>
      )
    } else {
      return _.map(data?.attribute, (eachCol) => (
        <Th w="10%" borderBlock={'black'} border="1px">
          <div className="text-center">{eachCol?.subTopic}</div>
        </Th>
      ))
    }
  }

  const checkType = (data) => {
    if (typeof data === 'number') {
      return data.toFixed(2)
    } else if (typeof data === 'boolean') {
      return <div>{data === true ? <i className="fas fa-check"></i> : ''}</div>
    } else if (data === dayjs(data).format('YYYY-MM-DD')) {
      return dayjs(data).format('D/MM/BBBB')
    }
    return data
  }

  const renderTableBody = (colName, rows, index) => {
    if (colName?.formType === 'CALCULATE') {
      return (
        <Td w="10%" borderBlock={'black'} border="1px">
          <div className="text-center">
            {checkType(rows[colName?.name]) || '-'}
          </div>
        </Td>
      )
    } else if (
      colName?.attribute?.length === 0 ||
      colName?.formType === 'SELECTOR'
    ) {
      return (
        <Td w="10%" borderBlock={'black'} border="1px">
          <div className="text-center">
            {checkType(rows[colName?.name]) || '-'}
          </div>
        </Td>
      )
    } else {
      return _.map(colName?.attribute, (eachCol) => (
        <Td w="10%" borderBlock={'black'} border="1px">
          <div className="text-center ">
            {checkType(rows?.[colName?.name]?.[eachCol?.subTopic]) || '-'}
          </div>
        </Td>
      ))
    }
  }

  const renderCard = () => (
    <div>
      <TableContainer>
        <Table colorScheme="gray" size="sm" border="2px">
          <Thead bg={'gray.200'} border="1px">
            <Tr borderColor="black" borderTop="1px">
              {_.map(form?.components, (row) => (
                <Td
                  borderBlock={'black'}
                  borderBottom={'1px'}
                  borderX="1px"
                  sx={{ width: '10' }}
                  w="100%"
                  colSpan={_.size(
                    row?.formType === 'SELECTOR'
                      ? 1
                      : row?.formType === 'CALCULATE'
                      ? 1
                      : row?.attribute
                  )}
                >
                  <div className="text-center font-bold">{row?.topic}</div>
                </Td>
              ))}
              {/* <Td
                borderBlock={'black'}
                borderBottom={'1px'}
                borderX="1px"
                sx={{ width: '10' }}
                w="100%"
              >
                <div className="text-center font-bold">ดำเนินการ</div>
              </Td> */}
            </Tr>
            <Tr> {_.map(form?.components, (row) => checkHeadTable(row))}</Tr>
          </Thead>
          <Tbody>
            {_.map(formInput?.arr, (row, index) => (
              <Tr key={index} borderColor="black" border="1px">
                {_.map(form?.components, (col) =>
                  renderTableBody(col, row, index)
                )}
                {/* <Td>
                  <Button
                    colorScheme="yellow"
                    size="xs"
                    onClick={() => modalOpen(row)}
                  >
                    แก้ไข
                  </Button>
                </Td> */}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  )

  return renderCard()
}
