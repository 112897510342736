import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderDataScrewPress = (data, ScrewMap) => {
  const content = []
  _.map(ScrewMap, (Sample, index) => {
    let indexTime = false
    if (data?.length === 0) {
      return content.push([
        { text: Sample, style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    } else {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (Sample === eachData?.Process) {
          indexTime = true
          content.push([
            { text: eachData?.Process || '', style: 'tableContent' },
            { text: eachData?.Fiber || '', style: 'tableContent' },
            { text: eachData?.TNut || '', style: 'tableContent' },
            { text: eachData?.BNut || '', style: 'tableContent' },
            { text: eachData?.Kernel || '', style: 'tableContent' },
            { text: eachData?.BKernel || '', style: 'tableContent' },
            { text: eachData?.Shell || '', style: 'tableContent' },
            { text: eachData?.ExTime + ' น.' || '', style: 'tableContent' },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: Sample, style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    }
  })
  return content
}
const renderDataFibercyclone = (data) => {
  const content = []
  if (data?.length === 0) {
    content.push([
      { text: 'Fibercyclone', style: 'tableContent' },
      { text: '', style: 'tableContent' },
      { text: '', style: 'tableContent' },
      { text: '', style: 'tableContent' },
      { text: '', style: 'tableContent' },
      { text: '', style: 'tableContent' },
      { text: '', style: 'tableContent' },
      { text: '', style: 'tableContent' },
    ])
  } else {
    _.map(data, (row) =>
      content.push([
        { text: row?.Process || '', style: 'tableContent' },
        { text: row?.Fiber || '', style: 'tableContent' },
        { text: row?.Nut || '', style: 'tableContent' },
        { text: row?.BNut || '', style: 'tableContent' },
        { text: row?.Kernel || '', style: 'tableContent' },
        { text: row?.BKernel || '', style: 'tableContent' },
        { text: row?.NutKernel || '', style: 'tableContent' },
        { text: row?.ExTime + ' น.' || '', style: 'tableContent' },
      ])
    )
  }
  return content
}

const renderDataShellcyclon = (data) => {
  const ShellMap = ['Shellcyclone', 'Shell After Claybath']
  const content = []
  _.map(ShellMap, (Sample, index) => {
    let indexTime = false
    if (data?.length === 0) {
      return content.push([
        { text: Sample, style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    } else {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (Sample === eachData?.Process) {
          indexTime = true
          content.push([
            { text: eachData?.Process || '', style: 'tableContent' },
            { text: eachData?.Shell || '', style: 'tableContent' },
            { text: eachData?.Nut || '', style: 'tableContent' },
            { text: eachData?.BNut || '', style: 'tableContent' },
            { text: eachData?.Kernel || '', style: 'tableContent' },
            { text: eachData?.BKernel || '', style: 'tableContent' },
            { text: eachData?.TKernel || '', style: 'tableContent' },
            { text: eachData?.ExTime + ' น.' || '', style: 'tableContent' },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: Sample, style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    }
  })
  return content
}
const renderDataKernel = (data) => {
  const KernelMap = ['Wet Kernel', 'Dry Kernel']
  const content = []
  _.map(KernelMap, (Sample, index) => {
    let indexTime = false
    if (data?.length === 0) {
      return content.push([
        { text: Sample, style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    } else {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (Sample === eachData?.Process) {
          indexTime = true
          content.push([
            { text: eachData?.Process || '', style: 'tableContent' },
            { text: eachData?.Nut || '', style: 'tableContent' },
            { text: eachData?.BNut || '', style: 'tableContent' },
            { text: eachData?.Kernel || '', style: 'tableContent' },
            { text: eachData?.BKernel || '', style: 'tableContent' },
            { text: eachData?.Shell || '', style: 'tableContent' },
            { text: eachData?.TShell || '', style: 'tableContent' },
            { text: eachData?.ExTime + ' น.' || '', style: 'tableContent' },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: Sample, style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    }
  })
  return content
}
const renderAfterKernel = (data, SiloMap) => {
  const content = []
  _.map(SiloMap, (Sample, index) => {
    let indexTime = false
    if (data?.length === 0) {
      return content.push([
        { text: Sample, style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    } else {
      _.map(data, (eachData, indexData) => {
        if (Sample === eachData?.Process) {
          indexTime = true
          content.push([
            { text: eachData?.Process || '', style: 'tableContent' },
            { text: eachData?.Nut || '', style: 'tableContent' },
            { text: eachData?.BNut || '', style: 'tableContent' },
            { text: eachData?.Kernel || '', style: 'tableContent' },
            { text: eachData?.BKernel || '', style: 'tableContent' },
            { text: eachData?.Shell || '', style: 'tableContent' },
            { text: eachData?.TShell || '', style: 'tableContent' },
            { text: eachData?.M || '', style: 'tableContent' },
            { text: eachData?.ExTime + ' น.' || '', style: 'tableContent' },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: Sample, style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    }
  })
  return content
}

const renderHeaderBody = (data, date) => {
  if (data?.length === 0) {
    return {
      text: [
        {
          text: 'วันที่...................................................  ',
          style: 'header2',
        },
        { text: 'Shift\t', style: 'header2' },
        { text: '', style: 'icon' },
        { text: '   A\t', style: 'header2' },
        { text: '', style: 'icon' },
        { text: '   B       ', style: 'header2' },
        {
          text: 'เวลา....................................................',
          style: 'header2',
        },
      ],

      alignment: 'center',
      margin: [10, 0, 10, 0],
    }
  } else {
    return {
      text: [
        {
          text: `วันที่ ${dayjs(date).format('D เดือน MMMM พ.ศ. BBBB \t')}`,
          style: 'header2',
        },
        {
          text: 'Shift\t',
          style: 'header2',
        },
        { text: data?.[0].Shift.A ? '' : '', style: 'icon' },
        { text: '   A\t', style: 'header2' },
        { text: data?.[0].Shift.B ? '' : '', style: 'icon' },
        { text: '   B       ', style: 'header2' },
        {
          text: 'เวลา ' + data?.[0].Time + ' น.\t',
          style: 'header2',
        },
      ],
      alignment: 'center',
      margin: [10, 0, 10, 0],
    }
  }
}
const FM_QC_005 = async (form, formInput, subForm, system, date) => {
  const ScrewMap = [
    'Screw Press #1',
    'Screw Press #2',
    'Screw Press #3',
    'Screw Press #4',
  ]
  const SiloMap = [
    'After Kernel Silo #1',
    'After Kernel Silo #2',
    'After Kernel Silo #3',
    'After Kernel Silo #4',
    'After Kernel Silo #5',
  ]
  //find data for map in table
  const dataHeader = _.filter(formInput?.arr, {
    subForm_name: 'วัน-เวลาของแต่ละ Shift',
  })
  const dataScrewPress = _.filter(formInput?.arr, {
    subForm_name: 'Screw Press',
  })
  const dataFibercyclone = _.filter(formInput?.arr, {
    subForm_name: 'Fibercyclone',
  })
  const dataShellcyclone = _.filter(formInput?.arr, {
    subForm_name: 'Shellcyclone & Shell After Claybath',
  })
  const dataKernel = _.filter(formInput?.arr, {
    subForm_name: 'Wet & Dry Kernel',
  })
  const dataAfterKernel = _.filter(formInput?.arr, {
    subForm_name: 'After Kernel Silo',
  })
  const HeaderBody = renderHeaderBody(dataHeader, date)
  const tableBodyScrewPress = renderDataScrewPress(dataScrewPress, ScrewMap)
  const tableBodyFibercyclone = renderDataFibercyclone(dataFibercyclone)
  const tableBodyShellcyclon = renderDataShellcyclon(dataShellcyclone)
  const tableBodyKerne = renderDataKernel(dataKernel)
  const tableAfterKernel = renderAfterKernel(dataAfterKernel, SiloMap)

  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 120, 20, 10],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      {
        margin: [20, 30, 20, 10],

        table: {
          widths: ['30%', '40%', '30%'],
          body: [
            [
              {
                image: 'logo_imge',
                rowSpan: 2,
                fit: [35, 35],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: `TTO3: รายงานการวิเคราะห์\n % การผลิตในแต่ละ Process`,
                alignment: 'center',
                bold: true,
                rowSpan: 3,
                fontSize: 20,
                margin: [0, 10],
              },
              {
                text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
                style: 'header',
                border: [true, true, true, false],
              },
            ],
            [
              {},
              { text: ``, alignment: 'center' },
              {
                text: `วันที่บังคับใช้ : ${
                  dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                }`,
                style: 'header',
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: `THAI TALLOW AND OIL CO.,LTD.`,
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: 12,
                border: [true, false, true, true],
              },
              {},
              {
                text: `แก้ไขครั้งที่: 0${form?.editVersion || '-'}`,
                style: 'header',
                border: [true, false, true, true],
              },
            ],
          ],
        },
      },
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      HeaderBody,
      {
        margin: [0, 20, 0, 0],
        table: {
          widths: ['16.5%', '11%', '11%', '11%', '11%', '11%', '15.5%', '13%'],
          body: [
            [
              {
                text: 'Process',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 4, 0, -4],
              },
              { text: '% Fiber', style: 'headerTable' },
              { text: '%T.Nut', style: 'headerTable' },
              { text: '%B.Nut', style: 'headerTable' },
              { text: '%Kernel', style: 'headerTable' },
              { text: '%B.Kernel', style: 'headerTable' },
              { text: '%Shell', style: 'headerTable' },
              {
                text: 'เวลาเก็บตัวอย่าง',
                style: 'headerTable',
                rowSpan: 2,
                margin: [-3, 4, -3, -4],
              },
            ],
            [
              {},
              { text: '55', style: 'headerTable' },
              { text: '45', style: 'headerTable' },
              { text: '<10', style: 'headerTable' },
              { text: '<3', style: 'headerTable' },
              { text: '<4', style: 'headerTable' },
              { text: '<3', style: 'headerTable' },
              {},
            ],
            ...tableBodyScrewPress,
          ],
        },
      },
      {
        table: {
          widths: ['16.5%', '11%', '11%', '11%', '11%', '11%', '15.5%', '13%'],
          body: [
            [
              {
                text: 'Process',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 4, 0, -4],
              },
              { text: '% Fiber', style: 'headerTable' },
              { text: '%T.Nut', style: 'headerTable' },
              { text: '%B.Nut', style: 'headerTable' },
              { text: '%Kernel', style: 'headerTable' },
              { text: '%B.Kernel', style: 'headerTable' },
              { text: '%T.Nut+T.Kernel', style: 'headerTable' },
              {
                text: 'เวลาเก็บตัวอย่าง',
                style: 'headerTable',
                rowSpan: 2,
                margin: [-3, 4, -3, -4],
              },
            ],
            [
              {},
              { text: '>99.2', style: 'headerTable' },
              { text: '0', style: 'headerTable' },
              { text: '0', style: 'headerTable' },
              { text: '<0.3', style: 'headerTable' },
              { text: '<0.5', style: 'headerTable' },
              { text: '<0.8', style: 'headerTable' },
              {},
            ],
            ...tableBodyFibercyclone,
          ],
        },
      },
      {
        table: {
          widths: ['16.5%', '11%', '11%', '11%', '11%', '11%', '15.5%', '13%'],
          body: [
            [
              {
                text: 'Process',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 4, 0, -4],
              },
              { text: '%Shell', style: 'headerTable' },
              { text: '%Nut', style: 'headerTable' },
              { text: '%B.Nut', style: 'headerTable' },
              { text: '%Kernel', style: 'headerTable' },
              { text: '%B.Kernel', style: 'headerTable' },
              { text: '%T.kernel', style: 'headerTable' },
              {
                text: 'เวลาเก็บตัวอย่าง',
                style: 'headerTable',
                rowSpan: 2,
                margin: [-3, 4, -3, -4],
              },
            ],
            [
              {},
              { text: '>99.2', style: 'headerTable' },
              { text: '0', style: 'headerTable' },
              { text: '<0.5', style: 'headerTable' },
              { text: '<0.5', style: 'headerTable' },
              { text: '<0.5', style: 'headerTable' },
              { text: '<0.8', style: 'headerTable' },
              {},
            ],
            ...tableBodyShellcyclon,
          ],
        },
      },
      {
        table: {
          widths: ['16.5%', '11%', '11%', '11%', '11%', '11%', '15.5%', '13%'],
          body: [
            [
              {
                text: 'Process',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 4, 0, -4],
              },
              { text: '% Nut', style: 'headerTable' },
              { text: '%B.Nut', style: 'headerTable' },
              { text: '%Kernel', style: 'headerTable' },
              { text: '%B.Kernel', style: 'headerTable' },
              { text: '%Shell', style: 'headerTable' },
              { text: '%T.Shell', style: 'headerTable' },
              {
                text: 'เวลาเก็บตัวอย่าง',
                style: 'headerTable',
                rowSpan: 2,
                margin: [-3, 4, -3, -4],
              },
            ],
            [
              {},
              { text: '<4', style: 'headerTable' },
              { text: '<4', style: 'headerTable' },
              { text: '>80', style: 'headerTable' },
              { text: '<3', style: 'headerTable' },
              { text: '<7', style: 'headerTable' },
              { text: 'Wet+Dry<10', style: 'headerTable' },
              {},
            ],
            ...tableBodyKerne,
          ],
        },
      },
      {
        table: {
          widths: [
            '16.8%',
            '11.1%',
            '11.2%',
            '11.2%',
            '11.3%',
            '11.2%',
            '9%',
            '5%',
            '13.2%',
          ],
          body: [
            [
              {
                text: 'Process',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 4, 0, -4],
              },
              { text: '% Nut', style: 'headerTable' },
              { text: '%B.Nut', style: 'headerTable' },
              { text: '%Kernel', style: 'headerTable' },
              { text: '%B.Kernel', style: 'headerTable' },
              { text: '%Shell', style: 'headerTable' },
              { text: '%T.Shell', style: 'headerTable' },
              { text: '%M', style: 'headerTable' },
              {
                text: 'เวลาเก็บตัวอย่าง',
                style: 'headerTable',
                rowSpan: 2,
                margin: [-3, 4, -3, -4],
              },
            ],
            [
              {},
              { text: '<4', style: 'headerTable' },
              { text: '<4', style: 'headerTable' },
              { text: '>80', style: 'headerTable' },
              { text: '<3', style: 'headerTable' },
              { text: '<7', style: 'headerTable' },
              { text: '<10', style: 'headerTable' },
              { text: '<8', style: 'headerTable' },
              {},
            ],
            ...tableAfterKernel,
          ],
        },
      },
      {
        margin: [0, 50],
        table: {
          widths: ['33.33%', '33.33%', '33.33%'],
          body: [
            [
              {
                text: 'ลงชื่อ...................................',
                style: 'tableContent',
                border: [false, false, false, false],
              },
              {
                text: 'ลงชื่อ...................................',
                style: 'tableContent',
                border: [false, false, false, false],
              },
              {
                text: 'ลงชื่อ...................................',
                style: 'tableContent',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '(พนักงานควบคุมคุณภาพ)',
                style: 'tableContent',
                border: [false, false, false, false],
              },
              {
                text: '(ผู้ตรวจสอบ)',
                style: 'tableContent',
                border: [false, false, false, false],
              },
              {
                text: '(หัวหน้างานผลิต)',
                style: 'tableContent',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    // footer: function (currentPage, pageCount) {
    //   return []
    // },

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: {
        font: 'FontAwesome',
        bold: true,
        alignment: 'center',
        fontSize: 10,
      },
      header: {
        bold: true,
        fontSize: 15,
        margin: [0, 0, 0, 0],
      },
      header2: {
        fontSize: 16,
        bold: true,
      },
      headerTable: {
        margin: [-3, 0, -2, 0],
        fontSize: 15,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 14,
        margin: [0, 0, 0, 0],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_QC_005
