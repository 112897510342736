import React from 'react'
import { Box, Image } from '@chakra-ui/react'

export default function StaticStringDisplay({ staticData, graphLabel = '' }) {
    return (
        <Box
            maxW="sm"
            borderWidth="1px"
            borderRadius="lg"
            className=" ml-4 my-2 p-2 w-full  md:w-full"
            overflow="hidden"
        >
            <Box p="1">
                <h5 className="font-bold text-xl"> {graphLabel} </h5>
                <Image
                    src={'data:image/่jpeg;base64,' + staticData}
                    alt={'image'}
                    borderRadius="lg"
                ></Image>
            </Box>
        </Box>
    )
}
