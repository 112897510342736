/* eslint-disable no-nested-ternary */
import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { formInputPost } from 'redux/actions'
import waterImage from './waterImage.json'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderTable = (data) => {
  const TableBody = [data]
  console.log('data?.lengthta', data?.length)
  if (data?.length === 0) {
    TableBody.push(
      [
        { text: '', border: [true, true, true, false] },
        {
          text: 'บ่อ Biogas 1',
          style: 'nameContent',
          margin: [0, 10],
        },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
      ],
      [
        {
          image: waterImage?.image,
          fit: [100, 100],
          margin: [10, -30],
          border: [true, false, true, false],
        },
        {
          text: 'บ่อ Cake Decanter 2',
          style: 'nameContent',
          margin: [0, 10],
        },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
      ],
      [
        { text: '', border: [true, false, false, true] },
        {
          text: 'บ่อบำบัดบ่อสุดท้าย',
          style: 'nameContent',
          margin: [0, 10],
        },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
      ]
    )
    return TableBody
  } else {
    //มีข้อมูล

    _.map(data, (eachData, index) => {
      if (eachData?.wasteWater === 'บ่อ Biogas 1') {
        TableBody.push([
          { text: '', border: [true, true, true, false] },
          {
            text: eachData?.wasteWater || '', //บ่อ Biogas 1
            margin: [0, 10],
            style: 'nameContent',
          },
          {
            text: eachData?.pH || '',
            margin: [0, 10],
            style: 'tableContent',
          },
          {
            text: eachData?.temp || '',
            margin: [0, 10],
            style: 'tableContent',
          },
          {
            text: eachData?.COD || '',
            margin: [0, 10],
            style: 'tableContent',
          },
          {
            text: eachData?.BOD || '',
            margin: [0, 10],
            style: 'tableContent',
          },
          {
            text: eachData?.SS || '',
            margin: [0, 10],
            style: 'tableContent',
          },
          {
            text: eachData?.VFA || '',
            margin: [0, 10],
            style: 'tableContent',
          },
          {
            text: eachData?.OilGrease || '',
            margin: [0, 10],
            style: 'tableContent',
          },
          {
            text: eachData?.Alkaline || '',
            margin: [0, 10],
            style: 'tableContent',
          },
          {
            text: eachData?.comment || '',
            margin: [0, 10],
            style: 'tableContent',
          },
        ])
        return TableBody
      }
      if (eachData?.wasteWater === 'บ่อบำบัดบ่อสุดท้าย') {
        TableBody.push(
          [
            { text: '', border: [true, true, true, false] },
            {
              text: 'บ่อ Biogas 1',
              style: 'nameContent',
              margin: [0, 10],
            },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
          ],
          [
            {
              image: waterImage?.image,
              fit: [100, 100],
              margin: [10, -30],
              border: [true, false, true, false],
            },
            {
              text: 'บ่อ Cake Decanter 2',
              style: 'nameContent',
              margin: [0, 10],
            },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
          ],
          [
            { text: '', border: [true, false, false, true] },
            {
              text: eachData?.wasteWater || '', //บ่อบำบัดบ่อสุดท้าย
              style: 'nameContent',
              margin: [0, 10],
            },
            {
              text: eachData?.pH || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.temp || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.COD || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.BOD || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.SS || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.VFA || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.OilGrease || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.Alkaline || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.comment || '',
              margin: [0, 10],
              style: 'tableContent',
            },
          ]
        )
        return TableBody
      }
      if (eachData?.wasteWater === 'บ่อ Cake Decanter 2') {
        TableBody.push(
          [
            { text: '', border: [true, true, true, false] },
            {
              text: 'บ่อ Biogas 1',
              style: 'nameContent',
              margin: [0, 10],
            },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
          ],
          [
            {
              image: waterImage?.image, //บ่อ Cake Decanter 2'
              fit: [100, 100],
              margin: [10, -30],
              border: [true, false, true, false],
            },
            {
              text: eachData?.wasteWater || '',
              style: 'nameContent',
              margin: [0, 10],
            },
            {
              text: eachData?.pH || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.temp || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.COD || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.BOD || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.SS || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.VFA || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.OilGrease || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.Alkaline || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.comment || '',
              margin: [0, 10],
              style: 'tableContent',
            },
          ],
          [
            { text: '', border: [true, false, false, true] },
            {
              text: 'บ่อบำบัดบ่อสุดท้าย',
              style: 'nameContent',
              margin: [0, 10],
            },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
          ]
        )
        return TableBody
      }
      if (eachData?.wasteWater === 'บ่อ Biogas 1') {
        TableBody.push(
          [
            { text: '', border: [true, true, true, false] },
            {
              text: eachData?.wasteWater || '', //บ่อ Biogas 1
              margin: [0, 10],
              style: 'nameContent',
            },
            {
              text: eachData?.pH || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.temp || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.COD || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.BOD || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.SS || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.VFA || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.OilGrease || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.Alkaline || '',
              margin: [0, 10],
              style: 'tableContent',
            },
            {
              text: eachData?.comment || '',
              margin: [0, 10],
              style: 'tableContent',
            },
          ],
          [
            {
              image: waterImage?.image,
              fit: [100, 100],
              margin: [10, -30],
              border: [true, false, true, false],
            },
            {
              text: 'บ่อ Cake Decanter 2',
              style: 'nameContent',
              margin: [0, 10],
            },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
          ],
          [
            { text: '', border: [true, false, false, true] },
            {
              text: 'บ่อบำบัดบ่อสุดท้าย',
              style: 'nameContent',
              margin: [0, 10],
            },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
            { text: ' ', style: 'tableContent' },
          ]
        )
      }
    })
  }
  return TableBody
}

const FM_BG_001 = async (form, formInput, subForm, system, date) => {
  console.log(formInput)

  const TableBodyFooter = _.map(formInput?.arr, (eachData, index) => {
    if (
      eachData?.subForm_name ===
      'ปริมาณผลปาล์มสดเข้าผลิต & ปริมาณนํ้าเสียจาก 50% FFB'
    ) {
      return {
        QuantityPalmMonth: eachData?.QuantityPalmMonth,
        QuantityWasteFFB: eachData?.QuantityWasteFFB,
      }
    }
  })

  const finalTableBodyFooter = _.compact(TableBodyFooter)

  const westWater = _.filter(formInput?.arr, {
    subForm_name: 'นํ้าเสียจากกระบวนการผลิตปาล์ม',
  })

  const dataBiogas1 = _.filter(formInput?.arr, {
    wasteWater: 'บ่อ Biogas 1',
  })

  const dataDecanter2 = _.filter(formInput?.arr, {
    wasteWater: 'บ่อ Cake Decanter 2',
  })

  const lastHealing = _.filter(formInput?.arr, {
    wasteWater: 'บ่อบำบัดบ่อสุดท้าย',
  })

  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 120, 20, 10],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      {
        margin: [20, 30, 20, 10],
        table: {
          widths: ['23%', '54%', '12%', '11%'],
          body: [
            [
              {
                image: 'logo_imge',
                rowSpan: 2,
                fit: [35, 35],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: 'รายงานผลการวิเคราะห์คุณภาพนํ้าเสีย\nออกจากกระบวนการผลิตและระบบบ่อหมัก',
                alignment: 'center',
                bold: true,
                rowSpan: 3,
                fontSize: 20,
                margin: [0, 10],
              },
              {
                text: `รหัสเอกสาร:  ${form?.docNo || '-'}  `,
                style: 'header',
                border: [true, true, true, false],
                colSpan: 2,
              },
              {},
            ],
            [
              '',
              { text: ``, alignment: 'center' },
              {
                text: `แก้ไขครั้งที่: ${form?.editVersion || '-'}`,
                style: 'header',
                border: [true, true, true, true],
              },
              {
                text: `หน้าที่ 1/1`,
                style: 'header',
                border: [true, true, true, true],
              },
            ],
            [
              {
                text: `THAI TALLOW AND OIL CO.,LTD.`,
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: 12,
                border: [true, false, true, true],
              },
              '',
              {
                text: `วันที่ประกาศใช้ : 28 มี.ค. 2562`,
                style: 'header',
                border: [true, true, true, true],
                colSpan: 2,
              },
              {},
            ],
          ],
        },
      },
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        text: [
          {
            text: `${dayjs(date).format('เดือน MMMM')}`,
            alignment: 'center',
            style: 'nameContent',
          },
          {
            text: '      ',
            style: 'header2',
          },
          {
            text: `${dayjs(date).format('ปี BBBB ')}`,
            alignment: 'center',
            style: 'header',
          },
        ],
        alignment: 'center',
        margin: [10, 0, 10, 0],
      },
      {
        margin: [0, 10],
        table: {
          widths: [
            '9%',
            '20%',
            '7%',
            '7%',
            '7%',
            '7%',
            '7%',
            '7%',
            '8%',
            '7%',
            '15%',
          ],
          body: [
            [
              //row1
              {
                text: 'ตัวอย่างนํ้าเสีย',
                style: 'nameContent',
                alignment: 'center',
                margin: [0, 10],
                border: [true, true, true, false],
                rowSpan: 2,
                colSpan: 2,
              },
              { text: '', border: [true, true, false, false] },
              { text: 'pH', style: 'headerTable', rowSpan: 2, margin: [0, 10] },
              {
                text: 'Temp',
                style: 'headerTable',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: 'COD',
                style: 'headerTable',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: 'BOD',
                style: 'headerTable',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: 'SS',
                style: 'headerTable',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: 'VFA',
                style: 'headerTable',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: 'Oil&Grease',
                style: 'headerTable',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: 'Alkaline',
                style: 'headerTable',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: 'หมายเหตุ',
                style: 'headerTable',
                rowSpan: 2,
                margin: [0, 10],
              },
            ],
            [
              //row2
              { text: '', border: [true, false, false, true] },
              {},
              {},
              {
                text: ' ํC',
                style: 'headerTable',
                bold: true,
                border: [true, false, true, true],
              },
              {
                text: '(mg/l)',
                style: 'headerTable',
                bold: true,
                border: [true, false, true, true],
              },
              {
                text: '(mg/l)',
                style: 'headerTable',
                bold: true,
                border: [true, false, true, true],
              },
              {
                text: '(mg/l)',
                style: 'headerTable',
                bold: true,
                border: [true, false, true, true],
              },
              {
                text: '(mg/l)',
                style: 'headerTable',
                bold: true,
                border: [true, false, true, true],
              },
              {
                text: '(mg/l)',
                style: 'headerTable',
                bold: true,
                border: [true, false, true, true],
              },
              {
                text: '(mg/l)',
                style: 'headerTable',
                bold: true,
                border: [true, false, true, true],
              },
              {},
            ],

            [
              {
                text: 'นํ้าเสียจากกระบวนการผลิตปาล์ม',
                alignment: 'center',
                colSpan: 2,
                margin: [0, 10],
                style: 'nameContent',
                border: [true, true, false, true],
              },
              { text: '', border: [true, true, false, true] },
              {
                text: westWater?.[0]?.pH || '',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: westWater?.[0]?.temp || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: westWater?.[0]?.COD || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: westWater?.[0]?.BOD || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: westWater?.[0]?.SS || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: westWater?.[0]?.VFA || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: westWater?.[0]?.OilGrease || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: westWater?.[0]?.Alkaline || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: westWater?.[0]?.comment || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
            ],

            [
              { text: '', border: [true, true, true, false] },
              {
                text: 'บ่อ Biogas 1',
                style: 'nameContent',
                margin: [0, 10],
              },
              {
                text: dataBiogas1?.[0]?.pH || '',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataBiogas1?.[0]?.temp || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataBiogas1?.[0]?.COD || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataBiogas1?.[0]?.BOD || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataBiogas1?.[0]?.SS || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataBiogas1?.[0]?.VFA || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataBiogas1?.[0]?.OilGrease || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataBiogas1?.[0]?.Alkaline || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataBiogas1?.[0]?.comment || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
            ],
            [
              {
                image: waterImage?.image,
                fit: [100, 100],
                margin: [10, -30],
                border: [true, false, true, false],
              },
              {
                text: 'บ่อ Cake Decanter 2',
                style: 'nameContent',
                margin: [0, 10],
              },
              {
                text: dataDecanter2?.[0]?.pH || '',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataDecanter2?.[0]?.temp || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataDecanter2?.[0]?.COD || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataDecanter2?.[0]?.BOD || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataDecanter2?.[0]?.SS || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataDecanter2?.[0]?.VFA || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataDecanter2?.[0]?.OilGrease || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataDecanter2?.[0]?.Alkaline || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: dataDecanter2?.[0]?.comment || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
            ],
            [
              { text: '', border: [true, false, false, true] },
              {
                text: 'บ่อบำบัดบ่อสุดท้าย',
                style: 'nameContent',
                margin: [0, 10],
              },
              {
                text: lastHealing?.[0]?.pH || '',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: lastHealing?.[0]?.temp || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: lastHealing?.[0]?.COD || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: lastHealing?.[0]?.BOD || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: lastHealing?.[0]?.SS || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: lastHealing?.[0]?.VFA || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: lastHealing?.[0]?.OilGrease || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: lastHealing?.[0]?.Alkaline || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
              {
                text: lastHealing?.[0]?.comment || ' ',
                margin: [0, 10],
                style: 'tableContent',
              },
            ],
          ],
        },
      },
      {
        margin: [10, 0, 20, 0],
        table: {
          widths: ['40%', '10%', '10%', '20%'],
          body: [
            [
              {
                text: 'ปริมาณผลปาล์มสดเข้ากระบวนการผลิต ประจำเดือน',
                style: 'header2',
                alignment: 'left',
                margin: [0, 2, 0, 0],
                border: [false, false, false, false],
              },
              {
                text:
                  finalTableBodyFooter.length === 0
                    ? '= .............................ตัน'
                    : '=  ' +
                      finalTableBodyFooter[0].QuantityPalmMonth +
                      '  ตัน',

                style: 'header2',
                margin: [-70, 2, 0, 0],
                alignment: 'left',
                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'header2',
                alignment: 'left',
                margin: [-70, 2, 0, 0],
                border: [false, false, false, false],
              },
              { text: ' ', border: [false, false, false, false] },
            ],
            [
              {
                text: 'ปริมาณนํ้าเสียจาก 50% FFB',
                style: 'header2',
                alignment: 'left',
                margin: [0, 2, 0, 0],
                border: [false, false, false, false],
              },
              {
                text:
                  finalTableBodyFooter.length === 0
                    ? '= .............................ลบ.ม.'
                    : '=  ' +
                      finalTableBodyFooter[0].QuantityWasteFFB +
                      '  ลบ.ม.',

                style: 'header2',
                margin: [-70, 2, 0, 0],
                alignment: 'left',
                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'header2',
                margin: [-70, 2, 0, 0],
                alignment: 'left',
                border: [false, false, false, false],
              },
              { text: ' ', border: [false, false, false, false] },
            ],
          ],
        },
      },
      {
        margin: [20, 30, 20, 10],
        table: {
          widths: ['70%', '30%'],
          body: [
            [
              {
                text: 'ผู้บันทึก............................................',
                alignment: 'left',
                margin: [20, 2, 0, 0],
                style: 'header2',
                border: [false, false, false, false],
              },
              {
                text: `หัวหน้าแผนก............................................`,
                style: 'header2',
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: ``,
                alignment: 'center',
                margin: [0, 5, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: `(นายธีระยุทธ กลิ่นจันทร์)`,
                style: 'header2',
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    // footer: function (currentPage, pageCount) {
    //   return []
    // },

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: { font: 'FontAwesome' },
      header: {
        fontSize: 16,
        alignment: 'center',
        bold: true,
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        fontSize: 16,
        alignment: 'center',
        bold: true,
      },
      tableContent: {
        fontSize: 16,
        alignment: 'center',
      },
      nameContent: {
        fontSize: 17,
        bold: true,
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_BG_001
